/*包月记录路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace, ListView } from "antd-mobile";
import ReactDOM from "react-dom";

const center = {
  height: "210px",
  width:"94%",
  margin:"auto",
  marginTop:"6px",
  marginBottom:'15px',
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow:'0px 6px 12px 0px rgba(228,230,233,0.5)',
};
const style_title = { 
  textAlign:"right",
  color: "#CBC9C9",
  fontSize:"13px",
  float:"right",
  whiteSpace:"nowrap",
  overflow:"hidden",
  textOverflow:"ellipsis" 
}

export default class MonthlyOrder extends Component {
  constructor(props) {
    super(props);

    this.pageNum = 0; //定义分页信息
    this.pageSize = 10;
    this.state = {
      // arrearage:[],
      dataSource: new ListView.DataSource({
        //这个dataSource有cloneWithRows方法
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      refreshing: true,
      isLoading: true,
      height: document.documentElement.clientHeight,
      useBodyScroll: true,
      hasMore: true,
    };
  }

  //   componentDidMount() {
  //     this.genData();
  //   }

  async componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
      this.props.history.push('/'); 
      return false;
    }
    const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
    this.rData = await this.genData();
    this.setState({
      dataSource: this.state.dataSource.cloneWithRows(this.rData),
      height: hei,
      refreshing: false,
      isLoading: false,
    });
  }

  genData = () => {
    this.pageNum++;
    const carNums = window.sessionStorage.getItem("carNum");
    const userId = window.sessionStorage.getItem("userId");
    const carNum = {
      baseApplicationId: 74,
      baseOrgId: 1,
      carNumber: carNums,
      elasUserId: userId,
      pageNum: this.pageNum,
      pageSize: this.pageSize,
    };
    const url = "c/userManage/getElasCardDetailList";
    const param = {
      requestUrl: url,
      param: JSON.stringify(carNum),
      baseCpId: null,
    };
    return (
      fetch("a/request/all", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(param), //把提交的内容转字符串
      })

        .then((res) => res.json())
        .then(function (result) {
          if (result) {
            const list = result.rst.list;
            return list;
          } else {
            this.setState({
              hasMore: false,
            });
          }
        })
    );
  };

  onEndReached = async (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    } //如果this.state.hasMore为false，说明没数据了，直接返回
    this.setState({ isLoading: true });
    if (this.rData) {
      this.rData = [...this.rData, ...(await this.genData())]; //每次下拉之后将新数据装填过来
    }
    setTimeout(() => {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        isLoading: false,
      });
    }, 1000);
  };
   // 1 跳转到详情页
   Go_detail = rowData => {
    sessionStorage.setItem("orderCode", rowData.orderCode);
    sessionStorage.setItem("prefixUrl", rowData.prefixUrl);
    this.props.history.push({ pathname: "./orderDetail",})
  }

  render() {
    // const { arrearage } = this.state;
    //这里就是个渲染数据，rowData就是每次过来的那一批数据，已经自动给你遍历好了，rouID可以作为key值使用，直接渲染数据即可
    const row = (rowData, sectionID, rowID) => {
      return (
        <div style={center} key={rowID}>
          <WingBlank>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <div>
              <span style={{ color:"#55555",fontSize:"14px",float:"left", }}>
                订单编号&nbsp;&nbsp;&nbsp;&nbsp;{rowData.orderCode}</span>
              <span style={style_title}>{rowData.baseCpName}</span>
              <div style={{ clear:"both", }}/>
            </div>
            <WhiteSpace />              
            <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
            <WhiteSpace />
            <div onClick={()=>this.Go_detail(rowData)} style={{ color:'#55617C',fontWeight:'400' }}>
              <div>月租卡号&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.clubCardCode}</span></div>
              <WhiteSpace></WhiteSpace>
              <div>车牌号码&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.carNumber}</span></div>
              <WhiteSpace></WhiteSpace>
              <div>车场名称&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.parkingLotName}</span></div>
              <WhiteSpace></WhiteSpace>
              <div>包月时长&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: "red" }}>{rowData.renewDay}</span> 个月</div>
              <WhiteSpace></WhiteSpace>
              <div>开始时间&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.startTime}</span></div>
              <WhiteSpace></WhiteSpace>
              <div>包月费用&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ color: "#398CFC",fontSize:"16px", }}>{rowData.cardFee} 元</span>
              </div>
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
          </WingBlank>
        </div>
      );
    };
    return (
      <div>
        <WhiteSpace></WhiteSpace>
        <WingBlank className="tabs_container">
          <ListView
            key={this.state.useBodyScroll ? "0" : "1"}
            ref={(el) => (this.lv = el)}
            dataSource={this.state.dataSource}
            renderFooter={
              // renderFooter就是下拉时候的loading效果
              () => (
                <div style={{ padding: 30, textAlign: "center" }}>
                  {this.state.isLoading ? "加载中..." : "加载完成"}
                </div>
              )
            }
            renderRow={row} //渲染你上边写好的那个row
            useBodyScroll={this.state.useBodyScroll}
            style={
              this.state.useBodyScroll
                ? {}
                : {
                    height: this.state.height,
                    border: "1px solid #ddd",
                    margin: "5px 0",
                  }
            }
            onEndReached={this.onEndReached}
            pageSize={this.pageSize} //每次下拉之后显示的数据条数
          />
          {/* {arrearage && arrearage.length > 0
            ? arrearage.map((item, index) => (
                <div key={index}>
                  <div style={center}>
                    <WingBlank>
                      <WhiteSpace></WhiteSpace>
                      <WhiteSpace></WhiteSpace>
                      <div style={{ color: "#CBC9C9" }}>
                        订单编号:{item.orderCode}
                      </div>
                      <WhiteSpace></WhiteSpace>
                      <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
                      <WhiteSpace></WhiteSpace>
                      <div
                        onClick={() => {
                          sessionStorage.setItem("orderCode", item.orderCode);
                          this.props.history.push({
                            pathname: "./orderDetail",
                          });
                        }}
                      >
                        <div>月租卡号: {item.clubCardCode}</div>
                        <WhiteSpace></WhiteSpace>
                        <div>车牌号码: {item.carNumber}</div>
                        <WhiteSpace></WhiteSpace>
                        <div>车场名称: {item.parkingLotName}</div>
                        <WhiteSpace></WhiteSpace>
                        <div>
                          包月时长:
                          <span style={{ color: "red" }}>{item.renewDay}</span>
                          个月
                        </div>
                        <WhiteSpace></WhiteSpace>
                        <div>包月开始时间: {item.startTime}</div>
                        <WhiteSpace></WhiteSpace>
                        <div>包月费用: {item.cardFee}元</div>
                      </div>
                      <WhiteSpace></WhiteSpace>
                      <WhiteSpace></WhiteSpace>
                    </WingBlank>
                  </div>
                  <WhiteSpace></WhiteSpace>
                </div>
              ))
            : ""} */}
        </WingBlank>
      </div>
    );
  }
}
