/*主界面路由组件*/
import React, { Component } from "react";
import { Flex, WingBlank, WhiteSpace, Button, Modal, } from "antd-mobile";
import "../../components/boom.css";
import "../number/index.css";
import PhonenumQuery from "./phonenumQuery";
import { getUserCarList, getUserCarList2, getUseCarNumById, getArrearsRecord, getPayParm, getRunOrderByCarNum, } from '../../fetch'
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const myParking = {
  fontSize: "18px",
  color: "#ffffff",
  padding: "18px 0",
};
const flexMain = {
  backgroundColor: "#ffffff",
  height: "93px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#A7895F",
  borderRadius: "13px",
  fontSize: "13px",
  padding: "0 20px",
  marginBottom: "18px",
};
const fontStyle = {
  color: "#0A0A0A",
  fontSize: "35px",
};
const center = {
  height: "210px",
  background: "#fff",
  borderRadius: "13px",
};
const centerOther = {
  height: "210px",
  background: `#5150F2 url(${require("../../components/images/phoneNum.png")}) no-repeat center /100% 100%`,
  borderRadius: "13px",
};
const numberLocked = {
  textAlign: "center",
  fontSize: "16px",
  padding: "14px 0px",
};
const numberStyle = {
  textAlign: "center",
  fontSize: "16px",
  color: "#fff",
  padding: "14px 0px",
};
const numberQuery = {
  textAlign: "center",
  fontSize: "13px",
  color: "#8E8D8D",
};
const carNumbers = {
  border: "1px solid #2D96FE",
  width: "12.698%",
  height: "40px",
  textAlign: "center",
  lineHeight: "40px",
  fontSize: "20px",
};
const carNumber = {
  border: "1px solid #2D96FE",
  borderLeft: "none",
  width: "12.698%",
  height: "40px",
  textAlign: "center",
  lineHeight: "40px",
  fontSize: "20px",
};
const carNumberNew = {
  border: "1px solid #2D96FE",
  borderLeft: "none",
  backgroundColor: "#2580F3",
  width: "12.698%",
  textAlign: "center",
};

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}
function getQueryVariable(url,variable){
  var str=url.split('?');
  var query=str[1];
  var vars=query.split('&');
  // console.log(query,str,vars);
  for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      // console.log(pair);
      if(pair[0] == variable){
          return pair[1];
      }
  }
  return(false);
}

export default class Main extends Component {
  constructor(props) {
    super(props);
    // http://m.elascloud.com/s/13
    // http://m.elascloud.com/main
    //当网址为带有车道号截取下来车道号并跳转到phoneLeave页面
    // http://m.elascloud.com/?laneCode=13
    const query = this.props.location.pathname; //为/s/13
    const code = query.split("/")[1];
    if (code !== "" && query.indexOf("&") < 0 && query.indexOf("main") < 0) {
      //获取通道号
      const codeId = query.split("/")[2];
      //通过通道号查询到进行中订单并进行跳转
      window.sessionStorage.setItem("landCode", codeId);
      window.sessionStorage.setItem("hasCode", "1");
    }

    this.state = {
      modal1: false,
      underway: "",
      cost: "",
      show: false,
      text: true,
      prov: false,
      strArr: "",
      numberHistory: "",
      str: "",
      carHistory: "",
      showStyle: true,
      numType: 1, //1为车牌号码，2为手机号码
      list: undefined,
    };
    this.carLicenseMain = this.carLicenseMain.bind(this);
    this.changeContentBtn = this.changeContentBtn.bind(this);
    this.delText = this.delText.bind(this);
    this.NumListClick = this.NumListClick.bind(this);
    this.closeKeyBorad = this.closeKeyBorad.bind(this);
    this.eightNum = this.eightNum.bind(this);
    this.inputCarnum = this.inputCarnum.bind(this);
    this.inputPhonenum = this.inputPhonenum.bind(this);
  }
  inputCarnum() {
    this.setState({
      numType: 1,
    });
    window.sessionStorage.setItem("numType", "1");
  }
  inputPhonenum() {
    this.setState({
      numType: 2,
      show: false,
    });
    window.sessionStorage.setItem("numType", "2");
  }
  onClose = (key) => () => {
    this.setState({
      [key]: false,
    });
  };

  handlesclick = (keyword) => {
    const { showStyle, str, } = this.state;
    let _str = JSON.parse(JSON.stringify(str))
    let _carNum = showStyle ? _str.slice(0, 7) : _str;
    //点击查询关闭键盘
    this.setState({
      show: false,
    });
    window.sessionStorage.setItem("carNum", _carNum);
    const carNum = {
      carNum: _carNum,
    };
    if (!_carNum) {
      alert("未识别到车牌号");
    } else {
      //查询是否有进行中订单
      getRunOrderByCarNum(carNum).then(data => {
        this.setState({ underway: data.rst, });
        if (this.state.underway) {
          this.props.history.push({
            pathname: "./more",
            carNum: { carNum: _carNum },
          });
          window.sessionStorage.setItem("hasCarNumber", "1");
        } else {
          this.fulfillOrder();
        }
      })
    }
  };

  //查询是否有欠费订单
  fulfillOrder() {
    const { str, } = this.state;
    const carNums = { orderStatus: 1, carNum: str, };
    getArrearsRecord(carNums).then(data => {
      const { list, } = data.rst;
      this.setState({ cost: list, }, () => {
        if (this.state.cost.length > 0) {
          this.props.history.push({
            pathname: "./costRecord",
            carNum: { orderStatus: "1", carNum: this.state.str },
          });
        } else {
          this.setState({ modal1: true, });
        }
      });
    })
  }

  onWrapTouchStart = (e) => {
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };
  //键盘显示隐藏
  carLicenseMain() {
    this.setState({ show: !this.state.show });
  }
  //点击空白处关闭键盘
  closeKeyBorad() {
    this.setState({
      show: false,
    });
  }
  //切换键盘
  changeContentBtn() {
    this.setState({
      text: !this.state.text,
      prov: !this.state.prov,
    });
  }
  //车牌号字母数字读取
  NumListClick(e) {
    //阻止事件冒泡
    if ((e.target && e.target.matches("ul")) || e.target.matches("div")) {
      return false;
    }
    let str = this.state.str;
    let strRes = (str += e.target.innerText);
    //车牌省份选择后切换键盘

    if (strRes.length == 1) {
      this.changeContentBtn();
    }

    if (this.state.showStyle == true) {
      //车牌号码最多为7位
      const realStr = strRes.slice(0, 7);
      this.setState({
        str: realStr,
      });
    } else {
      //车牌号码最多为8位
      const realStr = strRes.slice(0, 8);
      this.setState({
        str: realStr,
      });
    }
  }

  //点击新能源后车牌号码最多为8位
  eightNum() {
    this.setState({
      showStyle: !this.state.showStyle,
    }, () => {
      this.setState({ show: true, });
    });
  }

  // 删除已输入车牌号
  delText(e) {
    e.stopPropagation();
    let newStr = this.state.str.substring(0, this.state.str.length - 1);
    this.setState({
      str: newStr,
    });
    // 车牌号删除为空时切换键盘
    if (!newStr) {
      this.setState({
        text: true,
        prov: false,
      });
    }
  }

  componentDidMount() {
    //选择手机号查询刷新保留页面
    const phoneChoice = window.sessionStorage.getItem("numType");
    if (phoneChoice == 2) {
      this.setState({ numType: 2, });
    }
    //判断是哪个浏览器
    this.IsWechatPayOrAliPay();
  }

  IsWechatPayOrAliPay() {
    //2为微信app的浏览器，1为支付宝app的浏览器，3为其它浏览器
    var browser = window.navigator.userAgent.toLowerCase();
    let url = window.location.href;
    const hasCode = window.sessionStorage.getItem("hasCode");
    const landCode = window.sessionStorage.getItem("landCode");
    if (browser.indexOf("micromessenger") > 0) {//微信
      window.sessionStorage.setItem("browser", "2");
      //https://m.elascloud.com/?code=061dG2kg214i3H0vYskg2n6lkg2dG2kP&state=STATE
      if (url.indexOf("code") > 0) {
        let code = getQueryVariable(url,'code');
        const params = { code, type: 2 };
        // 根据用户CODE获取用户常用车辆
        let pathname = window.sessionStorage.getItem('pathname');
        const cb = () => {
          this.props.history.push({ pathname: './more' });
        }
        if (pathname == null || pathname == '') { this.carUsual({ params }); }
        else { this.carUsual({ params, cb }); }
      } else {
        getPayParm({ type: 2, }).then(data => {
          const { appid, redirectUri, } = data.rst;
          window.sessionStorage.setItem("url", redirectUri);
          let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirectUri
          if (hasCode == 1) {
            url = url + "/phoneLeave?laneCode=" + landCode + "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
          } else {
            url = url + "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
          }
          if (data.code == 1000) { window.location.href = url; }
        })
      }
    } else if (browser.indexOf("alipayclient") > 0) {//支付宝
      window.sessionStorage.setItem("browser", "1");
      // http://pay-test.elascloud.com/#/?app_id=2019112169349514&source=alipay_wallet&scope=auth_base&auth_code=4d9bf65140d944c6836b9ac68b53RX05
      if (url.indexOf("auth_code") > 0) {
        let auth_code = getQueryVariable(url,'auth_code');
        const params = { code: auth_code, type: 1, };
        // 根据用户CODE获取用户常用车辆
        this.carUsual({ params });
      } else {
        getPayParm({ type: 1, }).then(data => {
          const { appid, redirectUri, } = data.rst
          let _redirectUri = redirectUri + "phoneLeave?laneCode=" + landCode;
          let url = "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=" + appid + "&scope=auth_base&redirect_uri="
          if (hasCode == 1) { url = url + _redirectUri; }
          else { url = url + redirectUri; }
          if (data.code == 1000) { window.location.href = url; }
        })
      }
    } else {
      window.sessionStorage.setItem("browser", "3");
      if (hasCode == 1) {
        // http://xxxxxxx/s/13
        let href = window.location.href;
        let newUrl = href.split("/s/")[0];
        let _laneCode = href.split("/s/")[1]
        let leaveUrl = newUrl + "/phoneLeave?laneCode=" + _laneCode;
        window.location.href = leaveUrl;
      }
    }
  }

  carUsual = ({ params, cb }) => {
    // console.log(window.location.href);
    if (window.location.href.indexOf('/phoneLeave') != -1) {
      // console.log('getUserCarList2');
      getUserCarList2(params).then(data => {
        const { rst } = data;
        //为空返回false
        if (JSON.stringify(rst) == "{}") {
          const id = window.sessionStorage.getItem("userId");
          this.idCar(id);
        } else {
          window.sessionStorage.setItem("userId", rst.userId);
        }
        if (cb) { cb() }
      })
    } else {
      getUserCarList(params).then(data => {
        const { rst } = data;
        //为空返回false
        if (JSON.stringify(rst) == "{}") {
          const id = window.sessionStorage.getItem("userId");
          this.idCar(id);
        } else {
          window.sessionStorage.setItem("userId", rst.userId);
          this.setState({ list: rst.orderList, });
        }
        if (cb) { cb() }
      })
    }


  }

  idCar = (id) => {
    if (id == null) {
      this.props.history.push('/MyCar')
      return false
    }
    getUseCarNumById({ userId: id, }).then(data => this.setState({ list: data.rst, }))
  }

  toOrder = (name) => {
    window.sessionStorage.setItem("carNum", name.carNumber);
    if (name.orderStatus == 2) {
      //跳转欠费订单缴费
      // this.props.history.push({ pathname: "./order" });
      this.props.history.push({ pathname: "./costRecord" });
      // this.props.history.push({pathname:'./myCar'})
    } else if (name.orderStatus == 0) {
      //跳转进行中订单缴费
      // this.props.history.push({ pathname: './more' })
      this.props.history.push({ pathname: "./myCar" });
    } else {
      this.props.history.push({ pathname: "./myCar" });
    }
  };

  render() {
    const { list } = this.state;
    const show = this.state.show ? "block" : "none";
    const text = this.state.text ? "block" : "none";
    const prov = this.state.prov ? "block" : "none";
    const keyboardBox = {
      display: show,
    };
    const textBox = {
      display: text,
    };
    const provBox = {
      display: prov,
    };
    const showNum = this.state.str.split("");
    const userId = window.sessionStorage.getItem("userId");
    return (
      <div style={sectionStyle}>
        <WingBlank>
          {userId !== null ? (
            <div
              style={myParking}
              onClick={() => {
                this.props.history.push({ pathname: "./myParking" });
              }}
            >
              {"我的停车 >"}
            </div>
          ) : (
            <div style={myParking}></div>
          )}
          {list && list.length > 0
            ? list.map((item, index) => (
              <div style={flexMain} key={index}>
                <div>
                  <div style={fontStyle}>{item.carNumber}</div>
                  {item.orderStatus == 0 ?
                    <li style={{ color: "#5EAE53", marginTop: "8px" }}>已进入智慧停车场</li>
                    : item.orderStatus == 2 ? <li style={{ color: "#FD3434", marginTop: "8px" }}>停车费用未结清</li>
                      : null}
                </div>
                {item.orderStatus == 0 ? (
                  <div
                    style={{ color: "#2580F3" }}
                    onClick={() => this.toOrder(item)}
                  >
                    {"去缴车费 >"}
                  </div>
                ) : item.orderStatus == 2 ? (
                  <div
                    style={{ color: "#2580F3" }}
                    onClick={() => this.toOrder(item)}
                  >
                    {"去缴车费 >"}
                  </div>
                ) : (
                  <div
                    style={{ color: "#A7895F" }}
                    onClick={() => this.toOrder(item)}
                  >
                    {"查看订单 >"}
                  </div>
                )}
              </div>
            ))
            : null}

          <div style={this.state.numType == 1 ? center : centerOther}>
            <Flex>
              <Flex.Item>
                <div
                  style={this.state.numType == 1 ? numberLocked : numberStyle}
                  onClick={this.inputCarnum}
                >
                  <b>输入车牌号码</b>
                </div>
              </Flex.Item>
            </Flex>
            {/*输入车牌号样式或者手机号*/}
            {this.state.numType == 1 ? (
              <div>
                <div style={{ height: "164px" }}>
                  <WhiteSpace></WhiteSpace>
                  <div style={numberQuery}>请输入车牌号进行缴费查询</div>
                  <WhiteSpace></WhiteSpace>
                  <WingBlank>
                    <WhiteSpace></WhiteSpace>
                    <div
                      style={{ display: "flex" }}
                      onClick={this.carLicenseMain}
                    >
                      <div style={carNumbers}>{showNum[0]}</div>
                      <div style={carNumber}>{showNum[1]}</div>
                      <div style={carNumber}>{showNum[2]}</div>
                      <div style={carNumber}>{showNum[3]}</div>
                      <div style={carNumber}>{showNum[4]}</div>
                      <div style={carNumber}>{showNum[5]}</div>
                      <div style={carNumber}>{showNum[6]}</div>
                      {this.state.showStyle == false ? (
                        <div style={carNumber} onClick={this.eightNum}>
                          {showNum[7]}
                        </div>
                      ) : (
                        <div style={carNumberNew} onClick={this.eightNum}>
                          <span style={{ color: "#ffffff", fontSize: "12px" }}>
                            <p style={{ paddingTop: "7px" }}>新</p>
                            <p>能源</p>
                          </span>
                        </div>
                      )}
                    </div>
                    <WhiteSpace></WhiteSpace>
                    <WhiteSpace></WhiteSpace>
                    <Button
                      type="primary"
                      style={{ borderRadius: "8px", cursor: "pointer" }}
                      onClick={this.handlesclick}
                    >
                      查询
                    </Button>
                  </WingBlank>
                </div>
              </div>
            ) : (
              <PhonenumQuery />
            )}
            <WhiteSpace></WhiteSpace>
          </div>
        </WingBlank>

        {/*键盘*/}
        <div className="keyboardBox" style={keyboardBox}>
          <div
            style={{
              height: "30px",
              backgroundColor: "#fff",
              lineHeight: "30px",
              textAlign: "end",
            }}
            onClick={this.closeKeyBorad}
          >
            完成 &nbsp;
          </div>
          <div
            className="provienceBox"
            className="provienceBox"
            style={textBox}
          >
            <ul onClick={this.NumListClick}>
              <li>京</li>
              <li>津</li>
              <li>渝</li>
              <li>沪</li>
              <li>冀</li>
              <li>晋</li>
              <li>辽</li>
              <li>吉</li>
              <li>黑</li>
              <li>苏</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>浙</li>
              <li>皖</li>
              <li>闽</li>
              <li>赣</li>
              <li>鲁</li>
              <li>豫</li>
              <li>鄂</li>
              <li>湘</li>
              <li>粤</li>
              <li>琼</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>川</li>
              <li>贵</li>
              <li>云</li>
              <li>陕</li>
              <li>甘</li>
              <li>青</li>
              <li>蒙</li>
              <li>桂</li>
              <li>宁</li>
              <li>新</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li
                className="changeContentBtn other"
                onClick={this.changeContentBtn}
              >
                <img
                  src={require("../../components/images/shuzi.png")}
                  style={{ width: "20px", height: "20px", paddingTop: "10px" }}
                  alt="ABC"
                />
              </li>
              <li>藏</li>
              <li>使</li>
              <li>挂</li>
              <li>警</li>
              <li>学</li>
              <li>港</li>
              <li>澳</li>
              <li className="deleteBtn other" onClick={this.delText}>
                <img
                  src={require("../number/del.png")}
                  style={{ height: "15px" }}
                  alt="删除"
                />
              </li>
            </ul>
          </div>
          <div className="textBox" className="textBox" style={provBox}>
            <ul onClick={this.NumListClick}>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>0</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>Q</li>
              <li>W</li>
              <li>E</li>
              <li>R</li>
              <li>T</li>
              <li>Y</li>
              <li>U</li>
              {/* <li>I</li>
              <li>O</li> */}
              <li>P</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>A</li>
              <li>S</li>
              <li>D</li>
              <li>F</li>
              <li>G</li>
              <li>H</li>
              <li>J</li>
              <li>K</li>
              <li>L</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li
                className="changeContentBtn other"
                onClick={this.changeContentBtn}
              >
                <img
                  src={require("../../components/images/fanhui.png")}
                  style={{ width: "20px", height: "20px", paddingTop: "10px" }}
                  alt="返回"
                />
              </li>
              <li>Z</li>
              <li>X</li>
              <li>C</li>
              <li>V</li>
              <li>B</li>
              <li>N</li>
              <li>M</li>
              <li className="deleteBtn other" onClick={this.delText}>
                <img
                  src={require("../number/del.png")}
                  style={{ height: "15px" }}
                  alt="删除"
                />
              </li>
            </ul>
          </div>
        </div>

        {/*未查询到欠费订单*/}
        <Modal
          visible={this.state.modal1}
          transparent
          maskClosable={false}
          onClose={this.onClose("modal1")}
          footer={[
            {
              text: "知道了",
              onPress: () => {
                this.onClose("modal1")();
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              height: 190,
              backgroundImage: `url(${require("../../components/images/no.png")})`,
            }}
          >
            <h3 style={{ color: "#000000" }}>未查询到欠费记录</h3>
            <span style={{ color: "#CBC9C9" }}>关注公众号"云之合"</span>
          </div>
        </Modal>
      </div>
    );
  }
}
