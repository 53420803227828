/*欠费和缴费订单列表路由组件*/
import React, { Component } from "react";
import {
  WingBlank,
  WhiteSpace,
  Button,
  Checkbox,
  ListView,
  Tabs,
} from "antd-mobile";
import ReactDOM from "react-dom";
import Paided from "../fulfill/fulfill";
import "./index.css";
import _Drawer from './_Drawer'
import { getArrearsRecord, } from '../../fetch'

const CheckboxItem = Checkbox.CheckboxItem;
const center = {
  height: "193px",
  width:"94%",
  margin:"auto",
  marginTop:"6px",
  marginBottom:'15px',
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow:'0px 6px 12px 0px rgba(228,230,233,0.5)',
};
const style_title = { 
  textAlign:"right",
  color: "#CBC9C9",
  fontSize:"13px",
  float:"right",
  whiteSpace:"nowrap",
  overflow:"hidden",
  textOverflow:"ellipsis" 
}
const nullList = {
  width: "100%",
  height: "100vh",
  background: `url(${require("../../components/images/noList.png")}) no-repeat center`,
};
const style_bottom_pay = {
  marginRight: "4px",
  position: "absolute",
  right: "10px",
  top: "7px",
  color:"#fff",
  fontWeight:'400',
  lineHeight:'30px',
  
}

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.pageNum = 1; //定义分页信息
    this.pageSize = 10;
    const arrearageDataSource = new ListView.DataSource({
      //这个dataSource有cloneWithRows方法
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      arrearage: [],
      isAllChecked: false,
      money: 0,
      orderCode: [],
      isShowFixed: true, // 默认显示顶部固定全选

      arrearageDataSource,
      refreshing: true,
      isLoading: true,
      height: document.documentElement.clientHeight,
      useBodyScroll: true,
      hasMore: true,
      _prefixUrl:undefined,
      _baseCpId:undefined,
      showDrawer:false,
      _DrawerBaseCpId:undefined,
    };
  }

  way = () => {
    const { orderCode, money, arrearage, } = this.state;
    if(!orderCode.length||orderCode == ""){
      alert("请选择要支付的订单")
    }else{
      let _urlArr = arrearage.filter(item=>item.orderCode == orderCode[0])
      let _prefixUrl = _urlArr[0].prefixUrl;
      this.setState({ _prefixUrl, },()=>{
        window.sessionStorage.setItem("prefixUrl", _prefixUrl);
        window.sessionStorage.setItem("entityCodes", orderCode);
        window.sessionStorage.setItem("money", money);
        this.props.history.push({ pathname: "./way" })
      })
    }
  };

  total = ()  => {
    let arr = this.state.arrearage;
    let price = 0;
    const a = [];
    arr &&
      arr.map(item => {
        if (item.checked == true) {
          const fee = parseFloat(item.orderFee);
          price += fee;
          a.push(item.orderCode);
        }
        return item;
      });
    this.setState({ money: price.toFixed(2), orderCode: a, });
  }

  // 全选
  isAllChecked(e) {
    let bool = e.target.checked;
    if (bool) {
      this.setState({
        isAllChecked: true,
      });
      this.state.arrearage.filter(item=> item.checked = true);
    } else {
      this.setState({ isAllChecked: false, });
      this.state.arrearage.filter(item => item.checked = false );
    }
    this.setState({
      arrearage: this.state.arrearage,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        JSON.parse(JSON.stringify(this.state.arrearage)) // 更改state数据时也要把dataSource重新赋值
      ),
    });
    this.total();
  }

  // 勾选
  singleChecked(e, i, rowData) {
    const { orderCode, _baseCpId, } = this.state;
    const { prefixUrl, baseCpId, } = rowData;
    const isChecked = e.target.checked;
    // 空数组填入
    if(isChecked&&!orderCode.length){
      this.setState({ _prefixUrl:prefixUrl, _baseCpId:baseCpId, })
    }
    // 填入多数组
    if(isChecked&&orderCode.length){
      if(baseCpId!==_baseCpId){
        alert("请选择相同公司的订单进行支付！")
        return false;
      }
    }
    this.state.arrearage[i].checked = e.target.checked;
    this.setState({
      arrearage: this.state.arrearage,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        JSON.parse(JSON.stringify(this.state.arrearage)) // 更改state数据时也要把dataSource重新赋值
      ),
    });
    let bool = this.state.arrearage.filter((item) => !item.checked); 
    if (bool.length == 0) { this.setState({ isAllChecked: true, }); } 
    else { this.setState({ isAllChecked: false, }); }
    this.total();
  }

  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
      this.props.history.push('/'); 
      return false;
    }
    this.start()
  }

  start = (params) =>{
    this.genData1({ pageNum: this.pageNum, pageSize: this.pageSize, }).then(data=>{
      this.rData = data;
      if (this.lv) {
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
        this.setState({ height: hei, });
      }
      this.setState({
        arrearage: this.rData,
        arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(this.rData),
        refreshing: false,
        isLoading: false,
      });
    })
  }

  genData1 = (params) => {
    return new Promise(resolve=>{
      this.pageNum++;
      const carNums = window.sessionStorage.getItem("carNum");
      const carNum = {
        carNum: carNums,
        orderStatus: "1",
        ...params,
      };
      getArrearsRecord(carNum).then(result=>{
        if (result) {
          if (result.code == 1000) {
            resolve(result.rst.list)
          } else {
            alert(result.desc);
            return;
          }
        } 
        else { this.setState({ hasMore: false, }); }
      })
    })
  };

  onEndReached = async (event) => {
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    } //如果this.state.hasMore为false，说明没数据了，直接返回
    this.setState({ isLoading: true });
    this.genData1({ pageNum: this.pageNum,pageSize: this.pageSize, }).then(result=>{
      this.rData = [...this.rData, ...result]
        this.setState({
          arrearage: this.rData,
          arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(this.rData),
          isLoading: false,
        });
    })
  };
  onEndReached1 = () => {
    const { _DrawerBaseCpId, } = this.state;
    let payload = { pageNum: 1,pageSize: 99999, baseCpId:_DrawerBaseCpId, }
    this.genData1(payload).then(result=>{
      this.setState({
        arrearage: result,
        arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(result),
      });
    })
  };

  // 1 跳转到详情页
  Go_detail = rowData => {
    const { orderCode, prefixUrl } = rowData;
    sessionStorage.setItem("orderCode", orderCode);
    sessionStorage.setItem("prefixUrl", prefixUrl);
    this.props.history.push({ pathname: "./detail", });
  }

  // 2 打开抽屉筛选
  fn_showDrawer = () => this.setState({ showDrawer:!this.state.showDrawer })

  // 3 抽屉获取公司id
  getNewData = item =>{
    const { baseCpId, } = item;
    const { _DrawerBaseCpId, } = this.state;
    if( _DrawerBaseCpId == baseCpId ){ return false }
    this.setState({ 
      _DrawerBaseCpId:baseCpId, 
      arrearage: [],
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows([]),
    },()=>{
      this.onEndReached1()
      this.fn_showDrawer()
    })
  }

  // 4 清空筛选
  ResetList = () =>{
    this.pageNum = 1;
    this.setState({ _DrawerBaseCpId:undefined, },()=>this.start())
  }

  render() {
    const tabs = [
      { title: "未缴费", sub: "1" },
      { title: "已缴费", sub: "2" },
    ];
    let { arrearage, arrearageDataSource, isShowFixed, showDrawer, _DrawerBaseCpId, } = this.state;
    const row = (rowData, sectionID, rowID) => {
      return (
          <div style={center} key={rowID}>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div>
                <span style={{ color:"#55555",fontSize:"14px",float:"left", }}>订单编号&nbsp;&nbsp;&nbsp;&nbsp;{rowData.orderCode}</span>
                <span style={style_title}>{rowData.baseCpName}</span>
                <div style={{ clear:"both", }}/>
              </div>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              <WhiteSpace></WhiteSpace>
              <div onClick={()=>this.Go_detail(rowData)} style={{ color:'#55617C',fontWeight:'400' }}>
                <div>车牌号码&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.carNumber}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>停车地点&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.parkingLotAdress}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>
                  停车时长&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color:'#000000' }}>
                    <span style={{ color: "red" }}>{rowData.hour}</span>小时
                    <span style={{ color: "red" }}>{rowData.minute}</span>分钟
                  </span>
                </div>
                <WhiteSpace></WhiteSpace>
                <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              </div>
            </WingBlank>
            <WhiteSpace></WhiteSpace>
            <CheckboxItem
              onChange={e => this.singleChecked(e, rowID,rowData)}
              checked={rowData.checked ? true : false}
            >
              <div>金额&nbsp;&nbsp;<span style={{ color: "#398CFC",fontSize:"16px", }}>{rowData.orderFee} 元</span></div>
              <Button
                type="primary"
                inline
                size="small"
                onClick={() => this.Go_detail(rowData)}
                style={{
                  marginRight: "4px",
                  borderRadius: "8px",
                  position: "absolute",
                  right: "10px",
                  top: "7px", width:"100px",
                  background:'linear-gradient(-10deg,rgba(123,133,252,1) 0%,rgba(75,111,252,1) 100%)',
                }}
              >支&nbsp;付
              </Button>
            </CheckboxItem>
          </div>
      );
    };
    return (
      <div className="tabs_container">
        <_Drawer 
          _DrawerBaseCpId={_DrawerBaseCpId}
          getNewData={this.getNewData}
          showDrawer={showDrawer} 
          fn_showDrawer={this.fn_showDrawer}
        />
        <Tabs
          tabs={tabs}
          initialPage={0}
          renderTab={(tab) => <span>{tab.title}</span>}
          onTabClick={(tab, index) => this.setState({ isShowFixed: index==0?true:false, })}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              backgroundColor: "transtarent",
            }}
          >
            <div style={{ marginTop: 43.5, width: "100%" }}>
            <WhiteSpace />
              {arrearage && arrearage.length > 0 ? (
                <div style={{ marginBottom: '60px', height: "100%", }}>
                  <WingBlank size="md">
                    <div style={{ textAlign:"right", }}>
                      <div onClick={this.fn_showDrawer}>
                        <span style={{ float:"right" }}>筛选</span>
                        <img
                          src={require("../../components/images/sort.png")}
                          style={{ width: "16px", height: "16px",float:"right" }}
                          alt="标签"
                        />
                      </div>
                      
                      {_DrawerBaseCpId?
                        <div style={{ margin:"0 10px", }} onClick={this.ResetList}>
                          <span style={{ float:"right" }}> 清空筛选 &nbsp;&nbsp;&nbsp;</span>
                        </div>:null
                      }
                      <div style={{ clear:"both" }}/>
                    </div>
                  </WingBlank>
                  <ListView
                    key={this.state.useBodyScroll ? "0" : "1"}
                    ref={(el) => (this.lv = el)}
                    dataSource={arrearageDataSource}
                    renderFooter={
                      // renderFooter就是下拉时候的loading效果
                      () => (
                        <div style={{ padding: 30, textAlign: "center" }}>
                          {this.state.isLoading ? "加载中..." : "加载完成"}
                        </div>
                      )
                    }
                    renderRow={row} //渲染你上边写好的那个row
                    useBodyScroll={this.state.useBodyScroll}
                    style={
                      this.state.useBodyScroll
                        ? {}
                        : {
                            height: this.state.height,
                            border: "1px solid #ddd",
                            margin: "5px 0",
                          }
                    }
                    onEndReached={_DrawerBaseCpId?this.onEndReached1:this.onEndReached}
                    invoicePageSize={this.pageSize} //每次下拉之后显示的数据条数
                  />
                </div>
              ) : (
                <div style={nullList}></div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Paided />
          </div>
        </Tabs>

        <span
          className="hiedcheck"
          style={{
            position: "fixed",
            width: "100%",
            bottom: "0",
            padding: "8px 0",
            boxSizing:'border-box',
            display: isShowFixed?"block":"none",
          }}
        >
        <WingBlank size="lg">
          <WingBlank size="md">
            <CheckboxItem
              disabled={_DrawerBaseCpId?false:true}
              checked={this.state.isAllChecked ? true : false}
              onChange={(e) => this.isAllChecked(e)}
              style={{
                boxSizing:'border-box',
                borderRadius:'8px',
                background: "linear-gradient(-10deg,rgba(123,133,252,1) 0%,rgba(75,111,252,1) 100%)", 
                boxShadow:'0px 10px 18px 0px rgba(64,95,216,0.3)',
              }}
            >
              <div style={{ color:"#fff", }}>
                总金额：<span style={{ fontWeight:'400', }}>{this.state.money} 元</span>
              </div>

              <span style={style_bottom_pay} onClick={this.way}>
              <span style={{ display:"inline-block",background:"#1324A3",width:"1px",height:"20px",top:"5px",position:'absolute',left:'0' }}/>
                &nbsp;&nbsp;支付&nbsp;&nbsp;
              </span>
            </CheckboxItem>
          </WingBlank>
        </WingBlank>
        </span>
      </div>
    );
  }
}
