/*
 * @Date: 2020-06-03 11:22:45
 * @LastEditors: XPy
 * @LastEditTime: 2020-07-22 09:23:43
 * @FilePath: \parking-h5\src\index.js
 */ 
/*入口js*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Invoice from "./containers/invoice/invoice";
import OrderType from "./containers/invoice/orderType";
import MonthlyList from "./containers/invoice/monthlyList";
import StopSever from "./containers/invoice/stopSever";
import InvoiceStatus from "./containers/invoice/invoiceStatus";
import InvoiceHistory from "./containers/invoice/invoiceHistory";
import MyCar from "./containers/myCar/myCar";
import MonthlyOrder from "./containers/myCar/monthlyOrder";
import OrderDetail from "./containers/myCar/orderDetail";
import MyParking from "./containers/myParking/myParking";
import MyIdentification from "./containers/myParking/myIdentification";
import MonthlyCar from "./containers/monthlyCar/monthlyCar";
import MonthlyDetail from "./containers/monthlyCar/detail";
import MonthlyDeal from "./containers/monthlyCar/deal";
import Number from "./containers/number/number";
import PhoneBind from "./containers/phoneBind/phoneBind";
import PhoneLeave from "./containers/phoneLeave/phoneLeave";
import PhoneBindStatus from "./containers/phoneBindStatus/phoneBindStatus";
import Order from "./containers/order/order";
import Detail from "./containers/detail/Detail_";
import Record from "./containers/record/record";
import Login from "./containers/login/login";
import Register from "./containers/register/register";
import Machine from "./containers/machine/machine";
import Pay from "./containers/pay/pay";
import PayStatus from "./containers/payStatus/payStatus";
import Way from "./containers/way/way";
import Fulfill from "./containers/fulfill/fulfill";
import More from "./containers/more/more";
import Main from "./containers/main/main";
import CostRecord from "./containers/costRecord/costRecord";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/myCar" component={MyCar}></Route>
      <Route path="/monthlyOrder" component={MonthlyOrder}></Route>
      <Route path="/orderDetail" component={OrderDetail}></Route>
      <Route path="/invoice" component={Invoice}></Route>
      <Route path="/orderType" component={OrderType}></Route>
      <Route path="/monthlyList" component={MonthlyList}></Route>
      <Route path="/stopSever" component={StopSever}></Route>
      <Route path="/invoiceStatus" component={InvoiceStatus}></Route>
      <Route path="/invoiceHistory" component={InvoiceHistory}></Route>
      <Route path="/myParking" component={MyParking}></Route>
      <Route path="/myIdentification" component={MyIdentification}></Route>
      <Route path="/monthlyCar" component={MonthlyCar}></Route>
      <Route path="/monthlyDetail" component={MonthlyDetail}></Route>
      <Route path="/monthlyDeal" component={MonthlyDeal}></Route>
      <Route path="/n" component={Number}></Route>
      <Route path="/p" component={PhoneBind}></Route>
      <Route path="/phoneLeave" component={PhoneLeave}></Route>
      <Route path="/phoneBindStatus" component={PhoneBindStatus}></Route>
      <Route path="/order" component={Order}></Route>
      <Route path="/detail" component={Detail}></Route>
      <Route path="/record" component={Record}></Route>
      <Route path="/login" component={Login}></Route>
      <Route path="/register" component={Register}></Route>
      <Route path="/pay" component={Pay}></Route>
      <Route path="/payStatus" component={PayStatus}></Route>
      <Route path="/way" component={Way}></Route>
      <Route path="/fulfill" component={Fulfill}></Route>
      <Route path="/machine" component={Machine}></Route>
      <Route path="/more" component={More}></Route>
      <Route path="/costRecord" component={CostRecord}></Route>
      <Route path="/" component={Main}></Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
