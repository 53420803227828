/*用户认证路由组件*/
import React, { Component } from "react";
import { Input } from "antd";
import "antd/lib/input/style/index.css";
import './index.css'
import { Flex, List, WingBlank, WhiteSpace, Toast } from "antd-mobile";
import changeimg from '../../Photo/change.png'
import { getUserProve, getAuthCode, addUserProve, updateUserPhone, } from '../../fetch'
const sectionStyle = {
  width: "100%",
  height: "100vh",
  // backgroundSize: "cover",
  // backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  height: "430px",
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  marginTop: "12px",
};

export default class MyIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPhone: "",
      userName: "",
      phone: "",
      idCard: "",
      authCode: "",
      originHeight: "",
      timeStart: false,
      count: 0,
      isOriginHei: true,
      identifyStatus: 0, //认证状态 认证过的为1，未认证的为0,修改手机号为3
    };
    this.onChange = this.onChange.bind(this);
    this.resize = this.resize.bind(this);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.resize);
  }

  screenChange = () => {
    window.addEventListener("resize", this.resize);
  }

  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
      this.props.history.push('/'); 
      return false;
    }
    this.start()
  }


  start = () =>{
    const originHeight = document.documentElement.clientHeight;
    this.screenChange();
    //获取用户认证详情
    this.setState({ originHeight, },()=>this.getUserProve() );
  }

  getUserProve = () => {
    const id = {
      userId: window.sessionStorage.getItem("userId"),
    };
    getUserProve(id).then(data=>{
      const { rst:{ phone }, code, desc, } = data;
      if(code==1000){
        if(phone!==null&&phone!==""){
          this.setState({
            identifyStatus: 1,
            idCard: data.rst.idCard,
            userName: data.rst.userName,
            phone: data.rst.phone,
          });
        }
      }else{
        Toast.info(desc)
      }
    })
  }

  resize() {
    const changeHeight = document.documentElement.clientHeight;
    if (this.state.originHeight > changeHeight) {
      this.setState({ isOriginHei: false, });
    } else {
      this.setState({ isOriginHei: true, });
    }
  }
  
  //把当前修改的值赋入state
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, });
  }
  //获取手机验证码
  getCode = () => {
    const phoneReg = /^[1][3-9][0-9]{9}$/;
    const { phone } = this.state;
    if (phone == "") {
      Toast.info("请输入手机号后获取验证码");
    } else if (!phoneReg.test(phone)) {
      Toast.info("手机号格式有误");
    } else {
      getAuthCode({ phone, }).then(data=>{
          this.setState({ timeStart: true, count: 60, },()=>{
            this.setInterval();
          })
          if (data.code !== 1000) {
            Toast.info(data.desc);
          }
      })
    }
  }
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000);
  };

  countDown = () => {
    const { count } = this.state;
    if (count == 1) {
      this.clearInterval();
      this.setState({ timeStart: false });
    } else {
      this.setState({ timeStart: true, count: count - 1 });
    }
  };

  clearInterval = () => {
    clearInterval(this.timer);
  };
  componentWillUnmount() {
    this.clearInterval();
  }

  onSubmit(e) {
    // 阻止事件传递
    e.preventDefault();
    const { identifyStatus, userName, phone, idCard, authCode, oldPhone, } = this.state;
    if(!(/^1[3456789]\d{9}$/.test(phone))){ 
      Toast.info("手机号格式不正确",1); return false;
    } 
    // const idcardReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
    // if(!(idcardReg.test(idCard))) {
    //   Toast.info("身份证格式不正确",1); return false;
    // }
    if (identifyStatus == 0) {
      //2为微信app的浏览器，1为支付宝app的浏览器，3为其它浏览器
      const browser = window.sessionStorage.getItem("browser");
      const userId = window.sessionStorage.getItem("userId");
      // const token= sessionStorage.getItem("token");
      // 把表单用的最终数据从state中提取出来,传入请求
      const params = {
        type: browser,
        userId: userId,
        userName: userName,
        phone: phone,
        // idCard: idCard,
        authCode: authCode,
      };
      if (userName == "" || phone == "" || authCode == "" ) {
        if(userName == ""){ Toast.info("请输入姓名",1) }
        if(phone == ""){ Toast.info("请输入手机号",1) }
        if(authCode == ""){ Toast.info("请输入验证码",1) }
        // if(idCard == ""){ Toast.info("请输入证件号码",1) }
      } else {
        addUserProve(params).then(data=>{
          if (data.code == 1000) {
            alert("认证成功")
            const _GoDetail = window.sessionStorage.getItem('_GoDetail')
            if(_GoDetail){ this.props.history.push({ pathname:_GoDetail, }) }
            this.start()
            this.clearInterval()
          } else {
            alert(data.desc);
          }
        })
      }
    } else {
      const params = { phone, oldPhone, authCode, };
      if (phone == "" || authCode == "") {
        Toast.info("请输入相应信息",1);
      } else {
        updateUserPhone(params).then(data=>{
          const { code, desc, } = data;
          if(code==1000){
            // 验证成功后刷新更新页面 没有办法，只能刷新了
            Toast.success(desc,1,()=> window.location.reload() )  
          }else{ Toast.offline(desc,1) }
        })
      }
    }
  }

  changePhone(oldPhone) {
    this.setState({
      identifyStatus: 3,
      oldPhone: oldPhone,
    });
  }

  componentWillUnmount(){
    window.sessionStorage.removeItem("_GoDetail")
  }

  render() {
    const { identifyStatus, timeStart, count } = this.state;
    const show = this.state.isOriginHei == true ? "block" : "none";
    const isOriginHei = {
      display: show,
      borderRadius: "8px",
      height: "47px",
      width: "100%",
      background: "linear-gradient(-10deg,rgba(123,133,252,1) 0%,rgba(75,111,252,1) 100%)",
      fontSize: "18px",letterSpacing:'1px',
      border: "none", color:'#FFFDFD',
      boxShadow:'0px 10px 18px 0px rgba(44,59,92,0.3)',
    };
    return (
      <div style={sectionStyle} className="myIdentification">
        <WhiteSpace></WhiteSpace>
        <WingBlank>
          <form onSubmit={this.onSubmit.bind(this)}>
            <div style={center}>
              <WingBlank>
                <WhiteSpace></WhiteSpace>
                <div style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", }}>
                  {identifyStatus == 0? "用户认证": identifyStatus == 1 ? "我的认证": "修改手机号"}
                </div>
                <WhiteSpace></WhiteSpace>
                {identifyStatus == 0 ? (
                  <div className="page" key="0-0">
                    <label>*姓名（必填）
                      <Input
                        placeholder="请输入姓名"
                        name="userName"
                        onChange={this.onChange}
                        defaultValue={this.state.userName}
                      />
                    </label>
                    <WhiteSpace size="lg"/>
                    <label>*手机号（必填）
                      <Input
                        type="number"
                        placeholder="请输入手机号"
                        name="phone"
                        onChange={this.onChange}
                        defaultValue={this.state.phone}
                      />
                    </label>
                    <WhiteSpace size="lg"/>
                    <label>*验证码（必填）
                      <Input
                        placeholder="请输入验证码"
                        name="authCode"
                        onChange={this.onChange}
                        addonAfter={timeStart ? `(${count}s) 后重试`: <span onClick={() => this.getCode()}>获取验证码</span>}
                      />
                    </label>
                  </div>
                ) : identifyStatus == 1 ? (
                  <div className="page" key="0-1">
                    <label>姓名
                      <Input
                        placeholder={this.state.userName}
                        value={this.state.userName}
                        readOnly
                        name="userName"
                      />
                    </label>
                    <WhiteSpace size="lg"/>
                    <label>手机号
                      <Input
                        type="number"
                        placeholder={this.state.phone}
                        value={this.state.phone}
                        readOnly
                        name="phone"
                        addonAfter={
                          <div style={{ color:"#7C90B6",fontWeight:'lighter' }} onClick={(val) => this.changePhone(this.state.phone)}>
                            修改&nbsp;
                            <img src={changeimg} style={{ }}/>
                          </div>}
                      />
                    </label>
                  </div>
                ) : (
                  <div className="page" key="0-3">
                    <label>手机号
                      <Input
                        type="number"
                        placeholder={this.state.phone}
                        name="phone"
                        onChange={this.onChange}
                        defaultValue={this.state.phone}
                      />
                    </label>
                    <WhiteSpace size="lg"/>
                    <label>验证码
                      <Input
                        placeholder="请输入验证码"
                        name="authCode"
                        onChange={this.onChange}
                        addonAfter={timeStart ?`(${count}s) 后重试`:<div onClick={() => this.getCode()}>获取验证码</div>}
                      />
                      </label>
                  </div>
                )}
              </WingBlank>
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            {identifyStatus == 0 ? 
              <button type="submit" style={isOriginHei}>提交认证</button>
             : identifyStatus == 3 ?
              <button type="submit" style={isOriginHei}>提交</button>
            : null}
          </form>
        </WingBlank>
      </div>
    );
  }
}
