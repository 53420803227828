import React, { Component, Fragment } from 'react'
import { Drawer, List, WhiteSpace, WingBlank, Button } from 'antd-mobile';
import { getAllBaseCp, } from '../../fetch'
import "./index.css";

export default class _Drawer extends Component {
    state = {
      data:[]
    }

    componentDidMount(){
      getAllBaseCp({}).then(res=> this.setState({ data:res.rst!==null?res.rst:[], }) )
    }
    
    render() {
      const { data, } = this.state;
      const { showDrawer, _DrawerBaseCpId, } = this.props;
      // 抽屉里面的内容
      const sidebar = <Fragment>
        <WhiteSpace />
        <WingBlank style={{ fontSize:"16px", }} size="lg">
          停车场运营公司:
        </WingBlank>
        <WhiteSpace />
        {data.length?
          <List>
            {data.map(item=>{
              return <List.Item key={item.baseCpId} multipleLine onClick={()=>this.props.getNewData(item)}>
                <Button type={_DrawerBaseCpId==item.baseCpId?"primary":'ghost'}>{item.baseCpName}</Button>
              </List.Item>
            })}
          </List>:null
        }
      </Fragment>

      return <Drawer
          className="my-drawer"
          style={{ minHeight: document.documentElement.clientHeight, }}
          enableDragHandle
          contentStyle={{ color: '#A6A6A6', textAlign: 'center', paddingTop: 42 }}
          sidebar={sidebar}
          open={showDrawer}
          onOpenChange={()=>this.props.fn_showDrawer()}
        >{` `}
        </Drawer>
    }
}
