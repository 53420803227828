/*发票状态路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace, Toast, ListView, Button } from "antd-mobile";
import ReactDOM from "react-dom";
import download from '../../Photo/download.png'

const center = {
  backgroundColor: "#ffffff",
  borderRadius: "12px",
  boxShadow:'0px 6px 12px 0px rgba(228,230,233,0.5)',
  marginBottom:"20px",
};

export default class InvoiceHistory extends Component {
  constructor(props) {
    super(props);
    // const dataSource = new ListView.DataSource({
    //   //这个dataSource有cloneWithRows方法
    //   rowHasChanged: (row1, row2) => row1 !== row2,
    // });
    this.pageNum = 0; //定义分页信息
    this.pageSize = 10;

    this.state = {
      money: 0,
      orderCode: "",

      // 上拉加载
      dataSource: new ListView.DataSource({
        //这个dataSource有cloneWithRows方法
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      refreshing: true,
      isLoading: true,
      height: document.documentElement.clientHeight,
      useBodyScroll: true,
      hasMore: true,
    };
  }

  async componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
    this.props.history.push('/'); 
    return false;
    }
    const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
    this.rData = await this.genData();
    this.setState({
      dataSource: this.state.dataSource.cloneWithRows(this.rData),
      height: hei,
      refreshing: false,
      isLoading: false,
    });
  }

  genData = () => {
    this.pageNum++;
    const userId = window.sessionStorage.getItem("userId");
    const id = {
      userId: userId,
      pageNum: this.pageNum,
      pageSize: this.pageSize,
    };
    const url = "c/h5Manage/getInvoiceList";
    const param = {
      requestUrl: url,
      param: JSON.stringify(id),
      baseCpId: null,
    };
    return fetch("a/request/all", {
      // post提交
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param), //把提交的内容转字符串
    })
      .then((res) => res.json())
      .then(function (result) {
        if (result) {
          const list = result.rst.list;
          return list;
        } else {
          this.setState({
            hasMore: false,
          });
        }
      });
  };

  getInvoicePic = (mes) => {
    const { invoiceCode, } = mes;
    const url = mes.prefixUrl + "c/invoiceManage/getInvoiceManage";
    // const param = {
    //   requestUrl: url,
    //   param: JSON.stringify({ invoiceCode }),
    //   baseCpId: null,
    // };
    fetch(url, {
      // post提交
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ invoiceCode }), //把提交的内容转字符串
    })
      .then((res) => res.json())
      .then((data) => {
        const dataUrl = data.rst;
        if (dataUrl == "") {
          Toast.info("开票中，请稍后重试", 3);
        } else {
          Toast.info("开票中，请稍等");
          const link = document.createElement("a");
          //设置下载的文件名
          link.download = "发票";
          link.style.display = "none";
          //设置下载路径
          link.href = dataUrl;
          //触发点击
          document.body.appendChild(link);
          link.click();
          //移除节点
          document.body.removeChild(link);
          Toast.hide()
        }
      });
  };

  onEndReached = async (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    } //如果this.state.hasMore为false，说明没数据了，直接返回
    this.setState({ isLoading: true });
    if (this.rData) {
      this.rData = [...this.rData, ...(await this.genData())]; //每次下拉之后将新数据装填过来
    }
    setTimeout(() => {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        isLoading: false,
      });
    }, 1000);
  };

  render() {
    // const { invoiceList } = this.state;
    //这里就是个渲染数据，rowData就是每次过来的那一批数据，已经自动给你遍历好了，rouID可以作为key值使用，直接渲染数据即可
    const row = (rowData, sectionID, rowID) => {
      return (
          <div key={rowID} style={center}>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={{ color: "#CBC9C9" }}>发票号码&nbsp;&nbsp;&nbsp;&nbsp;{rowData.invoiceCode}</div>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }} />
              <WhiteSpace></WhiteSpace>
              <div style={{ color:'#55617C',fontWeight:'400' }}>
                <div>
                  发票类型&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color:'#000000' }}>{rowData.invoiceType && rowData.invoiceType == 1? "增值税电子普通发票": ""}</span>
                </div>

                <WhiteSpace></WhiteSpace>
                <div>抬头类型&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color:'#000000' }}>{rowData.titleType && rowData.titleType == 1 ? "企业" : "个人"}</span>
                </div>

                <WhiteSpace></WhiteSpace>
                <div>发票抬头&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color:'#000000' }}>{rowData.invoiceName}</span>
                </div>

                <WhiteSpace></WhiteSpace>
                <div>发票内容&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color:'#000000' }}>{rowData.invoiceMsg}</span>
                </div>

                <WhiteSpace></WhiteSpace>
                  <div>开票时间&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.createdTime}</span></div>
                <WhiteSpace />
                <div style={{ borderBottom: "1px solid #F2F2F2" }} />
                <WhiteSpace />
                <div style={{ display: "flex", justifyContent: "space-between",lineHeight:"30px", }} >
                  <div>发票金额&nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ color:'#398CFC',fontSize:"16px",fontWeight:"400" }}>{rowData.invoiceFee} 元</span>
                  </div>

                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.getInvoicePic(rowData)}
                    style={{ 
                      color: "#FFF",borderRadius:"8px",width:"100px",
                      background:'linear-gradient(-10deg,rgba(123,133,252,1) 0%,rgba(75,111,252,1) 100%)', 
                      position:'relative',
                      lineHeight:'30px',
                    }}
                  >
                    <img style={{ position:"absolute",left:'22px',top:"5px", }} src={download}/>&nbsp;&nbsp;&nbsp;下载
                  </Button>
                </div>
              </div>
            </WingBlank>
            <WhiteSpace />
          </div>
      );
    };
    return (
        <div className="listbackground">
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <ListView
              key={this.state.useBodyScroll ? "0" : "1"}
              ref={(el) => (this.lv = el)}
              dataSource={this.state.dataSource}
              renderFooter={
                // renderFooter就是下拉时候的loading效果
                () => (
                  <div style={{ padding: 30, textAlign: "center" }}>
                    {this.state.isLoading ? "加载中..." : "加载完成"}
                  </div>
                )
              }
              renderRow={row} //渲染你上边写好的那个row
              useBodyScroll={this.state.useBodyScroll}
              style={
                this.state.useBodyScroll
                  ? {}
                  : {
                      height: this.state.height,
                      border: "1px solid #ddd",
                      margin: "5px 0",
                    }
              }
              onEndReached={this.onEndReached}
              pageSize={this.pageSize} //每次下拉之后显示的数据条数
            />
          </WingBlank>
        </div>
    );
  }
}
