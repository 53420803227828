/*缴费记录路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace, ListView } from "antd-mobile";
import ReactDOM from "react-dom";
import {withRouter} from "react-router-dom";
import { getArrearsRecord, } from '../../fetch'

const center = {
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  margin:'20px 0',
  boxShadow:'0px 6px 12px 0px rgba(228,230,233,0.5)',
  borderRadius:'8px',
};
class Order extends Component {
  constructor(props) {
    super(props);

    this.pageNum = 0; //定义分页信息
    this.pageSize = 10;
    this.state = {
      //   arrearage: [],
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      refreshing: true,
      isLoading: true,
      height: document.documentElement.clientHeight,
      useBodyScroll: true,
      hasMore: true,
      data:{
        baseCpId:undefined,
      }
    };
  }

  componentDidMount() {
    const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
    this.genData().then(list=>{
      this.rData=list;
      this.setState({
        data:list[0],
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        height: hei,
        refreshing: false,
        isLoading: false,
      });
    })
  }

  genData = () => {
    return new Promise(resolve=>{
      this.pageNum++;
      const carNums = window.sessionStorage.getItem("carNum");
      const carNum = {
        carNum: carNums,
        orderStatus: "2",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      getArrearsRecord(carNum).then(result=>{
          if (result) { resolve(result.rst.list) } 
          else { this.setState({ hasMore: false, }); }
      })
    })
  };

  onEndReached = async (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    } //如果this.state.hasMore为false，说明没数据了，直接返回
    this.setState({ isLoading: true });
    if (this.rData) {
      this.rData = [...this.rData, ...(await this.genData())]; //每次下拉之后将新数据装填过来
    }
    setTimeout(() => {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        isLoading: false,
      });
    }, 1000);
  };
  render() {
    //这里就是个渲染数据，rowData就是每次过来的那一批数据，已经自动给你遍历好了，rouID可以作为key值使用，直接渲染数据即可
    const { data, } = this.state;
    const row = (rowData, sectionID, rowID) => {
      return (
          <div style={center} key={rowID}>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={{ color: "#CBC9C9" }}>
                订单编号: {rowData.orderCode}
              </div>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              <WhiteSpace></WhiteSpace>
              <div
                style={{ color:'#55617C',fontWeight:'400' }}
                onClick={() => {
                  sessionStorage.setItem("orderCode", rowData.orderCode);
                  this.props.history.push({ pathname: "./record", state:data });
                }}
              >
                <div>车牌号码&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.carNumber}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>停车地点&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.parkingLotAdress}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>
                  停车时长&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color:'#000000' }}>
                    <span style={{ color: "red" }}>{rowData.hour}</span>小时
                    <span style={{ color: "red" }}>{rowData.minute}</span>分钟
                  </span>
                </div>
                <WhiteSpace></WhiteSpace>
                <div>
                  停车费用&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#398CFC' }}>{rowData.orderFee}元</span>
                </div>
                <WhiteSpace></WhiteSpace>
                {rowData.orderFee == "0.00" ?null:<div>缴费时间: {rowData.payTime}</div>}
              </div>
              <WhiteSpace></WhiteSpace>
            </WingBlank>
          </div>
      );
    };
    return (
      <div style={{ marginTop: 43.5, width: "100%" }}>
        <WingBlank>
          <ListView
            key={this.state.useBodyScroll ? "0" : "1"}
            ref={(el) => (this.lv = el)}
            dataSource={this.state.dataSource}
            renderFooter={
              // renderFooter就是下拉时候的loading效果
              () => (
                <div style={{ padding: 30, textAlign: "center" }}>
                  {this.state.isLoading ? "加载中..." : "加载完成"}
                </div>
              )
            }
            renderRow={row} //渲染你上边写好的那个row
            useBodyScroll={this.state.useBodyScroll}
            style={
              this.state.useBodyScroll
                ? {}
                : {
                    height: this.state.height,
                    border: "1px solid #ddd",
                    margin: "5px 0",
                  }
            }
            onEndReached={this.onEndReached}
            pageSize={this.pageSize} //每次下拉之后显示的数据条数
          />
        </WingBlank>
      </div>
    );
  }
}

export default withRouter(Order)