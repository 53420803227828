/*支付中路由组件*/
import React, { Component } from "react";
import { WhiteSpace } from "antd-mobile";

export default class Pay extends Component {
  componentDidMount() {
    const query = this.props.location.search; //?&orderCode=12345
    // const query = '?&orderCode=201912115351052'
    const arr = query.split("&");
    const wxPay = arr[1].split("=")[0];
    if (wxPay == "orderCode") {
      const orderCode = arr[1].split("=")[1];
      //查询订单支付状态
      const payCode = {
        orderCode: orderCode,
      };
      const url = "c/wxPay/weixinPayH5Result";
      const param = {
        requestUrl: url,
        param: JSON.stringify(payCode),
        baseCpId: null,
      };
      fetch("a/request/all", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(param),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.rst == 0) {
            // 在当前页面等待5秒刷新
            this.timerID = setInterval(() => this.tick(), 5000);
          } else {
            window.sessionStorage.setItem("orderStatus", "1");
            this.props.history.replace({ pathname: "./PayStatus" });
          }
        });
    } else {
      window.sessionStorage.setItem("orderStatus", "1");
      this.props.history.replace({ pathname: "./PayStatus" });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    const query = this.props.location.search; //?&orderCode=12345
    const arr = query.split("&");
    const orderCode = arr[1].split("=")[1];
    const payCode = {
      orderCode: orderCode,
    };
    const url = "c/wxPay/weixinPayH5Result";
    const param = {
      requestUrl: url,
      param: JSON.stringify(payCode),
      baseCpId: null,
    };
    fetch("a/request/all", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.rst == 0) {
          //在当前页面等待5秒刷新无效，支付失败
          window.sessionStorage.setItem("orderStatus", "2");
          this.props.history.replace({ pathname: "./PayStatus" });
        } else {
          window.sessionStorage.setItem("orderStatus", "1");
          this.props.history.replace({ pathname: "./PayStatus" });
        }
      });
  }
  render() {
    return (
      <div>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <div style={{ textAlign: "center" }}>
          <h1 style={{ color: "#2D96FE" }}>支付中...</h1>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <div style={{ color: "#919191" }}>下滑刷新支付结果</div>
        </div>
      </div>
    );
  }
}
