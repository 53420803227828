/*主界面手机查询路由组件*/
import React, { Component } from "react";
import {  WingBlank, WhiteSpace, Button, Modal } from "antd-mobile";
import "../../components/boom.css";
import "../phoneBind/index.css";
import { withRouter } from "react-router-dom";
import { getRunOrderByCarNum, getArrearsRecord, } from '../../fetch'
const numberQuery = {
  textAlign: "center",
  fontSize: "13px",
  color: "#8E8D8D",
};
const carNumber = {
  border: "1px solid #2D96FE",
  height: "40px",
  borderRadius: "1px",
  textAlign: "right",
  paddingRight: "16px",
  lineHeight: "40px",
  marginLeft: "2px",
  fontSize: "20px",
};
// const numberCar = {
//   border: "1px solid #2580F3",
//   height: "25px",
//   borderRadius: "5px",
//   lineHeight: "25px",
//   color: "#4E4D4D",
//   width: "31%",
//   margin: "3px 2px",
//   display: "flex",
// };

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1: false,
      underway: "",
      cost: "",
      show: false,
      strArr: "",
      numberHistory: "",
      str: "",
      carHistory: "",
    };
    this.handlesclick = this.handlesclick.bind(this);
    // this.phoneLicense = this.phoneLicense.bind(this)
    this.phoneLicenseMain = this.phoneLicenseMain.bind(this);
    this.delText = this.delText.bind(this);
    this.NumListClick = this.NumListClick.bind(this);
    this.closeKeyBorad = this.closeKeyBorad.bind(this);
    // this.clearHistory = this.clearHistory.bind(this)
  }

  onClose = (key) => () => {
    this.setState({
      [key]: false,
    });
  };

  handlesclick = (keyword) => {
    //点击查询关闭键盘
    this.setState({
      show: false,
    });
    window.sessionStorage.setItem("carNum", this.state.str);
    const carNum = {
      carNum: this.state.str,
    };
    if (!this.state.str) {
      alert("未识别到手机号");
    } else {
      //查询是否有进行中订单
      getRunOrderByCarNum(carNum).then(data=>{
        this.setState({ underway: data.rst, })
        if (this.state.underway) {
          this.props.history.push("./more");
        } else {
          this.fulfillOrder();
        }
      })
    }
  };

  //查询是否有欠费订单
  fulfillOrder() {
    const carNums = {
      orderStatus: 1,
      carNum: this.state.str,
    };
    getArrearsRecord(carNums).then(data=>{
      this.setState({
        cost: data.rst.list,
      });
      if (this.state.cost.length > 0) {
        this.props.history.push({
          pathname: "./costRecord",
          carNum: { orderStatus: "1", carNum: this.state.str },
        });
      } else {
        this.setState({
          modal1: true,
        });
      }
    })
  }

  onWrapTouchStart = (e) => {
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };
  //键盘显示隐藏
  phoneLicenseMain() {
    this.setState({ show: !this.state.show });
  }
  //点击空白处关闭键盘
  closeKeyBorad() {
    this.setState({
      show: false,
    });
  }


  //手机号数字读取
  NumListClick(e) {
    //阻止事件冒泡
    if ((e.target && e.target.matches("ul")) || e.target.matches("div")) {
      return false;
    }
    let str = this.state.str;
    let strRes = (str += e.target.innerText);
    const realStr = strRes.slice(0, 11);
    this.setState(
      {
        str: realStr,
      });
  }

  // 删除已输入手机号
  delText(e) {
    e.stopPropagation();
    let newStr = this.state.str.substring(0, this.state.str.length - 1);
    this.setState({
      str: newStr,
    });
  }

  render() {
    const show = this.state.show ? "block" : "none";
    const keyboardBox = {
      display: show,
    };
    const showNum = this.state.str;
    // const originHeight=document.documentElement.clientHeight;
    // const clickHeight=originHeight-204;
    return (
      <div>
        {/*输入手机号样式*/}
        <div style={{ height: "164px" }}>
          <div onClick={this.closeKeyBorad}>
            <WhiteSpace></WhiteSpace>
            <div style={numberQuery}>请输入手机号进行缴费查询</div>
            <WhiteSpace></WhiteSpace>
          </div>
          <WingBlank>
            <WhiteSpace></WhiteSpace>
            <div style={carNumber} onClick={this.phoneLicenseMain}>
              {showNum}
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <Button
              type="primary"
              style={{ borderRadius: "8px" }}
              onClick={this.handlesclick}
            >
              查询
            </Button>
          </WingBlank>
        </div>


        {/*键盘*/}
        <div className="keyboardBox" style={keyboardBox}>
          <div className="phoneBox" className="phoneBox">
            <ul onClick={this.NumListClick}>
              <li>1</li>
              <li>2</li>
              <li>3</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>4</li>
              <li>5</li>
              <li>6</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>7</li>
              <li>8</li>
              <li>9</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li
                className="changeContentBtn other"
                onClick={this.closeKeyBorad}
              >
                <img
                  src={require("../../components/images/closeKeyboard.png")}
                  style={{ width: "24px", height: "24px", padding: "8px" }}
                  alt="关闭键盘"
                />
              </li>
              <li>0</li>
              <li className="deleteBtn other" onClick={this.delText}>
                <img
                  src={require("../number/del.png")}
                  style={{ height: "15px" }}
                  alt="删除"
                />
              </li>
            </ul>
          </div>
        </div>

        {/*未查询到欠费订单*/}
        <Modal
          visible={this.state.modal1}
          transparent
          maskClosable={false}
          onClose={this.onClose("modal1")}
          footer={[
            {
              text: "知道了",
              onPress: () => {
                this.onClose("modal1")();
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              height: 190,
              backgroundImage: `url(${require("../../components/images/no.png")})`,
            }}
          >
            <h3 style={{ color: "#000000" }}>未查询到欠费记录</h3>
            <span style={{ color: "#CBC9C9" }}>关注公众号"云之合"</span>
          </div>
        </Modal>
      </div>
    );
  }
}
export default withRouter(Main);
