/*车辆包月详情路由组件*/
import React, { Component } from "react";
import {
  WingBlank,
  WhiteSpace,
  List,
  Picker,
  DatePicker,
  SegmentedControl,
  Button,
  Toast,
  Checkbox,
  Modal,
} from "antd-mobile";
import { createForm } from "rc-form";
import "../../components/boom.css";
import test1 from '../../Photo/test1.png'
import "./index.css";
import md5 from "md5";
import { getMonthlyAgreement, getParkingLots, getCarNumById } from '../../fetch'
import { judgeUserCard, getCardByCarNum } from '../../services/api'
import moment from 'moment';
const Modal_alert = Modal.alert;
const AgreeItem = Checkbox.AgreeItem;
const Item = List.Item;
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  backgroundColor: "#5150F2",
  borderRadius: "13px 13px 0 0",
  color: "#FFF",
};
const flexMain = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "13px",
};
const fontStyle = {
  fontSize: "23px",
  fontWeight: "bold",
};
const siteColor = {
  color: "#ADADF8",
};
const content = {
  borderRadius: "13px",
  backgroundColor: "#FFFFFF",
};
const carNumbers = {
  border: "1px solid #2D96FE",
  width: "12.698%",
  height: "40px",
  textAlign: "center",
  lineHeight: "40px",
  fontSize: "20px",
};
const carNumberNew = {
  border: "1px solid #2D96FE",
  borderLeft: "none",
  backgroundColor: "#2580F3",
  // width:'40px',
  width: "12.698%",
  height: "40px",
  textAlign: "center",
};
const Style_but = {
  width: "108px",
  borderRadius: "23px",
  fontSize: "18px",
  height: "46px",
  lineHeight: "46px",
  position: "absolute",
  right: "18px",
  top: "9px",
}

class MonthlyDetail extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      SelectData: false,
      duration: 0,
      parkingLotCode: "",
      data: "",
      carInfo: "",
      cardList: [],
      money: [],
      renewDay: "",
      startTime: moment().format("YYYY-MM-DD"),
      endTime: undefined,
      deal: false,
      carChoice: 1, //1为续费车辆，2为可选择车辆，3为自定义车辆
      // notCar:1, //1为有常用车辆，2为无常用车辆
      modal1: false, //自定义窗口
      modal2: false, //包月车协议
      show: false, //自定义键盘
      text: true, //车牌第一位
      prov: false, // 车牌其他位置
      str: "",
      showStyle: true, //新能源标记
      newCarNum: "",
      dealContent: "",
      infoVisible: false,
    };
    this.onCloseNum = this.onCloseNum.bind(this);
    this.NumListClick = this.NumListClick.bind(this);
    this.eightNum = this.eightNum.bind(this);
    this.carLicenseMain = this.carLicenseMain.bind(this);
    this.closeKeyBorad = this.closeKeyBorad.bind(this);
    this.changeContentBtn = this.changeContentBtn.bind(this);
    this.delText = this.delText.bind(this);
    this.onCloseDeal = this.onCloseDeal.bind(this);
  }

  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger") > 0) && (id == null)) {//微信
      this.props.history.push('/');
      return false;
    }
    const parkingLotCode = window.sessionStorage.getItem("parkingLotCode");
    const userId = window.sessionStorage.getItem("userId");

    // 为了解决异步操作 只能使用回调来挽救一下代码了
    const cb = () => {
      const params = { userId, parkingLotCode, };
      //判断是续费车辆还是包月可选车辆
      // const endTime = window.sessionStorage.getItem("cardTime");
      // const carNum = window.sessionStorage.getItem("carNum");

      // if (endTime.length > 0 && carNum) {
      // if (state.carChoice == 1) {
      //   this.setState({
      //     carInfo: carNum,
      //     carNumber: carNum,
      //     carChoice: 1,
      //   });
      // } else {
      //   this.carList(params);
      // }
      let state = this.props.location.state;
      if (state?.carChoice != 1)
        this.carList(params);
      // this.setState({ parkingLotCode, }); //, () => this.creatTime()
    }
    // 为了解决异步操作 只能使用回调来挽救一下代码了
    this.creatTime(() => {
      this.siteGet({ parkingLotCode, cb });
    })

  }

  //判断是否是续费车辆，是开始时间设置为月卡结束时间，否则为当前时间
  creatTime(cb) {
    // console.log('onPickerChange');
    // const endTime = window.sessionStorage.getItem("cardTime");
    // if (endTime.length > 0 && moment(endTime).valueOf()>moment().valueOf()) {
    let state = this.props.location.state;
    if (state?.carChoice == 1) {
      const carNum = window.sessionStorage.getItem("carNum");
      let theTime;
      if (state.cardTime.length > 0 && moment(state.cardTime).isSameOrAfter(moment(),'day')) {
        theTime = new Date(state.cardTime.slice(0, 10));
        theTime.setDate(theTime.getDate() + 1);
      }
      else {
        theTime = new Date();
      }
      const newDate =
        theTime.getFullYear() +
        "-" +
        ((theTime.getMonth() < 9 ? "0" : "") + (theTime.getMonth() + 1)) +
        "-" +
        (theTime.getDate() < 10 ? "0" : "") +
        theTime.getDate();
      sessionStorage.setItem("cardTime", newDate);
      this.setState({
        startTime: newDate, SelectData: true,
        carInfo: carNum,
        carNumber: carNum,
        carChoice: 1,
      }, cb);
    }
    // if (endTime.length > 0 && moment(endTime).valueOf()>moment().valueOf()) {
    //   const nowDate = endTime.slice(0, 10);
    //   const theTime = new Date(nowDate);
    //   theTime.setDate(theTime.getDate() + 1);
    //   const newDate =
    //     theTime.getFullYear() +
    //     "-" +
    //     ((theTime.getMonth() < 9 ? "0" : "") + (theTime.getMonth() + 1)) +
    //     "-" +
    //     (theTime.getDate() < 10 ? "0" : "") +
    //     theTime.getDate();
    //     sessionStorage.setItem("cardTime", newDate);
    //   this.setState({
    //     startTime: newDate, SelectData: true,
    //   },cb);
    // }
    else {
      const date = new Date();
      const nowDate =
        date.getFullYear() +
        "-" +
        ((date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate();
      sessionStorage.setItem("cardTime", nowDate);
      this.setState({
        startTime: nowDate,
      }, cb);
    }
  }

  //停车场信息获取
  siteGet = par => {
    const { parkingLotCode, cb } = par; // 回调用在最后

    const params = { entityCode: parkingLotCode, };
    getParkingLots(params).then(data => {
      let info = data.rst[0];
      let monthInfo = data.rst[0].cardList;
      if (!monthInfo.length) {
        Modal_alert(
          '该车场已停止包月',
          '点击确定返回上一页',
          [{ text: '确定', onPress: () => setTimeout(() => this.props.history.go(-1), 200) }])
        // Toast.offline('该车场已停止包月!',2,()=>this.props.history.go(-1))
        return false
      }
      window.sessionStorage.setItem("prefixUrl", data.rst[0].prefixUrl)
      const month = monthInfo && monthInfo.map((item) => `${item.monthNum}个月`);
      const monthNum = monthInfo && monthInfo.map((item) => item.monthNum);
      const fee = monthInfo && monthInfo.map((item) => item.fee);

      const { duration } = this.state;
      const index = monthNum[duration];
      const endTime = window.sessionStorage.getItem("cardTime");
      const str1 = endTime.replace(/-/g, "/"); //'2018/01/01 00:00:00'
      const date = new Date(Date.parse(str1)); //date格式的时间类型

      const d = endTime.length > 0 ? date : new Date();
      d.setMonth(d.getMonth() + index);
      const dNew = new Date(d.getTime() - 1000 * 60 * 60 * 24);
      // console.log('siteGet',moment(dNew).format("YYYY-MM-DD"));
      this.setState({
        parkingLotCode,
        data: info,
        monthNum: monthNum,
        cardList: month,
        money: fee,
        renewDay: index,
        endTime: moment(dNew).format("YYYY-MM-DD"),
        baseCpId: data.rst[0].baseCpId,
      }, () => { if (cb) { cb() } });
    })
  }

  // 根据用户ID查询绑定车辆信息
  carList(id) {
    const { data: { baseCpId }, } = this.state;
    getCarNumById({ payload: id, baseCpId }).then(data => {
      let list = data.rst;
      //id没有常用车辆时
      if (list && list.length > 0) {
        const definedList = [
          {
            label: "自定义",
            value: "自定义",
          },
        ];
        const newLists =
          list && list.length > 0
            ? list.map((item) => ({
              label: item.carNumber,
              value: item.carNumber,
            }))
            : "";
        const newList = definedList.concat(newLists);
        this.setState({
          carInfo: newList,
          carChoice: 2,
          list: list,
          // notCar:1
        });
      } else {
        const definedList = [{ label: "自定义", value: "自定义", },];
        this.setState({
          carInfo: definedList,
          carChoice: 2,
        });
      }
    })
  }

  //选择不同的月份出现不同时间段
  controlChange = (e) => {
    const index = e.nativeEvent.selectedSegmentIndex;
    const { monthNum } = this.state;
    const num = monthNum[index];

    const endTime = window.sessionStorage.getItem("cardTime");
    const str1 = endTime.replace(/-/g, "/"); //'2018/01/01 00:00:00'
    const date = new Date(Date.parse(str1)); //date格式的时间类型
    const d = endTime.length > 0 ? date : new Date();
    d.setMonth(d.getMonth() + num);
    //时间减少一天到前一天日期
    const dNew = new Date(d.getTime() - 1000 * 60 * 60 * 24);
    const newDate =
      dNew.getFullYear() +
      "-" +
      ((dNew.getMonth() < 9 ? "0" : "") + (dNew.getMonth() + 1)) +
      "-" +
      (dNew.getDate() < 10 ? "0" : "") +
      dNew.getDate();
    // console.log('controlChange',newDate);
    this.setState({
      duration: index,
      renewDay: num,
      endTime: newDate,
    });
  };
  //显示协议弹窗
  showDeal() {
    const { baseCpId, } = this.state;
    getMonthlyAgreement({ payload: {}, baseCpId, }).then(res => {
      if (res.code == 1000) {
        this.setState({ dealContent: res.rst.monthlyAgreement, });
      }
      this.setState({ modal2: true, });
    })
  }

  onCloseDeal = (key) => () => {
    this.setState({
      [key]: false,
      deal: true,
    });
  };
  //显示自定义车牌弹窗
  showModal() {
    this.setState({
      modal1: true,
      show: true,
      text: true,
      prov: false,
      showStyle: true,
    });
  }

  //点击查询关闭键盘以及弹窗
  onCloseNum = (key) => () => {
    const { str, carInfo, } = this.state;
    const newCar = [{ label: str, value: str, },];
    //拼接数组展示出来
    const newLists = newCar.concat(carInfo);
    // 过滤掉为定义新车牌的空数组
    const newList = newLists.filter((item) => "" !== item.value);
    this.setState({
      show: false,
      [key]: false,
      carChoice: 3,
      carNumber: this.state.str,
      carInfo: newList,
      str: "",
    });
    this.changeCarNumber(this.state.str)
  };

  //选择车辆
  onPickerChange = (val) => {
    // console.log('onPickerChange');
    this.setState({ SelectData: false })
    // 每次选择的时候修改选择的状态
    if (val[0] == "自定义") {
      this.showModal();
    } else {
      this.setState({ carNumber: val[0] });
      this.changeCarNumber(val[0])
    }
  };

  //车牌替换逻辑 
  changeCarNumber = val => {
    const { list } = this.state;
    const carMes = list?.find((item) => val == item.carNumber);
    if (typeof carMes == "undefined") {
      getCardByCarNum({
        parkingLotCode: window.sessionStorage.getItem("parkingLotCode"),
        carNumber: val
      }).then(res => {
        if (res.code == 1000) {
          this.changeTime(res.rst)
        }
        else Toast.fail('系统错误，请联系管理员！')
      })
    } else {
      this.changeTime(carMes.clubCard)
    }
  }

  changeTime = clubCard => {
    const parkingLotCode = window.sessionStorage.getItem("parkingLotCode");
    if (clubCard && moment(clubCard.endTime).isSameOrAfter(moment(),'day')) {
      // // 增加判断 自动填充开始时间，只针对月卡尚未过期的，已经过期的不做任何处理
      // const End = new Date(clubCard.endTime)
      // if (moment().valueOf() > moment(clubCard.endTime).valueOf()) {
      //   sessionStorage.setItem("cardTime", moment().format("YYYY-MM-DD"));
      //   this.setState({ startTime: moment().format("YYYY-MM-DD"), SelectData: false, })
      //   return false;
      // }
      // const timeGet = clubCard.endTime;
      // //月卡结束时间增加一秒
      // const times = new Date(timeGet).getTime() + 1000;
      // const date = new Date(times);
      // const time = date.getFullYear() + "-" +
      //   ((date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1)) +"-" +
      //   (date.getDate() < 10 ? "0" : "") + date.getDate();
      let time = moment(clubCard.endTime).add(1, 'day').format("YYYY-MM-DD")
      
      sessionStorage.setItem("cardTime", time);
      this.setState({ startTime: time, SelectData: true, }, () => this.siteGet({ parkingLotCode }));
    } else {
      sessionStorage.setItem("cardTime", moment().format("YYYY-MM-DD"));
      this.setState({ startTime: moment().format("YYYY-MM-DD"), SelectData: false, }, () => this.siteGet({ parkingLotCode }))
    }
  }


  //手动选择开始时间
  selectStartTime = (date) => {
    const parkingLotCode = window.sessionStorage.getItem("parkingLotCode");
    const selectTime =
      date.getFullYear() +
      "-" +
      ((date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() < 10 ? "0" : "") +
      date.getDate();
    sessionStorage.setItem("cardTime", selectTime);
    this.setState({
      startTime: selectTime,
    });
    this.siteGet({ parkingLotCode });
  };

  payFee = (e) => {
    // const browser = window.sessionStorage.getItem("browser");
    window.sessionStorage.setItem("money", e);
    const {
      parkingLotCode,
      carNumber,
      // renewDay,
      // startTime,
      // endTime,
      deal,
    } = this.state;
    if (typeof carNumber == "undefined" || carNumber == "") {
      alert("请选择包月车辆后付费");
      return false;
    } else if (deal == false) {
      alert("请阅读包月协议");
      return false;
    } else {

      judgeUserCard({
        carNumber,
        parkingLotCode
      }).then(res => {
        if (res.code == 1000) {
          if (res.rst) this.setState({ infoVisible: true, });// 打开确认支付弹窗
          else Toast.info('该车场包月已到上限！')
        }
        else Toast.fail('系统错误，请联系管理员！')
      })

    }
  };

  // 支付确认 关闭弹窗
  paySure = () => {
    this.setState({ infoVisible: false, });
    const browser = window.sessionStorage.getItem("browser");
    const prefixUrl = window.sessionStorage.getItem("prefixUrl");
    const e = window.sessionStorage.getItem("money");

    const { parkingLotCode, carNumber, renewDay, startTime, endTime, } = this.state;
    Toast.loading("调用支付接口，请稍后...", 5);
    if (Number(browser) == 2) {
      const userId = window.sessionStorage.getItem("userId");
      const myUrl = window.sessionStorage.getItem("url");
      const toUrl = prefixUrl +
        "c/wxPay/weixinPayCardH5?parkingLotCode=" + parkingLotCode +
        "&carNumber=" + carNumber +
        "&renewDay=" + renewDay +
        "&startTime=" + startTime +
        "&endTime=" + endTime +
        "&fee=" + e +
        "&userId=" + userId;
      fetch(toUrl, { method: "GET", }).then((res) => res.json())
        .then((data) => {
          const mes = data.rst;
          const time = parseInt(new Date().getTime() / 1000);
          const stringA =
            "appId=" + mes.appid +
            "&nonceStr=" + mes.nonce_str + "&package=" +
            "prepay_id=" + mes.prepay_id +
            "&signType=MD5&timeStamp=" + time;
          const stringSignTemp = stringA + "&key=" + mes.key; //注：key为商户平台设置的密钥key
          const sign = md5(stringSignTemp).toUpperCase(); //注：MD5签名方式
          Toast.hide();
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: mes.appid, //公众号名称，由商户传入
              timeStamp: time.toString(), //时间戳，自1970年以来的秒数
              nonceStr: mes.nonce_str, //随机串
              package: "prepay_id=" + mes.prepay_id,
              signType: "MD5", //微信签名方式：
              paySign: sign, //微信签名
            },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                //支付成功后跳转的页面
                window.sessionStorage.setItem("orderStatus", "1");
                window.sessionStorage.setItem("carNum", carNumber);
                window.location.href = myUrl + "payStatus?type=2";
                window.sessionStorage.setItem("payType", 2);
              } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                alert("您已取消支付");
              } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                alert("支付失败，请稍后再试");
              }
            }
          );
        });
    } else if (Number(browser) == 1) {
      Toast.hide();
      const url = prefixUrl +
        "c/alipay/ailiH5CardPay?parkingLotCode=" + parkingLotCode +
        "&carNumber=" + carNumber +
        "&renewDay=" + renewDay +
        "&startTime=" + startTime +
        "&endTime=" + endTime +
        "&fee=" + e;
      window.location.href = url;
    } else {
      Toast.hide();
    }
  };

  //车牌号字母数字读取
  NumListClick(e) {
    //阻止事件冒泡
    if ((e.target && e.target.matches("ul")) || e.target.matches("div")) {
      return false;
    }
    let str = this.state.str;
    let strRes = (str += e.target.innerText);
    //车牌省份选择后切换键盘

    if (strRes.length == 1) {
      this.changeContentBtn();
    }

    if (this.state.showStyle == true) {
      //车牌号码最多为7位
      const realStr = strRes.slice(0, 7);
      this.setState({
        str: realStr,
      });
    } else {
      //车牌号码最多为8位
      const realStr = strRes.slice(0, 8);
      this.setState({
        str: realStr,
      });
    }
  }

  //点击新能源后车牌号码最多为8位
  eightNum = () => {
    this.setState({
      showStyle: !this.state.showStyle,
    });
    if (!this.state.showStyle) {
      this.setState({
        str: this.state.str.slice(0, 7),
      });
    }
  }

  //键盘显示隐藏
  carLicenseMain = () => {
    this.setState({ show: !this.state.show }); //,showStyle: true
  }

  //切换键盘
  changeContentBtn = () => {
    this.setState({
      text: !this.state.text,
      prov: !this.state.prov,
    });
  }

  //点击完成处关闭键盘
  closeKeyBorad = () => this.setState({ show: false, });

  // 删除已输入车牌号
  delText(e) {
    e.stopPropagation();
    let newStr = this.state.str.substring(0, this.state.str.length - 1);
    this.setState({
      str: newStr,
    });
    // 车牌号删除为空时切换键盘
    if (!newStr) {
      this.setState({
        text: true,
        prov: false,
      });
    }
  }

  render() {
    const { getFieldProps } = this.props.form;
    const {
      duration,
      data,
      carInfo,
      cardList,
      money,
      startTime,
      endTime,
      carChoice,
      deal,
      dealContent,
      renewDay,
      carNumber,
      infoVisible,
      SelectData,
    } = this.state;
    const selectStartTime = new Date(startTime);
    const miniSelectTime = new Date(); //最小可选择时间为当前时间
    //当前时间一年后时间
    const theTime = new Date();
    theTime.setDate(theTime.getDate() + 365);
    const maxTime =
      theTime.getFullYear() + "-" +
      ((theTime.getMonth() < 9 ? "0" : "") + (theTime.getMonth() + 1)) + "-" +
      (theTime.getDate() < 10 ? "0" : "") +
      theTime.getDate();

    const show = this.state.show ? "block" : "none";
    const text = this.state.text ? "block" : "none";
    const prov = this.state.prov ? "block" : "none";
    const keyboardBox = { display: show, zIndex: "9999999", };
    const textBox = { display: text, };
    const provBox = { display: prov, };
    const showNum = this.state.str.split("");
    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <div style={center}>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <WingBlank>
                <div style={fontStyle}>{data.parkingLotName}</div>
                <WhiteSpace></WhiteSpace>
                <div style={flexMain}>
                  <div style={siteColor}>{data.adress}</div>
                </div>
              </WingBlank>
              <WhiteSpace></WhiteSpace>
              <List>
                {carChoice == 2 ? (
                  <Picker
                    data={carInfo}
                    cols={1}
                    {...getFieldProps("district3")}
                    className="forss"
                    onOk={this.onPickerChange}
                  >
                    <List.Item arrow="horizontal">车辆选择</List.Item>
                  </Picker>
                ) : carChoice == 3 ? (
                  <Picker
                    data={carInfo}
                    cols={1}
                    {...getFieldProps("district3")}
                    className="forss"
                    onOk={this.onPickerChange}
                    value={[this.state.carNumber]}
                  >
                    <List.Item arrow="horizontal">车辆选择</List.Item>
                  </Picker>
                ) : (
                      <Item extra={carInfo}>车辆选择</Item>
                    )}
              </List>
              <List className="my-list">
                <Item extra={data.lotType == 1 ? "封闭停车场" : "道路停车场"}>
                  车场类型
                </Item>
              </List>
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <div style={content}>
              <List className="my-list">
                {cardList.length > 2 ? (
                  <Item
                    extra={
                      <SegmentedControl
                        selectedIndex={duration}
                        tintColor={"#A7895F"}
                        style={{ overflow: "scroll", width: "180px" }}
                        // values={['1个月', '3个月']}
                        // values={['1个月']}
                        values={cardList}
                        onChange={this.controlChange}
                      />
                    }
                  >
                    包月套餐
                  </Item>
                ) : (
                    <Item
                      extra={
                        <SegmentedControl
                          selectedIndex={duration}
                          tintColor={"#A7895F"}
                          values={cardList}
                          onChange={this.controlChange}
                        />
                      }
                    >
                      包月套餐
                    </Item>
                  )}
              </List>
              <List>
                <DatePicker
                  mode="date"
                  title=" "
                  disabled={SelectData}
                  extra="Optional"
                  value={selectStartTime}
                  minDate={miniSelectTime}
                  maxDate={new Date(maxTime)}
                  onChange={(date) => this.selectStartTime(date)}
                >
                  <List.Item arrow="horizontal">开始时间</List.Item>
                </DatePicker>
              </List>
              <List className="my-list">
                <Item extra={endTime}>结束时间</Item>
              </List>
            </div>
          </WingBlank>
        </div>
        <div className="circle">
          <AgreeItem
            data-seed="logId"
            checked={deal ? true : false}
            onChange={() => this.setState({ deal: !deal })}
          >
            <a
              onClick={() => this.showDeal("modal2")}
              style={{ color: "#DADADA" }}
            >
              《停车场包月停车服务协议》
            </a>
          </AgreeItem>
        </div>

        <div
          style={{
            color: "#CBCACA",
            position: "fixed",
            width: "100%",
            bottom: "68px",
            textAlign: "center",
          }}
        >
          * 车辆包月服务目前暂不支持退款，请确认后操作
        </div>
        <span
          style={{
            backgroundColor: "#ffffff",
            position: "fixed",
            width: "100%",
            bottom: "0",
            height: "64px",
            fontSize: "18px",
            lineHeight: "64px",
          }}
        >
          <WingBlank>
            <div>
              金额：
              <span style={{ color: "red", fontSize: "20px" }}>
                {money[duration]}
              </span>
              元
            </div>
            {money.length == 0 ? (
              <Button type="primary" disabled style={Style_but} >
                &nbsp;&nbsp;支付&nbsp;&nbsp;
              </Button>
            ) : (
                <Button type="primary" style={Style_but} onClick={() => this.payFee(money[duration])} >
                  &nbsp;&nbsp;支付&nbsp;&nbsp;
                </Button>
              )}
          </WingBlank>
        </span>

        <Modal
          style={{ marginTop: "-150px" }}
          className="infoModal"
          visible={infoVisible}
          transparent
          title=""
          footer={[{ text: "去支付", onPress: () => this.paySure(), },]}
        >
          <img alt="logo" style={{ width: '60px', }} src={test1} />
          <div style={{ textAlign: "center", fontSize: "18px", color: "#334161", fontFamily: 'PingFang SC', letterSpacing: '2px', fontWeight: 'lighter', }}>
            <p style={{ fontWeight: '400', fontSize: "28px", }}>{data.parkingLotName}</p>
            <span>{data.adress}</span>
            <WhiteSpace size="lg" />
            <p style={{ fontWeight: '400', fontSize: "28px", }}>{carNumber}</p>
            <p style={{ letterSpacing: '0', }}>包月时长：{renewDay}个月</p>
            <p style={{ letterSpacing: '0', fontWeight: 'normal', }}>
              {startTime} 至 {endTime}
            </p>
            <a className="ModalClose" onClick={() => this.setState({ infoVisible: false, })}>X</a>
          </div>
        </Modal>
        <Modal
          style={{ marginTop: "-50px" }}
          className="carFooter"
          visible={this.state.modal1}
          transparent
          maskClosable={false}
          onClose={this.onCloseNum("modal1")}
          title=""
          footer={[
            {
              text: "确定",
              onPress: () => {
                this.onCloseNum("modal1")();
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <div style={{ fontSize: "18px", color: "#000000" }}>
              <b>输入车牌号码</b>
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <div style={{ display: "flex" }}>
              <div style={carNumbers}>{showNum[0]}</div>
              <div style={carNumbers}>{showNum[1]}</div>
              <div style={carNumbers}>{showNum[2]}</div>
              <div style={carNumbers}>{showNum[3]}</div>
              <div style={carNumbers}>{showNum[4]}</div>
              <div style={carNumbers}>{showNum[5]}</div>
              <div style={carNumbers}>{showNum[6]}</div>
              {this.state.showStyle == false ? (
                <div style={carNumbers} onClick={this.eightNum}>
                  {showNum[7]}
                </div>
              ) : (
                  <div style={carNumberNew} onClick={this.eightNum}>
                    <span style={{ color: "#ffffff", fontSize: "12px" }}>
                      <p style={{ paddingTop: "2px" }}>新</p>
                      <p>能源</p>
                    </span>
                  </div>
                )}
            </div>
          </div>
        </Modal>
        <Modal
          className="carFooter"
          visible={this.state.modal2}
          transparent
          maskClosable={false}
          onClose={this.onCloseDeal("modal2")}
          footer={[
            {
              text: "我已阅读该协议",
              onPress: () => {
                this.onCloseDeal("modal2")();
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div style={{ height: "460px", color: "#2B2B2B", textAlign: "left" }}>
            <div dangerouslySetInnerHTML={{ __html: `${dealContent}` }}></div>
          </div>
        </Modal>
        {/*键盘*/}
        <div className="keyboardBox" style={keyboardBox}>
          <div
            style={{
              height: "30px",
              backgroundColor: "#fff",
              lineHeight: "30px",
              textAlign: "end",
            }}
            onClick={this.closeKeyBorad}
          >
            完成 &nbsp;
          </div>
          <div
            className="provienceBox"
            className="provienceBox"
            style={textBox}
          >
            <ul onClick={this.NumListClick}>
              <li>京</li>
              <li>津</li>
              <li>渝</li>
              <li>沪</li>
              <li>冀</li>
              <li>晋</li>
              <li>辽</li>
              <li>吉</li>
              <li>黑</li>
              <li>苏</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>浙</li>
              <li>皖</li>
              <li>闽</li>
              <li>赣</li>
              <li>鲁</li>
              <li>豫</li>
              <li>鄂</li>
              <li>湘</li>
              <li>粤</li>
              <li>琼</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>川</li>
              <li>贵</li>
              <li>云</li>
              <li>陕</li>
              <li>甘</li>
              <li>青</li>
              <li>蒙</li>
              <li>桂</li>
              <li>宁</li>
              <li>新</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li
                className="changeContentBtn other"
                onClick={this.changeContentBtn}
              >
                <img
                  src={require("../../components/images/shuzi.png")}
                  style={{ width: "20px", height: "20px", paddingTop: "10px" }}
                  alt="ABC"
                />
              </li>
              <li>藏</li>
              <li>使</li>
              <li>挂</li>
              <li>警</li>
              <li>学</li>
              <li>港</li>
              <li>澳</li>
              <li className="deleteBtn other" onClick={this.delText}>
                <img
                  src={require("../number/del.png")}
                  style={{ height: "15px" }}
                  alt="删除"
                />
              </li>
            </ul>
          </div>
          <div className="textBox" className="textBox" style={provBox}>
            <ul onClick={this.NumListClick}>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>0</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>Q</li>
              <li>W</li>
              <li>E</li>
              <li>R</li>
              <li>T</li>
              <li>Y</li>
              <li>U</li>
              {/* <li>I</li>
              <li>O</li> */}
              <li>P</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>A</li>
              <li>S</li>
              <li>D</li>
              <li>F</li>
              <li>G</li>
              <li>H</li>
              <li>J</li>
              <li>K</li>
              <li>L</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li
                className="changeContentBtn other"
                onClick={this.changeContentBtn}
              >
                <img
                  src={require("../../components/images/fanhui.png")}
                  style={{ width: "20px", height: "20px", paddingTop: "10px" }}
                  alt="返回"
                />
              </li>
              <li>Z</li>
              <li>X</li>
              <li>C</li>
              <li>V</li>
              <li>B</li>
              <li>N</li>
              <li>M</li>
              <li className="deleteBtn other" onClick={this.delText}>
                <img
                  src={require("../number/del.png")}
                  style={{ height: "15px" }}
                  alt="删除"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const WrappedForm = createForm()(MonthlyDetail);
export default WrappedForm;