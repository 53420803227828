/*手机号绑定界面路由组件*/
import React, { Component } from "react";
import { Flex, WingBlank, WhiteSpace, Button, Toast } from "antd-mobile";
import "../../components/boom.css";
import "./index.css";
import { getUrlByLaneId, } from '../../fetch'
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const car = {
  marginTop: "36px",
};
const center = {
  height: "154px",
  backgroundColor: "#ffffff",
  borderRadius: "5px",
  marginTop: "-14px",
};
const numberStyle = {
  textAlign: "center",
  fontSize: "16px",
};
const numberQuery = {
  textAlign: "center",
  fontSize: "12px",
  color: "#CBC9C9",
};
const carNumber = {
  border: "1px dashed #2D96FE",
  width: "30px",
  height: "30px",
  borderRadius: "3px",
  textAlign: "center",
  lineHeight: "30px",
  marginLeft: "2px",
};

export default class PhoneBind extends Component {
  constructor(props) {
    super(props);
    this.state = {
      underway: "",
      cost: "",
      show: true,
      strArr: "",
      numberHistory: "",
      str: "",
      carHistory: "",
      showStyle: true,
      channel: "",
      currentColor:1,
    };
    this.handlesclick = this.handlesclick.bind(this);
    this.carLicenseMain = this.carLicenseMain.bind(this);
    this.delText = this.delText.bind(this);
    this.NumListClick = this.NumListClick.bind(this);
    this.closeKeyBorad = this.closeKeyBorad.bind(this);
  }

  componentDidMount() {
    this.start()
  }

  start = () => {
    // http://m.elascloud.com/phoneBind?laneCode=5
    // const query = this.props.location.search;
    // const code = query.split("=")[1]
    // http://m.elascloud.com/p/5
    let path = this.props.location.pathname; //为/p/5
    let code = path.split("/")[2];
    getUrlByLaneId({ elasLaneId: code }).then(res => {
      this.setState({ channel: code, _url: res.rst, });
      if (res.code !== 1000) { alert(res.desc) }
    })
  }

  handlesclick = (keyword) => {
    const { _url, str, channel,currentColor } = this.state;
    Toast.loading("绑定中", 3);
    window.sessionStorage.setItem("phone", str);
    const carNum = {
      channelId: channel,
      phone: str,
      currentColor
    };
    // 正则判断表达式 手机号是否正确
    const phoneReg = /^[1][3-9][0-9]{9}$/;
    if (!str) {
      alert("未识别到手机号");
      Toast.hide();
      return false;
    } else if (!phoneReg.test(str)) {
      alert("手机号填写有误");
      Toast.hide();
      return false;
    } //填写信息入场调用
    else {
      const url = _url + "c/cutOffManage/cutOffQrCode";
      fetch(url, {
        method: "POST",
        headers: { "Content-type": "application/json", },
        body: JSON.stringify(carNum),
      }).then((res) => res.json())
        .then((data) => {
          Toast.hide();
          if (data.code == 1000) {
            this.props.history.push({ pathname: "../phoneBindStatus" });
          } else { alert(data.desc) }
        });
    }
  };

  //键盘显示隐藏
  carLicenseMain() {
    this.setState({ show: !this.state.show });
  }

  //点击空白处关闭键盘
  closeKeyBorad() {
    this.setState({
      show: false,
    });
  }

  //车牌号字母数字读取
  NumListClick(e) {
    //阻止事件冒泡
    if (e.target && e.target.matches("ul")) {
      return false;
    }
    let str = this.state.str;
    let strRes = (str += e.target.innerText);
    //手机号码最多为11位
    const realStr = strRes.slice(0, 11);
    this.setState({ str: realStr, });
  }

  // 删除已输入车牌号
  delText(e) {
    e.stopPropagation();
    let newStr = this.state.str.substring(0, this.state.str.length - 1);
    this.setState({
      str: newStr,
    });
  }

  render() {
    const keyboardBox = {
      display: "block",
    };
    const showNum = this.state.str.split("");
    const {currentColor} = this.state;
    return (
      <div style={sectionStyle}>
        <div onClick={this.closeKeyBorad} style={{ textAlign: "center" }}>
          <img
            src={require("../../components/images/car.png")}
            style={car}
            alt="标签"
          />
        </div>
        <WingBlank>
          <div style={center}>
            <div onClick={this.closeKeyBorad}>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={numberStyle}>
                <b>输入手机号码</b>
              </div>
              <WhiteSpace></WhiteSpace>
              <div style={numberQuery}>绑定手机入场停车</div>
              <WhiteSpace></WhiteSpace>
              {/* <div className="colorTabs">
                <div className={currentColor==1?'activeTab':undefined} onClick={e=>this.setState({currentColor:1})}><div>蓝牌</div></div> 
                <div className={currentColor==2?'activeTab':undefined} onClick={e=>this.setState({currentColor:2})}><div>绿牌</div></div>
              </div> */}
              <WhiteSpace></WhiteSpace>
            </div>
            <WingBlank>
              <WhiteSpace onClick={this.closeKeyBorad}></WhiteSpace>
              <Flex>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[0]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[1]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[2]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[3]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[4]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[5]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[6]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[7]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[8]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[9]}
                </Flex.Item>
                <Flex.Item style={carNumber} onClick={this.carLicenseMain}>
                  {showNum[10]}
                </Flex.Item>
              </Flex>
            </WingBlank>
          </div>
          <div onClick={this.closeKeyBorad}>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <Button
              style={{ borderRadius: "25px" }}
              onClick={this.handlesclick}
            >
              确定入场
            </Button>
          </div>
        </WingBlank>
        <div style={{ height: "200px" }} onClick={this.closeKeyBorad}></div>

        {/*键盘*/}
        <div className="keyboardBox" style={keyboardBox}>
          <div className="phoneBox" className="phoneBox">
            <ul onClick={this.NumListClick}>
              <li>1</li>
              <li>2</li>
              <li>3</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>4</li>
              <li>5</li>
              <li>6</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li>7</li>
              <li>8</li>
              <li>9</li>
            </ul>
            <ul onClick={this.NumListClick}>
              <li
                className="changeContentBtn other"
                onClick={this.closeKeyBorad}
              >
                <img
                  src={require("../../components/images/closeKeyboard.png")}
                  style={{ width: "24px", height: "24px", padding: "8px" }}
                  alt="关闭键盘"
                />
              </li>
              <li>0</li>
              <li className="deleteBtn other" onClick={this.delText}>
                <img
                  src={require("../number/del.png")}
                  style={{ height: "15px" }}
                  alt="删除"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
