import request from '../utils/request';

export async function judgeUserCard(params) {
    return request('c/userManage/judgeUserCard', {
        method: 'POST',
        body: params,
    });
}

export async function getCardByCarNum(params) {
    return request('c/userManage/getCardByCarNum', {
        method: 'POST',
        body: params,
    });
}