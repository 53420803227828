/*出场手机查询路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace, Button, Modal } from "antd-mobile";
import "../../components/boom.css";
import "../phoneBind/index.css";
import { withRouter } from "react-router-dom";
import { getUserCarList2, getUrlByLaneId, } from "../../fetch"
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  height: "210px",
  backgroundColor: "#ffffff",
  borderRadius: "5px",
};
const numberStyle = {
  textAlign: "center",
  fontSize: "16px",
};
const numberQuery = {
  textAlign: "center",
  fontSize: "13px",
  color: "#8E8D8D",
};
const carNumber = {
  border: "1px solid #2D96FE",
  height: "40px",
  borderRadius: "1px",
  textAlign: "right",
  paddingRight: "16px",
  lineHeight: "40px",
  marginLeft: "2px",
  fontSize: "20px",
};

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}
class PhoneLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1: false,
      underway: "",
      cost: "",
      show: false,
      strArr: "",
      numberHistory: "",
      str: "",
      carHistory: "",
    };
    this.handlesclick = this.handlesclick.bind(this);
    this.phoneLicenseMain = this.phoneLicenseMain.bind(this);
    this.delText = this.delText.bind(this);
    this.NumListClick = this.NumListClick.bind(this);
    this.closeKeyBorad = this.closeKeyBorad.bind(this);
  }

  onClose = (key) => () => {
    this.setState({
      [key]: false,
    });
  };

  handlesclick = () => {
    window.sessionStorage.setItem("carNum", this.state.str);
    const landCode = window.sessionStorage.getItem("landCode");
    const carNum = {
      carNum: this.state.str,
      type: landCode,
    };
    if (!this.state.str) {
      alert("未识别到手机号");
    } else {
      //查询是否有进行中订单
      const url = "c/h5Manage/getRunOrderByCarNum";
      const param = {
        requestUrl: url,
        param: JSON.stringify(carNum),
        baseCpId: null,
      };
      fetch("a/request/all", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(param),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            underway: data.rst,
          });
          if (this.state.underway) {
            this.props.history.push("./more");
          } else {
            this.fulfillOrder();
          }
        });
    }
  };

  //查询是否有欠费订单
  fulfillOrder() {
    const carNums = {
      orderStatus: 1,
      carNum: this.state.str,
    };
    const url = "c/h5Manage/getArrearsRecord";
    const param = {
      requestUrl: url,
      param: JSON.stringify(carNums),
      baseCpId: null,
    };
    fetch("a/request/all", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          cost: data.rst.list,
        });
        if (this.state.cost.length > 0) {
          this.props.history.push({
            pathname: "./costRecord",
            carNum: { orderStatus: "1", carNum: this.state.str },
          });
        } else {
          this.setState({
            modal1: true,
          });
        }
      });
  }

  onWrapTouchStart = (e) => {
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };

  //键盘显示隐藏
  phoneLicenseMain() {
    this.setState({ show: !this.state.show });
  }

  //点击空白处关闭键盘
  closeKeyBorad() {
    this.setState({
      show: false,
    });
  }

  //手机号数字读取
  NumListClick(e) {
    //阻止事件冒泡
    if ((e.target && e.target.matches("ul")) || e.target.matches("div")) {
      return false;
    }
    let str = this.state.str;
    let strRes = (str += e.target.innerText);
    const realStr = strRes.slice(0, 11);
    this.setState({str: realStr,});
  }

  // 删除已输入手机号
  delText(e) {
    e.stopPropagation();
    let newStr = this.state.str.substring(0, this.state.str.length - 1);
    this.setState({
      str: newStr,
    });
  }

  componentDidMount() {
    window.sessionStorage.setItem("hasCode", "2");
    window.sessionStorage.setItem("hasCarNumber", "0");
    const _landCodeArr = this.props.location.search.split('?')[1];
    if(_landCodeArr){
      const _landCode = _landCodeArr.split('=')[1];
      window.sessionStorage.setItem('elasLaneId',_landCode)
    }
    const code = window.sessionStorage.getItem("landCode");
    let url = window.location.href;
    //微信获取Id
    if (url.indexOf("code") > 0) {
      // http://m.elascloud.com/phoneLeave?laneCode=4&code=061oWbxg00n0Qs1MAmAg0Ytrxg0oWbxD&state=STATE
      const arr = url.split("&");
      const code1 = arr[1].split("=")[1];
      const params = { code:code1, type: 2, };
      // 根据用户CODE获取用户常用车辆
      this.carUsual({ params, code, });
    }
    //支付宝获取Id
    else if (url.indexOf("auth_code") > 0) {
      // http://pay-test.elascloud.com/phoneLeave?laneCode=4&app_id=2019112169349514&source=alipay_wallet&scope=auth_base&auth_code=4d9bf65140d944c6836b9ac68b53RX05
      let arr = url.split("&");
      const auth_code = arr[4].split("=")[1].replace("#/", "");
      const params = {
        code: auth_code,
        type: 1,
      };
      // 根据用户CODE获取用户常用车辆
      this.carUsual({ params, code, });
    }else{ this.getCode({ code, type:false }) }
  }
  carUsual = payload => {
    const { params, code, } = payload;
    getUserCarList2(params).then(data=>{
      window.sessionStorage.setItem("userId", data.rst.userId);
      this.getCode({ code, type:true })
    })
  }

  getCode = params => {
    const { code, type } = params;
    if(code==null){ return false; }
    const carNum = { elasLaneId: code, };
    getUrlByLaneId(carNum).then(result=>{
      const _url = result.rst;
      let url = `${_url}c/h5Manage/getNewOrderByLaneId`
      fetch(url, {
        method: "POST",
        headers: { "Content-type": "application/json", },
        body: JSON.stringify(carNum),
      }).then((res) => res.json())
        .then((data) => {
          if(data.rst){
            window.sessionStorage.setItem("carNum",data.rst.carNumber);
            this.setState({ underway: data.rst, },()=>{
              if(type){
                this.props.history.push("./more");
              }
            });
          }
        });
    })
  }

  render() {
    const keyboardBox = {
      display: "block",
    };
    const showNum = this.state.str;
    // eslint-disable-next-line
    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            {/*输入手机号样式*/}
            <div style={center}>
              <div onClick={this.closeKeyBorad}>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                <div style={numberStyle}>
                  <b>输入手机号码</b>
                </div>
                <WhiteSpace></WhiteSpace>
                <div style={numberQuery}>请输入手机号进行缴费离场</div>
                <WhiteSpace></WhiteSpace>
              </div>
              <WingBlank>
                <WhiteSpace></WhiteSpace>
                <div style={carNumber} onClick={this.phoneLicenseMain}>
                  {showNum}
                </div>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                <Button
                  type="primary"
                  style={{ borderRadius: "8px" }}
                  onClick={this.handlesclick}
                >
                  查询
                </Button>
              </WingBlank>
            </div>
          </WingBlank>
          {/*键盘*/}
          <div className="keyboardBox" style={keyboardBox}>
            <div className="phoneBox" className="phoneBox">
              <ul onClick={this.NumListClick}>
                <li>1</li>
                <li>2</li>
                <li>3</li>
              </ul>
              <ul onClick={this.NumListClick}>
                <li>4</li>
                <li>5</li>
                <li>6</li>
              </ul>
              <ul onClick={this.NumListClick}>
                <li>7</li>
                <li>8</li>
                <li>9</li>
              </ul>
              <ul onClick={this.NumListClick}>
                <li
                  className="changeContentBtn other"
                  onClick={this.closeKeyBorad}
                >
                  <img
                    src={require("../../components/images/closeKeyboard.png")}
                    style={{ width: "24px", height: "24px", padding: "8px" }}
                    alt="关闭键盘"
                  />
                </li>
                <li>0</li>
                <li className="deleteBtn other" onClick={this.delText}>
                  <img
                    src={require("../number/del.png")}
                    style={{ height: "15px" }}
                    alt="删除"
                  />
                </li>
              </ul>
            </div>
          </div>

          {/*未查询到欠费订单*/}
          <Modal
            visible={this.state.modal1}
            transparent
            maskClosable={false}
            onClose={this.onClose("modal1")}
            footer={[
              {
                text: "知道了",
                onPress: () => {
                  this.onClose("modal1")();
                },
              },
            ]}
            wrapProps={{ onTouchStart: this.onWrapTouchStart }}
          >
            <div
              style={{
                height: 190,
                backgroundImage: `url(${require("../../components/images/no.png")})`,
              }}
            >
              <h3 style={{ color: "#000000" }}>未查询到欠费记录</h3>
              <span style={{ color: "#CBC9C9" }}>关注公众号"云之合"</span>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
export default withRouter(PhoneLeave);
