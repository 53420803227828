/*发票列表-包月路由组件*/
import React, { Component, Fragment } from "react";
import {
  WingBlank,
  WhiteSpace,
  Button,
  Checkbox,
  DatePicker,
  List,
  ListView,
} from "antd-mobile";
import { createForm } from "rc-form";
import ReactDOM from "react-dom";
import { getElasCardDetailList2 } from "../../fetch/index";
import moment from 'moment';
const CheckboxItem = Checkbox.CheckboxItem;
const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
const waitStyle = {
  backgroundColor: "#ffffff",
  borderRadius: "12px",
  boxShadow:'0px 6px 12px 0px rgba(228,230,233,0.5)',
  marginBottom:"20px",
};
const topBack = {
  backgroundColor: "#ffffff",
  position: "fixed",
  width: "100%",
  top: "0",
  zIndex: 999,
};
const topSort = {
  height: "40px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const isIPhone = new RegExp("\\biPhone\\b|\\biPod\\b", "i").test(
  window.navigator.userAgent
);
// eslint-disable-next-line
let wrapProps;
if (isIPhone) {
  wrapProps = { onTouchStart: (e) => e.preventDefault(), };
}
class MonthlyList extends Component {
  constructor(props) {
    super(props);
    const arrearageDataSource = new ListView.DataSource({ rowHasChanged: (row1, row2) => row1 !== row2,});
    this.invoicePageNum = 1; // 可开票订单定义分页信息
    this.invoicePageSize = 3;
    this.elsePageNum = 1; // 其他订单分页信息
    this.elsePageSize = 3;

    this.state = {
      date: undefined,
      arrearage: [],
      isAllChecked: false,
      money: 0,
      orderCode: "",
      tabIndex: 0,
      show: false,
      down: false,
      invoice: true,
      startTime:undefined,
      endTime:undefined,
      arrearageDataSource,
      refreshing: true,
      isLoading: true,
      height: document.documentElement.clientHeight,
      useBodyScroll: true,
      hasMore: true,
      baseCpId:undefined,
    };
  }

  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
      this.props.history.push('/'); 
      return false;
    }
    this.start();
  }

  // 1 初始化数据
  start = () => {
    window.sessionStorage.setItem("addInvoiceType", "2");
    const params = {
      invoiceFlag: this.state.invoice ? 0 : 1,
      pageNum: this.state.invoice ? this.invoicePageNum : this.elsePageNum,
      pageSize: this.state.invoice ? this.invoicePageSize : this.elsePageSize,
    };
    this._getInvoiceList(params).then(list=>{
      this.rData = list;
      const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
      this.setState({
        arrearage: this.rData,
        arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(this.rData),
        height: hei,
        refreshing: false,
        isLoading: false,
      });
    })
  };

  way = () => {
    this.state.orderCode == ""
      ? alert("请选择要开票的订单")
      : this.props.history.push({ pathname: "./invoice" });
    window.sessionStorage.setItem("orderCode", this.state.orderCode);
    window.sessionStorage.setItem("fee", this.state.money);
  };

  total() {
    let arr = this.state.arrearage;
    let price = 0;
    const a = [];
    // eslint-disable-next-line
    arr && arr.map((item) => {
        if (item.checked == true) {
          const fee = parseFloat(item.cardFee);
          price += fee;
          a.push(item.orderCode);
        }
      });
    this.setState({
      money: price.toFixed(2),
      orderCode: a,
    });
  }

  // 全选
  isAllChecked(e) {
    // 判断是否选择了相同的公司
    let _Arr = [],_Arr2 = [];
    this.state.arrearage.map(item=>{
      _Arr.push(item.baseCpName)
      _Arr2.push(item.baseOrgId)
    })
    let _SetArr = [...new Set(_Arr)]
    if(_Arr.length>0&&_SetArr.length!==1){
      alert('请选择相同公司的订单进行开票！')
      return false;
    }
    let _SetArr2 = [...new Set(_Arr2)]
    if(_Arr2.length>0&&_SetArr2.length!==1){
      alert('请选择相同组织的订单进行开票！')
      return false;
    }
    // console.log(_Arr2,_SetArr2);
    // 判断是否选择了相同的公司
    let bool = e.target.checked;
    if (bool) {
      this.setState({ isAllChecked: true, });
      this.state.arrearage.filter((item) => item.checked = true);
    } else {
      this.setState({ isAllChecked: false, });
      this.state.arrearage.filter((item) => item.checked = false);
    }
    this.setState({
      arrearage: this.state.arrearage,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        JSON.parse(JSON.stringify(this.state.arrearage)) // 更改state数据时也要把dataSource重新赋值
      ),
    });
    this.total();
  }

  // 勾选
  singleChecked(e, i) {
    this.state.arrearage[i].checked = e.target.checked;
    this.setState({
      arrearage: this.state.arrearage,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        JSON.parse(JSON.stringify(this.state.arrearage)) // 更改state数据时也要把dataSource重新赋值
      ),
    });
    var bool = this.state.arrearage.filter((item) => {
      return !item.checked;
    });
    if (bool.length == 0) {
      this.setState({ isAllChecked: true, });
    } else {
      this.setState({ isAllChecked: false, });
    }
    this.total();
  }

  _getInvoiceList = params =>{
    return new Promise(resolve=>{
      const { invoice } = this.state;
      if (invoice) { this.invoicePageNum++; } else { this.elsePageNum++; }
      const payload = { 
        ...params, 
        elasUserId: window.sessionStorage.getItem("userId"), 
        baseApplicationId: 74, 
        baseOrgId: 1, 
      };
      getElasCardDetailList2(payload).then((res) => {
        if (res) {
          if (res.code == 1000) { 
            if(res.rst.list.length){ 
              const prefixUrl = res.rst.list[0].prefixUrl 
              window.sessionStorage.setItem('prefixUrl',prefixUrl)
            }
            resolve(res.rst.list) 
          } else { alert(res.desc); }
        } else { this.setState({ hasMore: false, }); }
      })
    })
  }

  showSelect = () => { this.setState({ down: !this.state.down, });};

  changeType = async () => {
    this.setState({
      invoice: !this.state.invoice,
      down: false,
    });
    const invoiceStatus = !this.state.invoice;
    const params = {
      invoiceFlag: invoiceStatus ? 0 : 1,
      pageNum: invoiceStatus
        ? (this.invoicePageNum = 1)
        : (this.elsePageNum = 1),
      pageSize: invoiceStatus ? this.invoicePageSize : this.elsePageSize,
    };
    this._getInvoiceList(params).then(list=>{
      this.rData = list;
      this.setState({
        arrearage: this.rData,
        arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
          this.rData
        ),
        refreshing: false,
        isLoading: false,
      });
    })
  };

  showPop = () => this.setState({ show: true,});
  
  // 重置查询条件
  closePop = () => {
    this.invoicePageNum = 1; // 可开票订单定义分页信息
    this.invoicePageSize = 3;
    this.elsePageNum = 1; // 其他订单分页信息
    this.elsePageSize = 3;
    this.props.form.resetFields();
    this.setState({ show: false, startTime:undefined, endTime:false, baseCpId:undefined, },()=>this.start());
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, fieldsValue) => {
      if(!err){
        const { startTime, endTime, } = fieldsValue;
        const { invoice, baseCpId, } = this.state;

        if(startTime&&endTime){
          const d = new Date(fieldsValue.startTime);
          const n = new Date(fieldsValue.endTime);
          if (d.getTime() > n.getTime()) {  alert("开始时间不能大于结束时间"); }
        }
        const params = {
          invoiceFlag: invoice ? 0 : 1,
          startTime: startTime?moment(startTime).format("YYYY-M-D 00:00:00"):undefined,
          endTime: endTime?moment(endTime).format("YYYY-M-D 23:59:59"):undefined,
          pageNum: invoice? (this.invoicePageNum = 1): (this.elsePageNum = 1),
          pageSize: invoice ? this.invoicePageSize : this.elsePageSize,
          baseCpId:baseCpId?baseCpId:undefined,
        };

        this._getInvoiceList(params).then(list=>{
          this.rData = list;
          this.setState({
            money: 0, // 总金额设置为0
            orderCode: [], // 选中的列表设置为空数组
            isAllChecked:false, // 全选按钮设置为取消状态
            arrearage: this.rData,
            arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(this.rData),
            refreshing: false,
            isLoading: false,
            show: false,
            startTime: startTime?moment(startTime).format("YYYY-M-D 00:00:00"):undefined,
            endTime: endTime?moment(endTime).format("YYYY-M-D 23:59:59"):undefined,
          });
        })
      }
    });
  };

  onEndReached = async (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    } //如果this.state.hasMore为false，说明没数据了，直接返回
    this.setState({ isLoading: true });
    let params = {
          invoiceFlag: this.state.invoice ? 0 : 1,
          pageNum: this.state.invoice ? this.invoicePageNum : this.elsePageNum,
          pageSize: this.state.invoice ? this.invoicePageSize : this.elsePageSize,
          startTime: this.state.startTime ? this.state.startTime : undefined,
          endTime: this.state.endTime ? this.state.endTime : undefined,
        }
    this._getInvoiceList(params).then(list=>this.rData=[...this.rData,...list,])
    setTimeout(() => {
      this.setState({
        arrearage: this.rData,
        arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
          this.rData
        ),
        isLoading: false,
      });
    }, 1000);
  };
  
  // 运营商筛选发票列表
  RadioItemChange = item => this.setState({ baseCpId:item.baseCpId, })

  render() {
    const { state, } = this.props.location; // AllBaseCp
    const { getFieldProps, } = this.props.form;
    const { baseCpId, invoice, arrearageDataSource, startTime, endTime, } = this.state;
    const show = this.state.show ? "block" : "none";
    const popStyle = {
      display: show,
    };
    const downstyle = this.state.down ? "rotate(180deg)" : "rotate(0deg)";
    const down = {
      width: "12px",
      height: "12px",
      marginTop:'2px',
      marginLeft:'4px',
      transform: downstyle,
    };
    const popShow = this.state.down ? "block" : "none";
    const menuPop = {
      display: popShow,
      borderTop: "1px solid #F2F2F2",
      backgroundColor: "#ffffff",
      position: "absolute",
      width: "150px",
      top: "40px",
      boxShadow: "2px 2px 5px #888888",
    };

    const row = (rowData, sectionID, rowID) => {
      return (<div key={rowID} style={waitStyle}>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={{ color: "#CBC9C9",fontWeight:'400' }}> 订单编号&nbsp;&nbsp;&nbsp;&nbsp;{rowData.orderCode} </div>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }} />
              <WhiteSpace></WhiteSpace>
              <div style={{ color:'#55617C',fontWeight:'400' }}>
                <div>月租卡号&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.clubCardCode}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>车场名称&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.parkingLotName}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>所属公司&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.baseCpName}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>组织ID&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.baseOrgId}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>车牌号码&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.carNumber}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>包月时长&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.renewDay}&nbsp;个月</span></div>
                <WhiteSpace></WhiteSpace>
                <div>开始时间&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.startTime}</span></div>
                <WhiteSpace></WhiteSpace>
                <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              </div>
            </WingBlank>
            <WhiteSpace></WhiteSpace>
            <CheckboxItem
              onChange={(e) => this.singleChecked(e, rowID)}
              checked={rowData.checked ? true : false}
            >
              <div>金额：<span style={{ color: "#398CFC",fontSize:'18px', }}>{rowData.cardFee} 元</span></div>
              <Button
                type="primary"
                inline
                size="small"
                onClick={() => {
                  sessionStorage.setItem("fee", rowData.cardFee);
                  sessionStorage.setItem("orderCode", rowData.orderCode);
                  this.props.history.push({
                    pathname: "./invoice",
                  });
                }}
                style={{
                  width:'100px',
                  marginRight: "4px",
                  borderRadius: "6px",
                  position: "absolute",
                  right: "10px",
                  top: "7px",
                  fontSize:"14px",
                  background:'linear-gradient(-10deg,rgba(123,133,252,1) 0%,rgba(75,111,252,1) 100%)',
                }}
              >
                开票
              </Button>
            </CheckboxItem>
          </div>);
    };

    const row2 = (rowData, sectionID, rowID) => {
      return (
          <div key={rowID} style={waitStyle}>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={{ color: "#CBC9C9" }}>订单编号&nbsp;&nbsp;&nbsp;&nbsp;{rowData.invoiceCode}</div>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              <div style={{ color:'#55617C' }}>
                <WhiteSpace></WhiteSpace>
                <div>月租卡号&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.clubCardCode}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>车牌号码&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.carNumber}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>车场名称&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.parkingLotName}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>包月时长:&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.renewDay}&nbsp;个月</span></div>
                <WhiteSpace></WhiteSpace>
                <div>包月费用&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.cardFee} 元</span></div>
                <WhiteSpace></WhiteSpace>
                <div>开始时间&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.startTime}</span></div>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
              </div>
            </WingBlank>
            <div style={{ height:"15px",width:"100%",background:"rgb(235,237,240)" }}/>
          </div>
      );
    };
    return (
      <div className="listbackground">
        <div className="maskPop" style={popStyle}>
          <div className="secondPop">
            <div className="actionPop">
              <WingBlank>
                <div style={{ height: "100vh" }}>
                  <WhiteSpace></WhiteSpace>
                  <WhiteSpace></WhiteSpace>
                  <WhiteSpace></WhiteSpace>
                  <div style={{ fontSize: "18px", marginBottom: "10px" }}>
                    包月日期
                    <div
                      type="ghost"
                      size="small"
                      style={{ float:"right",fontSize:"22px" }}
                      onClick={this.closePop}
                    >
                      ×
                    </div>
                  </div>
                  <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
                  <DatePicker mode="date" {...getFieldProps("startTime", { initialValue: startTime, })}>
                    <List.Item arrow="horizontal">开始日期</List.Item>
                  </DatePicker>
                  <DatePicker mode="date" {...getFieldProps("endTime", { initialValue: endTime, })}>
                    <List.Item arrow="horizontal">结束日期</List.Item>
                  </DatePicker>
                  <WhiteSpace />
                  
                  {/* 车场筛选 */}
                  {state&&state.AllBaseCp.length?
                  <Fragment>
                    <div style={{ fontSize: "18px", marginBottom: "10px" }}>车场筛选</div>
                    <div style={{ borderBottom: "1px solid #F2F2F2" }} />
                    <WhiteSpace size="lg"/>
                    {state.AllBaseCp.map(item=>
                      <WingBlank size="lg" key={item.baseCpId}>
                        <Button size="small" type={baseCpId==item.baseCpId?"primary":"ghost"} onClick={()=>this.RadioItemChange(item)}> {item.baseCpName} </Button>
                        <WhiteSpace size="md"/>
                      </WingBlank>)
                    }
                  </Fragment>
                  :null
                  }

                  <WhiteSpace></WhiteSpace>
                  <div style={{ position: "absolute", bottom: "20px" }}>
                    <Button type="ghost" inline size="small" style={{ marginRight: "8px", padding: "0 30px" }}
                      onClick={this.closePop}
                    >
                      重置
                    </Button>
                    <Button type="primary" inline size="small" style={{ marginRight: "4px", padding: "0 30px" }}
                      onClick={this.onSubmit}
                    >
                      确定
                    </Button>
                  </div>
                </div>
              </WingBlank>
            </div>
          </div>
        </div>
        <div style={topBack}>
          <WingBlank>
            <WingBlank>
              <div style={topSort}>
                <div style={{ display: "flex" }} onClick={this.showSelect}>
                  <div style={{ color:'#7C90B6', }}>{invoice ? "可开票订单" : "其他订单"}</div>
                  <img
                    src={require("../../Photo/test2.png")}
                    style={down}
                    alt="标签"
                  />
                </div>
                <div style={{ display: "flex",background:'#D4D6D9',width:'1px', height:'20px', }}/>
                <div style={{ display: "flex",color:'#7C90B6', }} onClick={this.showPop}>
                  <div>订单筛选</div>
                  <img
                    src={require("../../Photo/test3.png")}
                    style={{ width: "14px", height: "14px", marginTop:'2px',marginLeft:"4px", }}
                    alt="标签"
                  />
                </div>
              </div>
            </WingBlank>
          </WingBlank>
          <div style={menuPop}>
            <div>
              <WingBlank>
                <WingBlank>
                  <div style={{ height: "30px", lineHeight: "30px", color:'#7C90B6', }} onClick={this.changeType}>
                    {invoice ? "其他订单" : "可开票订单"}
                  </div>
                </WingBlank>
              </WingBlank>
            </div>
          </div>
        </div>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        {invoice ? (
          <div>
            <WingBlank>
              <ListView
                key={this.state.useBodyScroll ? "0" : "1"}
                ref={(el) => (this.lv = el)}
                dataSource={arrearageDataSource}
                renderFooter={() => (// renderFooter就是下拉时候的loading效果
                    <div style={{ padding: 30, textAlign: "center" }}>
                      {this.state.isLoading ? "加载中..." : "加载完成"}
                    </div>
                  )
                }
                renderRow={row} //渲染你上边写好的那个row
                useBodyScroll={this.state.useBodyScroll}
                style={
                  this.state.useBodyScroll
                    ? {}
                    : {
                        height: this.state.height,
                        border: "1px solid #ddd",
                        margin: "5px 0",
                      }
                }
                onEndReached={this.onEndReached}
                invoicePageSize={this.invoicePageSize} //每次下拉之后显示的数据条数
              />
            </WingBlank>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <span
              style={{
                boxSizing:'border-box',
                position: "fixed",
                width: "100%",
                bottom: "0",
                padding: "8px 0",
              }}
            >
              <WingBlank size="lg">
                <WingBlank size="md">
                  <CheckboxItem
                    style={{ 
                      boxSizing:'border-box',
                      borderRadius:'8px',
                      background: "linear-gradient(-10deg,rgba(123,133,252,1) 0%,rgba(75,111,252,1) 100%)", 
                      boxShadow:'0px 10px 18px 0px rgba(64,95,216,0.3)',
                    }}
                    checked={this.state.isAllChecked ? true : false}
                    onChange={(e) => this.isAllChecked(e)}
                  >
                    <div style={{ color:'#fff',fontWeight:'400',fontSize:'14px', }}>
                      全选&nbsp;
                      总金额：<span>{this.state.money} 元</span>
                    </div>
                    <span
                      type="primary"
                      inline
                      size="small"
                      style={{
                        zIndex:'999',
                        position: "absolute",
                        right: "20px",
                        top: "8px",
                        fontSize:"16px",
                        color:'#fff',
                        lineHeight:'27px'
                      }}
                      onClick={this.way}
                    >
                      <span style={{ display:"inline-block",background:"#1324A3",width:"1px",height:"20px",top:"3px",position:'absolute',left:'0' }}/>
                      &nbsp;&nbsp;&nbsp;开 票
                    </span>
                  </CheckboxItem>
                </WingBlank>
              </WingBlank>
            </span>
          </div>
        ) : (
          <div>
            <WingBlank>
              <ListView
                key={this.state.useBodyScroll ? "0" : "1"}
                ref={(el) => (this.lv = el)}
                dataSource={arrearageDataSource}
                renderFooter={() => ( // renderFooter就是下拉时候的loading效果
                    <div style={{ padding: 30, textAlign: "center" }}>
                      {this.state.isLoading ? "加载中..." : "加载完成"}
                    </div>
                  )
                }
                renderRow={row2} //渲染你上边写好的那个row
                useBodyScroll={this.state.useBodyScroll}
                style={
                  this.state.useBodyScroll
                    ? {}
                    : {
                        height: this.state.height,
                        border: "1px solid #ddd",
                        margin: "5px 0",
                      }
                }
                onEndReached={this.onEndReached}
                invoicePageSize={this.invoicePageSize} //每次下拉之后显示的数据条数
              />
            </WingBlank>
          </div>
        )}
      </div>
    );
  }
}
const WrappedForm = createForm()(MonthlyList);
export default WrappedForm;
