/*关联设备路由组件*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Select, Input, } from "antd";
import "antd/lib/select/style/index.css";
import "antd/lib/input/style/index.css";
import { Flex, List, WingBlank, WhiteSpace } from "antd-mobile";
import fetchJsonp from "fetch-jsonp";
const { Option } = Select;
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  marginTop: "12px",
  paddingBottom: "12px",
};
const link = {
  position: "fixed",
  bottom: "0",
  left: "28%",
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wisdomPileCode: "",
      longitude: "",
      dimension: "",
      driveAdress: "",
      parkingLotCode: "",
      //   berthOne: "",
      //   berthTwo: "",
      lat: "",
      lag: "",
      originHeight: "",
      isOriginHei: true,
      berthArr: [{ num: "" }], // 存储泊位号数组
    };
    this.onChange = this.onChange.bind(this);
    this.onblur = this.onblur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.resize = this.resize.bind(this);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.resize);
  }

  screenChange() {
    window.addEventListener("resize", this.resize);
  }

  componentDidMount() {
    this.handleClick();

    const originHeight = document.documentElement.clientHeight;
    this.screenChange();

    const realCode = window.sessionStorage.getItem("wisdomPileCode");
    this.setState({
      wisdomPileCode: realCode,
      originHeight: originHeight,
    });
  }

  resize() {
    const changeHeight = document.documentElement.clientHeight;
    if (this.state.originHeight > changeHeight) {
      this.setState({
        isOriginHei: false,
      });
    } else {
      this.setState({
        isOriginHei: true,
      });
    }
  }

  getLocation() {
    const BMap = window.BMap;
    const geolocation = new BMap.Geolocation();
    var _this = this;
    geolocation.getCurrentPosition(
      function (r) {
        if (this.getStatus() == 0) {
          var latlon = r.point.lat + "," + r.point.lng;
          fetchJsonp(
            "http://api.map.baidu.com/reverse_geocoding/v3/?ak=fvDO7gRh9gdM1MdTUhQMfL2Y7q1ivPfH&output=json&coordtype=wgs84ll&location=" +
              latlon +
              "&output=json&pois=1",
            {
              method: "GET",
            }
          )
            .then((response) => response.json())
            .then((rst) => {
              if (rst.status == 0) {
                _this.setState({
                  driveAdress: rst.result.formatted_address,
                });
              } else alert(rst.status + "解码出错");
            })
            .catch(function (e) {
            });

          _this.setState({
            dimension: r.point.lat.toFixed(5),
            longitude: r.point.lng.toFixed(5),
          });
        } else {
          alert("failed" + this.getStatus());
        }
      },
      { enableHighAccuracy: true }
    );
  }
  onblur(e) {
    this.setState({
      driveAdress: e.target.value,
    });
    fetchJsonp(
      "https://api.map.baidu.com/geocoding/v3/?address=" +
        this.state.driveAdress +
        "&output=json&ak=fvDO7gRh9gdM1MdTUhQMfL2Y7q1ivPfH&callback=showLocation",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((rst) => {
        // 在此处写获取数据之后的处理逻辑
        if (rst.status == 0) {
          this.setState({
            dimension: rst.result.location.lat.toFixed(5),
            longitude: rst.result.location.lng.toFixed(5),
          });
        } else alert(rst.status + "解码出错");
      })
      .catch(function (e) {
      });
  }

  handleChange(value) {
    this.setState({
      parkingLotCode: value,
    });
  }
  onChange(e) {
    //把当前修改的值赋入state
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onSubmit(e) {
    // 阻止事件传递
    e.preventDefault();
    const { berthArr } = this.state;
    if (berthArr && berthArr.length) {
      const newBerthArr = berthArr.map((i) => i.num);

      const token = sessionStorage.getItem("token");
      // 把表单用的最终数据从state中提取出来,传入请求
      const post = {
        wisdomPileCode: this.state.wisdomPileCode,
        longitude: this.state.longitude,
        dimension: this.state.dimension,
        driveAdress: this.state.driveAdress,
        parkingLotCode: this.state.parkingLotCode,
        berthNumList: newBerthArr,
        //   berthOne: this.state.berthOne,
        //   berthTwo: this.state.berthTwo,
      };

      if (
        (this.state.wisdomPileCode &&
          this.state.longitude &&
          this.state.dimension &&
          this.state.driveAdress &&
          this.state.parkingLotCode) ||
        (this.state.wisdomPileCode &&
          this.state.longitude &&
          this.state.dimension &&
          this.state.driveAdress &&
          this.state.parkingLotCode)
      ) {
        const url = "c/h5Manage/relationDrive";
        const param = {
          requestUrl: url,
          param: JSON.stringify(post),
          baseCpId: null,
        };
        fetch("a/request/all", {
          // post提交
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "X-Token": token,
          },
          body: JSON.stringify(param), //把提交的内容转字符串
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.code == 1000) {
              this.props.history.push({ pathname: "./machine" });
            } else {
              alert("关联失败!请输入正确关联信息");
            }
          });
      } else {
        alert("请输入相应信息");
      }
    }
  }

  handleClick() {
    const url = "c/h5Manage/getParkingLots";
    const param = {
      requestUrl: url,
      param: undefined,
      baseCpId: null,
    };
    fetch("a/request/all", {
      method: "post",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param),
    })
      .then((res) => res.json())
      .then((json) => {
        let hello = json.rst;
        this.setState({
          data: hello,
        });
      });
  }

  addBerthNo = () => {
    const { berthArr } = this.state;
    if (berthArr.length > 9) {
      // message.info("泊位号上限10个！");
      alert("泊位号上限10个！");
    } else {
      const obj = { num: "" };
      const arr = berthArr;
      arr.push(obj);
      this.setState({
        berthArr: arr,
      });
    }
  };

  berthNumOnChange = (e, index) => {
    const { berthArr } = this.state;
    const arr = berthArr;
    arr[index].num = e.target.value;
    this.setState({
      berthArr: arr,
    });
  };

  render() {
    const show = this.state.isOriginHei == true ? "block" : "none";
    const isOriginHei = {
      display: show,
    };
    const { data, berthArr } = this.state;
    return (
      <div style={sectionStyle}>
        {/*<NavBar*/}
        {/*    mode="light"*/}
        {/*    icon={<Icon type="cross" style={{ color:'#000000'}} />}*/}
        {/*    onLeftClick={() => this.props.history.push({pathname:'./main'})}*/}
        {/*><b>关联设备</b></NavBar>*/}
        <WhiteSpace></WhiteSpace>
        <WingBlank>
          <form onSubmit={this.onSubmit.bind(this)}>
            <div style={center}>
              <WingBlank>
                <WingBlank>
                  <List renderHeader={() => "设备编号"}>
                    <Input
                      placeholder={this.state.wisdomPileCode}
                      readOnly
                      defaultValue={this.state.wisdomPileCode}
                    />
                  </List>
                  <List renderHeader={() => "停车场"}>
                    <Select
                      defaultValue="请选择停车场"
                      style={{ width: "100%" }}
                      name="parkingLotCode"
                      onChange={this.handleChange}
                      onClick={this.handleClick.bind(this)}
                    >
                      {data && data.length > 0
                        ? this.state.data.map((item) => (
                            <Option
                              key={item.elasParkingLotId}
                              value={item.parkingLotCode}
                            >
                              {item.parkingLotName}
                            </Option>
                          ))
                        : ""}
                    </Select>
                    {/*<input type="text" name="parkingLotCode" onChange={this.onChange} defaultValue={this.state.parkingLotCode} />*/}
                  </List>
                  {berthArr && berthArr.length
                    ? berthArr.map((item, index) => (
                        <List
                          renderHeader={() =>
                            `${
                              index >= 9
                                ? `泊位号${index + 1}`
                                : `泊位号0${index + 1}`
                            }`
                          }
                        >
                          <Input
                            placeholder=""
                            onChange={(e) => this.berthNumOnChange(e, index)}
                            defaultValue={item.num}
                          />
                        </List>
                      ))
                    : null}
                  <span
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      width: "100%",
                      marginTop: 10,
                      color: "#1888F2",
                    }}
                    onClick={this.addBerthNo}
                  >
                    + 新增更多泊位号
                  </span>
                  {/* <Flex>
                    <List renderHeader={() => "泊位号01"}>
                      <Flex.Item>
                        <Input
                          placeholder=" "
                          name="berthOne"
                          onChange={this.onChange}
                          defaultValue={this.state.berthOne}
                        />
                      </Flex.Item>
                    </List>
                    <List renderHeader={() => "泊位号02"}>
                      <Flex.Item>
                        <Input
                          placeholder=" "
                          name="berthTwo"
                          onChange={this.onChange}
                          defaultValue={this.state.berthTwo}
                        />
                      </Flex.Item>
                    </List>
                  </Flex> */}
                  <List renderHeader={() => "位置"}>
                    <Input
                      placeholder={this.state.driveAdress}
                      name="driveAdress"
                      onChange={this.onChange}
                      defaultValue={this.state.driveAdress}
                      onBlur={this.onblur}
                    />
                    <img
                      src={require("../../components/images/position.png")}
                      onClick={this.getLocation}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "5px",
                      }}
                      alt="问号"
                    />
                  </List>
                  <Flex>
                    <List renderHeader={() => "经度"}>
                      <Flex.Item>
                        <Input
                          placeholder={this.state.longitude}
                          readOnly
                          name="longitude"
                          onChange={this.onChange}
                          defaultValue={this.state.longitude}
                        />
                        {/*<Input placeholder="西湖停车场"  readOnly name="longitude" onChange={this.onChange} defaultValue={this.state.longitude} />*/}
                      </Flex.Item>
                    </List>
                    <List renderHeader={() => "纬度"}>
                      <Flex.Item>
                        <Input
                          placeholder={this.state.dimension}
                          name="dimension"
                          readOnly
                          onChange={this.onChange}
                          defaultValue={this.state.dimension}
                        />
                        {/*<Input placeholder="西湖停车场" name="dimension" readOnly onChange={this.onChange} defaultValue={this.state.dimension}/>*/}
                      </Flex.Item>
                    </List>
                  </Flex>
                </WingBlank>
              </WingBlank>
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>

            <button
              type="submit"
              style={{
                borderRadius: "25px",
                height: "47px",
                width: "100%",
                backgroundColor: "#ffffff",
                fontSize: "18px",
                border: "none",
              }}
            >
              提交
            </button>
          </form>
        </WingBlank>

        <Link to="./login">
          <div style={isOriginHei}>
            <img
              src={require("../../components/images/link1.png")}
              style={link}
              alt="底图"
            />
            <span
              style={{
                color: "#ffffff",
                position: "fixed",
                bottom: "10px",
                left: "40%",
                fontSize: "15px",
              }}
            >
              &nbsp;退出登录
            </span>
          </div>
        </Link>
      </div>
    );
  }
}
