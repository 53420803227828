/*我的停车路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace } from "antd-mobile";
const sectionStyle = {
  width: "100%",
  paddingBottom: "69px",
  minHeight: "100vh",
  backgroundColor: "#5150F2",
  // backgroundImage: `url(${require("../../components/images/background.png")})`
};
const topStyle = {
  height: "60px",
  lineHeight: "60px",
  width: "100%",
  textAlign: "center",
  backgroundColor: "#5150F2",
  color: "rgb(255, 255, 255)",
  position: "fixed",
  zIndex: 999,
};
const center = {
  backgroundColor: "#ffffff",
  borderRadius: "2px",
  height: "78px",
  color: "#0A0A0A",
};
const flexMain = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "13px",
};

const fontStyle = {
  fontSize: "23px",
  fontWeight: "bold",
};
const siteColor = {
  color: "#A7895F",
};
export default class MonthlyCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
      this.props.history.push('/'); 
      return false;
    }
    this.handleClick();
  }
  handleClick() {
    const params = {
      cardFlag: 0,
    };
    const url = "c/h5Manage/getParkingLots";
    const param = {
      requestUrl: url,
      param: JSON.stringify(params),
      baseCpId: null,
    };
    fetch("a/request/all", {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param),
    })
      .then((res) => res.json())
      .then((data) => {
        let info = data.rst;
        this.setState({
          data: info,
        });
      });
  }
  render() {
    const { data } = this.state;
    return (
      <div style={sectionStyle}>
        <div>
          <div style={topStyle}>
            {data.length == 0 ? "暂无可包月停车场" : "附近可包月停车场"}
          </div>
          <WingBlank style={{ paddingTop: "60px" }}>
            {data && data.length > 0
              ? data.map((item, index) => (
                  <div key={index}>
                    <div
                      style={center}
                      onClick={() => {
                        sessionStorage.setItem("prefixUrl", item.prefixUrl);
                        sessionStorage.setItem("carNum", '');
                        sessionStorage.setItem(
                          "parkingLotCode",
                          item.parkingLotCode
                        );
                        sessionStorage.setItem("cardTime", "");
                        this.props.history.push({
                          pathname: "./monthlyDetail",
                        });
                      }}
                    >
                      <WhiteSpace></WhiteSpace>
                      <WhiteSpace></WhiteSpace>
                      <WingBlank>
                        <div style={fontStyle}>{item.parkingLotName}</div>
                        <WhiteSpace></WhiteSpace>
                        <div style={flexMain}>
                          <div style={siteColor}>{item.adress}</div>
                        </div>
                      </WingBlank>
                    </div>
                    <WhiteSpace></WhiteSpace>
                  </div>
                ))
              : ""}
          </WingBlank>
        </div>
      </div>
    );
  }
}
