import { Toast } from "antd-mobile";

function _fetch(params) {
    const { url, payload, baseCpId, } = params;
    Toast.loading('Loading...')
    return new Promise(function(resolve, reject) {
        const param = {
            requestUrl: url,
            param: JSON.stringify(payload),
            baseCpId: baseCpId ? baseCpId : null,
        };
        fetch("/a/request/all", {
                method: "POST",
                headers: { "Content-type": "application/json", },
                body: JSON.stringify(param), //把提交的内容转字符串
            })
            .then((res) => {
                if(res.status==200){ return res.json() }
                else{ return { code: 1001, desc: `${res.status} ${res.statusText}`, rst: null, }}
            })
            .then((result) => {
                Toast.hide()
                if(result.code==1000){ resolve(result); }
                else{ Toast.offline( result.desc,1.5) }
            });
    });
}

export async function getElasCardDetailList2(payload) {
    const url = "c/userManage/getElasCardDetailList2";
    const result = await _fetch({ url, payload });
    return result;
}
export async function getNoInvoiceList(payload) {
    const url = "c/h5Manage/getNoInvoiceList";
    const result = await _fetch({ url, payload });
    return result;
}
export async function getParkingLots(payload) {
    const url = "c/h5Manage/getParkingLots";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getMsgByCar(payload) {
    const url = "c/h5Manage/getMsgByCar";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getArrearsRecord(payload) {
    const url = "c/h5Manage/getArrearsRecord";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getRunOrderByCarNum(payload) {
    const url = "c/h5Manage/getRunOrderByCarNum";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getUserCarList(payload) {
    const url = "c/h5Manage/getUserCarList";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getUserCarList2(payload) {
    const url = "c/h5Manage/getUserCarList2";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getUseCarNumById(payload) {
    const url = "c/h5Manage/getUseCarNumById";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getUserProve(payload) {
    const url = "c/h5Manage/getUserProve";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getAuthCode(payload) {
    const url = "c/h5Manage/getAuthCode";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function addUserProve(payload) {
    const url = "c/h5Manage/addUserProve";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function updateUserPhone(payload) {
    const url = "c/h5Manage/updateUserPhone";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getPayParm(payload) {
    const url = "c/h5Manage/getPayParm";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getAllBaseCp(payload) {
    const url = "c/h5Manage/getAllBaseCp";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getUrlByLaneId(payload) {
    const url = "c/h5Manage/getUrlByLaneId";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function getUrlBerthsByCode(payload) {
    const url = "c/h5Manage/getUrlBerthsByCode";
    const result = await _fetch({ url, payload, });
    return result;
}
export async function updateOrderOperation(payload) {
    const url = "c/h5Manage/updateOrderOperation";
    const result = await _fetch({ url, payload, });
    return result;
}





export async function getMonthlyAgreement({ payload, baseCpId }) {
    const url = "c/h5Manage/getMonthlyAgreement";
    const result = await _fetch({ url, payload, baseCpId, });
    return result;
}
export async function getParkingLotRules({ payload, baseCpId }) {
    const url = "c/h5Manage/getParkingLotRules";
    const result = await _fetch({ url, payload, baseCpId, });
    return result;
}
export async function getCarNumById({ payload, baseCpId }) {
    const url = "c/h5Manage/getCarNumById";
    const result = await _fetch({ url, payload, baseCpId, });
    return result;
}