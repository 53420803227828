/*手机号绑定状态路由组件*/
import  React,{Component} from 'react'
import  {WingBlank, WhiteSpace,} from 'antd-mobile'
const sectionStyle = {
    width: "100%",
    height: "100vh",
    backgroundSize: 'cover',
    backgroundImage: `url(${require("../../components/images/background.png")})`
};
const car={
    marginTop:"36px"
}
const center={
    height: '215px',
    backgroundColor:'#ffffff',
    borderRadius:'8px',
    fontSize:'17px',
    marginTop: '-14px'
}
export default  class phoneBindStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNum:'',
        };
    }
    componentDidMount() {
        const phoneNum=window.sessionStorage.getItem("phone");
        this.setState({
            phoneNum:phoneNum
        })
    }
    render() {
        return(
            <div style={sectionStyle}>
                 <div style={{textAlign:'center'}}>
                    <img src={require('../../components/images/car.png')} style={car} alt="标签"/>
                 </div>
                    <WingBlank>
                        <div style={center}>
                            <WingBlank>
                                <div style={{textAlign:'center'}}>
                                        <img src={require('../../components/images/success.png')} style={{marginTop:"45px",height:'71px',width:'71px'}} alt="标签"/>
                                    <WhiteSpace></WhiteSpace>
                                        <div><span>{this.state.phoneNum}</span>&nbsp;绑定成功!</div>
                                    <WhiteSpace></WhiteSpace>
                                    <WhiteSpace></WhiteSpace>
                                    <WhiteSpace></WhiteSpace>
                                    <span style={{color:'#FD3434',fontSize:'14px'}}>请立即入场，避免拥堵！</span>
                                </div>
                            </WingBlank>
                        </div>
                        <WhiteSpace></WhiteSpace>
                        <WhiteSpace></WhiteSpace>
                    </WingBlank>
            </div>
        )
    }
}
