/*登录页面路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace, Button } from "antd-mobile";
import { Input } from "antd";
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
  zIndex: "-2",
  position: "absolute",
};
const login = {
  marginTop: "36px",
  position: "relative",
  zIndex: "-1",
};
const logo = {
  fontSize: "20px",
  color: "#2D96FE",
};
const center = {
  height: "264px",
  backgroundColor: "#ffffff",
  borderRadius: "5px",
  marginTop: "-86px",
  textAlign: "center",
};

const inputStyle = {
  backgroundColor: "#eeeeee",
  fontSize: "15px",
  border: "none",
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseAdminName: "",
      baseAdminPassword: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.loadAccountInfo();
  }

  //判断cookie中是否有账号信息，有就可以进行预填写，没有则直接返回
  loadAccountInfo = () => {
    //读取cookie长度，看是否存在（测试）
    //           let accountInfo123 = document.cookie
    // var cookie_pos = accountInfo123.indexOf('accountInfo');

    //读取cookie
    
    let arr,
    // eslint-disable-next-line
      reg = new RegExp("(^| )" + "accountInfo" + "=([^;]*)(;|$)");
    let accountInfo = "";
    if ((arr = document.cookie.match(reg))) {
      accountInfo = unescape(arr[2]);
    } else {
      accountInfo = null;
    }

    if (Boolean(accountInfo) == false) {
      return false;
    } else {
      const i = accountInfo.split("&");
      const baseAdminName = i[0];
      const baseAdminPassword = i[1];
      this.setState({
        baseAdminName: baseAdminName,
        baseAdminPassword: baseAdminPassword,
      });
    }
  };

  onChange(e) {
    //把当前修改的值赋入state
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    // 阻止事件传递
    e.preventDefault();
    const { baseAdminName, baseAdminPassword } = this.state;
    // 把表单用的最终数据从state中提取出来,传入请求
    const post = { baseAdminName, baseAdminPassword, };
    if ( baseAdminName && baseAdminPassword ) {
      const url = "c/h5Manage/h5Loading";
      const param = {
        requestUrl: url,
        param: JSON.stringify(post),
        baseCpId: null,
      };
      fetch("a/request/all", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(param), //把提交的内容转字符串
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code == 1000) {
            this.props.history.push({ pathname: "./register" });
            sessionStorage.setItem("token", data.rst.token);

            let accountInfo =
              this.state.baseAdminName + "&" + this.state.baseAdminPassword;
            let Days = 7; //cookie保存时间
            let exp = new Date();
            exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
            document.cookie =
              "accountInfo" +
              "=" +
              escape(accountInfo) +
              ";expires=" +
              exp.toGMTString();
          } else {
            alert("登录失败!请检查用户名及密码");
          }
        });
    } else {
      alert("请输入相应信息");
    }
  }

  render() {
    return (
      <div style={sectionStyle}>
        <div>
          {/*<NavBar*/}
          {/*    mode="light"*/}
          {/*    icon={<Icon type="cross" style={{ color:'#000000'}} />}*/}
          {/*    onLeftClick={() => this.props.history.push({pathname:'./main'})}*/}
          {/*><b>登录</b></NavBar>*/}
          <WingBlank>
            <div style={{ textAlign: "center" }}>
              {" "}
              <img
                src={require("../../components/images/login.png")}
                style={login}
                alt="标签"
              />
            </div>
          </WingBlank>
          <WingBlank>
            <div style={center}>
              <div>
                <img
                  src={require("../../components/images/cloud.png")}
                  style={{ marginTop: "25px" }}
                  alt="标签"
                />
              </div>
              <div style={logo}>ELASCLOUD</div>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <WingBlank>
                <WingBlank>
                  <Input
                    placeholder={
                      this.state.baseAdminName
                        ? this.state.baseAdminName
                        : "请输入用户名"
                    }
                    style={inputStyle}
                    name="baseAdminName"
                    onChange={this.onChange}
                    defaultValue={this.state.baseAdminName}
                  />
                  <WhiteSpace></WhiteSpace>
                  <WhiteSpace></WhiteSpace>
                  <Input
                    placeholder={
                      this.state.baseAdminPassword ? "******" : "请输入密码"
                    }
                    style={inputStyle}
                    type="password"
                    name="baseAdminPassword"
                    onChange={this.onChange}
                    defaultValue={this.state.baseAdminPassword}
                  />
                </WingBlank>
              </WingBlank>
            </div>
          </WingBlank>
        </div>
        <WingBlank>
          <Button
            type="default"
            style={{
              borderRadius: "25px",
              marginTop: "30px",
              fontSize: "17px",
            }}
            onClick={this.onSubmit}
          >
            登录
          </Button>
        </WingBlank>
      </div>
    );
  }
}
