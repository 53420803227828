import React, { useState, useEffect } from 'react'
import { Button, WingBlank, WhiteSpace, Modal } from 'antd-mobile'
import { getArrearsRecord, getParkingLotRules, getParkingLots, } from '../../fetch'
import { useHistory } from 'react-router-dom';
const Modal_alert = Modal.alert;
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const Btn_style = {
  position: "absolute",
  width: "100%",
  bottom: "0",
  padding: "9px 0",
}
const center = {
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxSizing: "border-box",
  padding: "15px 10px",
};
const detail = {
  height: "195px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxSizing: "border-box",
  padding: "15px 10px",
};
const Ptitle_style = {
  fontSize: "18px", fontWeight: "lighter", textAlign: "center",
}
const Ptitle_style2 = {
  color: "#2D96FE",
  fontSize: "12px",
  textAlign: "center",
}
// 1 便利数据信息
const LoopContent = ({ title, text }) =>
  <div style={{ color: '#55617C', fontWeight: '400' }}>
    {title}&nbsp;&nbsp;&nbsp;&nbsp;
    <span style={{ color: '#000000' }}>{text}</span>
  </div>

export default function Hooks1() {
  const [arrearage, setArrearage] = useState({})
  const [visible, setVisible] = useState(false)
  const [listLast, setListLast] = useState([])
  const [listTimes, setListTimes] = useState([])
  const [code, setCode] = useState({})
  const _useHistory = useHistory()

  // 1 初始触发
  useEffect(() => {
    const orderCode = window.sessionStorage.getItem("orderCode");
    const carNum = { orderCode, orderStatus: "1", };
    getArrearsRecord(carNum).then(res => setArrearage({ ...res.rst.list[0] }))
  }, [])

  // 2 打开弹窗 
  useEffect(() => {
    if (!visible) return
    const { elasParkingLotId, baseCpId, parkingLotCode,colorType } = arrearage;
    const payload = { entityId: elasParkingLotId,colorType }
    // 1 查询停车场规则 // 获取停车场规则 收费规则 0按时收费 1按次收费
    getParkingLotRules({ payload, baseCpId }).then(data => {
      if (data.rst !== null && data.rst.length) {
        const listLast = data.rst.filter((item) => 0 == item.chargeRule);
        const listTimes = data.rst.filter((item) => 1 == item.chargeRule);
        setListLast([...listLast])
        setListTimes([...listTimes])
      }
    })
    // 2查询所有的停车场
    getParkingLots({ entityCode: parkingLotCode }).then(data => {
      if (data.rst.length) setCode({ ...data.rst[0] })
    })
  }, [visible])

  // 3 支付
  function fn_pay() {
    const { orderCode, orderFee, prefixUrl, } = arrearage;
    if (!orderCode) {
      Modal_alert('订单号失效', "请刷新页面后重试",)
      return
    }
    // 存在订单号的话 跳转到支付页面
    sessionStorage.setItem("entityCodes", orderCode);
    sessionStorage.setItem("money", orderFee);
    sessionStorage.setItem("prefixUrl", prefixUrl);
    _useHistory.push('/way')
  }

  return (<div style={sectionStyle}>
    {/* 订单详情 */}
    <div style={{ boxSizing: "border-box", padding: '20px', }}>
      <div style={center}>
        <p style={Ptitle_style}>订单详情</p>
        <WhiteSpace size="lg" />
        <p style={{ color: "#CBC9C9" }}>订单编号&nbsp;&nbsp;&nbsp;&nbsp;{arrearage.orderCode}</p>
        <WhiteSpace />
        <div style={{ borderBottom: "1px solid #F2F2F2" }} />
        <WhiteSpace />
        {LoopContent({ title: '车牌号码', text: arrearage.carNumber })}
        <WhiteSpace />
        {LoopContent({ title: '停车地点', text: arrearage.parkingLotAdress })}
        <WhiteSpace />
        {LoopContent({ title: <span>泊&nbsp; 位&nbsp; 号</span>, text: arrearage.berthNum })}
      </div>
    </div>

    {/* 计费详情 */}
    <div style={{ boxSizing: "border-box", padding: '0 20px 20px', }}>
      <div style={detail}>
        <p style={Ptitle_style}>计费详情</p>
        <WhiteSpace />
        <div style={{ borderBottom: "1px solid #F2F2F2" }} />
        <WhiteSpace />
        {LoopContent({ title: '驶入时间', text: arrearage.startTime })}
        <WhiteSpace />
        {LoopContent({ title: '驶离时间', text: arrearage.endTime })}
        <WhiteSpace />
        {LoopContent({
          title: <span>时 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 长</span>,
          text: <>
            <span style={{ color: "red" }}>{arrearage.hour} </span>小时
            <span style={{ color: "red" }}> {arrearage.minute} </span>分钟
            <span style={{ color: "red" }}> {arrearage.second} </span>秒
          </>
        })}
        <WhiteSpace />
        {LoopContent({ title: '停车费用', text: <span style={{ color: "red" }}>{arrearage.orderFee} 元</span> })}
        <WhiteSpace size="lg" />
        {/* 打开弹窗按钮 */}
        <p style={Ptitle_style2} onClick={() => visible ? setVisible(false) : setVisible(true)}>
          停车收费标准 <img src={require("../../components/images/what.png")} alt="问号" style={{ width: "10px" }} />
        </p>
      </div>
    </div>


    {/* 支付按钮 */}
    <span style={Btn_style} >
      <WingBlank>
        <Button
          type="primary"
          style={{ borderRadius: "25px", fontSize: "17px" }}
          onClick={fn_pay}
        >
          <span>支付{arrearage.orderFee}元</span>
        </Button>
      </WingBlank>
    </span>


    <Modal
      visible={visible}
      transparent
      maskClosable={false}
      onClose={() => setVisible(false)}
      footer={[{ text: "知道了", onPress: () => setVisible(false), },]}
    >
      <div style={{ height: 460, backgroundImage: `url(${require("../../components/images/fee.png")})`, }}>
        <h3 style={{ color: "#000000" }}> 停车收费标准（{code.parkingLotName}） </h3>
        <table style={{ borderBottom: "none" }}>
          <tr>
            <td style={{ borderLeft: "none", textAlign: "center" }}>
              免费停车时长
            </td>
            <td></td>
            <td>{listLast && listLast.length > 0 ? listLast[0].freeParkingMinute : 0}分钟</td>
          </tr>
          <tr>
            <td style={{ borderLeft: "none", textAlign: "center" }}>
              付费后免费停车时长
            </td>
            <td></td>
            <td>{listLast && listLast.length > 0 ? listLast[0].freePayMinute : 0}分钟</td>
          </tr>
          <tr>
            <td
              style={{
                borderLeft: "none",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              每日最大停车费
            </td>
            <td ></td>
            <td >{listLast && listLast.length > 0 ? listLast[0].maxCharge : 0}元</td>
          </tr>
          {listLast.map((item, index) => (
            <tbody key={index}>
              <tr>
                <td style={{ borderLeft: "none", textAlign: "center" }}>收费时段{index + 1}</td>
                <td>
                  {item.startTime && item.startTime.replace(".", ":")}-
                  {item.endTime && item.endTime.replace(".", ":")}
                </td>
                <td>
                  {item.maxFrame == '0.00' ? '免费' : <>最大停车费<br />{item.maxFrame}元</>}
                  {/* 最大停车费
                          <br />
                          {item.maxFrame}元 */}
                </td>
              </tr>
              {item.maxFrame !== '0.00' && item.ruleList.map((x, index) => (
                <tr>
                  <td style={{ borderLeft: "none", textAlign: "center" }} />
                  <td>{index == 0 ? '初始时段' : `第${index + 1}时段`}<br />{x.initialChargeNum}小时</td>
                  <td>
                    {`${x.initialUtilCharge}元/${x.initialUtilChargeNum}小时`}
                  </td>
                </tr>
              ))}
              {/* <tr>
                        <td style={{ borderLeft: "none", textAlign: "center" }}></td>
                        <td>
                          初始时段时长
                          <br />
                          {item.initialChargeNum}小时
                        </td>
                        <td>
                          单位收费时长
                          <br />
                          {item.initialUtilChargeNum}小时
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderLeft: "none", textAlign: "center" }}></td>
                        <td></td>
                        <td>
                          单位收费时长
                          <br />
                          {item.initialUtilCharge}元
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderLeft: "none", textAlign: "center" }}></td>
                        <td>其余时段</td>
                        <td>
                          单位收费时长
                          <br />
                          {item.otherUtilChargeNum}小时
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderLeft: "none", textAlign: "center" }}></td>
                        <td></td>
                        <td>
                          单位停车费
                          <br />
                          {item.otherUtilCharge}元
                        </td>
                      </tr> */}
            </tbody>
          ))
          }
          <tbody>
            {listTimes.map((item, index) => (
              <tr key={index}>
                <td style={{ borderLeft: "none", textAlign: "center" }}>收费时段{index + 1 + listLast.length}</td>
                <td>
                  {item.startTime && item.startTime.replace(".", ":")}-
                  {item.endTime && item.endTime.replace(".", ":")}
                </td>
                <td>
                  单次停车费
                  <br />
                  {item.onceCharge}元/次
                </td>
              </tr>
            ))
            }

          </tbody>
        </table>
      </div>
    </Modal>
  </div>)
}
