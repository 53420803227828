/*进行中订单详情路由组件*/
// 这个页面最大的问题是把关键字new设置成了变量  ，但是也不敢改，怕影响其他页面的取值...
import React, { Component } from "react";
import "../../components/boom.css";
import { WingBlank, Flex, WhiteSpace, Button, Modal, Toast, } from "antd-mobile";
import { Radio, Checkbox, } from "antd";
import "antd/lib/radio/style/index.css";
import "./index.css"
import md5 from "md5";
import { getParkingLots, getParkingLotRules, getRunOrderByCarNum, } from '../../fetch'
const sectionStyle = {
  width: "100%",
  minHeight: "100vh",
  height: '100%',
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  // height: '380px',
  backgroundColor: "#ffffff",
  borderRadius: "7px",
  textAlign: "center",
};
const radioStyle = {
  display: "flex",
  height: "49px",
  lineHeight: "49px",
  backgroundColor: "#ffffff",
  borderRadius: "7px",
};
const img = {
  padding: '7px 3px',
  position: "absolute",
};

export default class More extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1: false,
      listLast: [],
      listTimes: [],
      new: null,
      stop: "",
      phoneLeave: 1, //1为有牌车，0为无牌车
      date: new Date(),
      value: 1, //1为支付宝支付，2为微信支付
      browser: 3, //1为微信app的浏览器，2为支付宝app的浏览器，3为其它浏览器
      ItemArr: [],//优惠券数组
      newFee: undefined,
    };
  }

  componentDidMount() {
    // 判断微信浏览器是否储存了userid
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    const _data = JSON.parse(window.sessionStorage.getItem("_data"));
    if ((browser.indexOf("micromessenger") > 0) && (id == null) && !_data) {//微信
      this.props.history.push('/');
      return false;
    }
    // 项目初始化
    this.start()
  }

  // 1 页面初始化
  start = () => {
    this.timerID = setInterval(() => this.tick(), 1000);
    this.timerFee = setInterval(() => this.handlesclick(), 1000 * 1 * 60);
    //判断哪个浏览器
    this.IsWechatPayOrAliPay();
    const hasCarNumber = window.sessionStorage.getItem("hasCarNumber");
    this.setState({ phoneLeave: hasCarNumber, }, () => {
      // 判断是否有传递过来的数据，没有的话通过接口请求 ===> 前置页面不一样。
      const _data = JSON.parse(window.sessionStorage.getItem("_data"));
      if (_data && _data !== null) {
        this.setState({ new: _data, prefixUrl: _data.prefixUrl, })
      } else {
        this.handlesclick()
        // this.handlesclick1().then(data => {

        //   if (data.rst == null || !data.rst) {
        //     Toast.info('暂无进行中的订单信息！')
        //     return false
        //   } else {

        //     this.setState({ prefixUrl: data.rst.prefixUrl, });
        //   }
        //   this.getParkingLots()
        // })
      }
    });
  }
  // 2 判断哪个浏览器
  IsWechatPayOrAliPay() {
    var browser = window.navigator.userAgent.toLowerCase();
    if (browser.indexOf("micromessenger") > 0) { this.setState({ browser: 1, value: 2, }); }
    else if (browser.indexOf("alipayclient") > 0) { this.setState({ browser: 2, }); }
    else { this.setState({ browser: 3, }); }
  }
  // 3 设置当前时间  ---- 不知道为什么要多此一举 不敢删
  tick = () => this.setState({ date: new Date(), })
  // 4 根据车牌号,泊位号查订单  ---- 不敢删
  handlesclick = () => {
    const carNum = { carNum: window.sessionStorage.getItem("carNum"), };
    getRunOrderByCarNum(carNum).then(data => {
      if (data.code == 1000) { 
        if (data.rst == null || !data.rst) {
          Toast.info('暂无进行中的订单信息！')
          return false
        }

        let elasDiscountIdList = [], orderFee = data.rst.orderFee;
        const Time =
          data.rst.startTime &&
          data.rst.startTime.substring(0, 19).replace(/-/g, "/");
        const resultTime = new Date().getTime() - new Date(Time).getTime();
        let hour = Math.floor(resultTime / (1000 * 60 * 60));

        for (const item of data.rst.noDiscountUse) {
          if (item.discountType == 2) {
            elasDiscountIdList.push(item.elasDiscountUseId);
            hour -= Number(item.discountFee);
            item.active = true;
            if(hour<=0) break;
          } else if (item.discountType == 1) {
            elasDiscountIdList.push(item.elasDiscountUseId);
            orderFee -= Number(item.discountFee);
            item.active = true;
            if(orderFee<=0) break;
          }
        }
        this.setState({ new: data.rst,prefixUrl:data.rst.prefixUrl,ItemArr:elasDiscountIdList }, e=>{
          this.getParkingLots();
          if(elasDiscountIdList.length) {
            const payload = { elasDiscountIdList, orderCode:data.rst.orderCode }
            this.getFeeByOrderCodeAndDis(payload)
          }
        }) 
      }
      else { alert(data.desc); }
    })
  }
  // 5 根据车牌号,泊位号查订单，获取页面信息
  handlesclick1 = () => {
    return new Promise(resove => {
      const carNum = { carNum: window.sessionStorage.getItem("carNum"), };
      getRunOrderByCarNum(carNum).then(data => {
        this.setState({ new: data.rst }, () => resove(data))
      })
    })
  }

  // 6 展示收费规则
  showModal = (key) => (e) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    if (!this.state.new) return
    this.setState({ [key]: true, }, () => this.getParkingLotRules());
    this.getParkingLots()
  }
  getParkingLots = () => {
    const entityCode = { entityCode: this.state.new.parkingLotCode, };
    getParkingLots(entityCode).then(res => {
      window.sessionStorage.setItem('freeParkingMinute', res.rst[0].freeParkingMinute)
      this.setState({ stop: res.rst, })
    })
  }
  // 7 关闭收费规则弹窗
  onClose = (key) => () => this.setState({ [key]: false, })
  // 8 选择支付方式
  onChange = e => this.setState({ value: e.target.value, })
  // 9确认出场
  leaveOperation = () => {
    const { prefixUrl, } = this.state;
    const leave = {
      orderCode: this.state.new.orderCode,
      elasLaneId: window.sessionStorage.getItem('elasLaneId'),
    };
    const url = `${prefixUrl}c/h5Manage/updateOrderOperation`;
    fetch(url, {
      method: "POST",
      headers: { "Content-type": "application/json", },
      body: JSON.stringify(leave), //把提交的内容转字符串
    }).then((res) => res.json())
      .then((data) => {
        if (data.code == 1000) {
          alert("操作成功");
          this.props.history.push({ pathname: "./phoneLeave" });
        } else {
          alert(data.desc);
        }
      });
  }
  // 10支付
  pay = () => {
    const { value, browser, prefixUrl, ItemArr, newFee, } = this.state;
    // 如果使用消费券 而且消费券可抵扣车费 直接弹出
    if (newFee && newFee == 0) {
      alert(`当前优惠券可抵扣停车费用，无需缴费可直接驶出！`)
      return false;
    }
    let _fee = newFee ? newFee : this.state.new.orderFee;
    const entityCodes = this.state.new.orderCode;
    const orderMoney = _fee; // 支付金额
    const _elasDiscountUseIdarr = ItemArr.length ? ItemArr.toString() : 'null';// 优惠券列表
    window.sessionStorage.setItem("orderCode", this.state.new.orderCode);
    window.sessionStorage.setItem("fee", _fee);
    window.sessionStorage.setItem("money", _fee);
    window.sessionStorage.setItem("payType", 1);
    window.sessionStorage.setItem('prefixUrl', prefixUrl)

    const landCodeId = window.sessionStorage.getItem("landCode");
    const id = entityCodes;
    const userId = window.sessionStorage.getItem("userId");
    const myUrl = window.sessionStorage.getItem("url");
    Toast.hide();
    if (prefixUrl == null || !prefixUrl) {
      alert("支付失败，请联系管理员配置支付前缀！")
      return false;
    }
    //支付宝支付
    if (value == 1) {
      let zfb_url = `${prefixUrl}c/alipay/ailiH5Pay?entityCodes=${id}&type=${landCodeId}&fee=${orderMoney}&elasDiscountUseId=${_elasDiscountUseIdarr}`;// 支付宝支付地址
      window.location.href = zfb_url;
    }
    //微信支付 在微信浏览器中
    else if (value == 2) {
      if (browser == 1) {
        // 微信内置浏览器支付地址
        let wx_in_url = `${prefixUrl}c/wxPay/weixinPayH5Copy?entityCodes=${id}&userId=${userId}&type=${landCodeId}&fee=${orderMoney}&elasDiscountUseId=${_elasDiscountUseIdarr}`;
        Toast.loading('Loading...')
        fetch(wx_in_url, { method: "GET", }).then((res) => res.json())
          .then(data => {
            Toast.hide();
            const mes = data.rst;
            const time = parseInt(new Date().getTime() / 1000);
            const stringA = "appId=" + mes.appid +
              "&nonceStr=" + mes.nonce_str + "&package=" +
              "prepay_id=" + mes.prepay_id +
              "&signType=MD5&timeStamp=" + time;
            const stringSignTemp = stringA + "&key=" + mes.key; //注：key为商户平台设置的密钥key
            const sign = md5(stringSignTemp).toUpperCase(); //注：MD5签名方式
            Toast.loading('Loading...')
            window.WeixinJSBridge.invoke("getBrandWCPayRequest",
              {
                appId: mes.appid, //公众号名称，由商户传入
                timeStamp: time.toString(), //时间戳，自1970年以来的秒数
                nonceStr: mes.nonce_str, //随机串
                package: "prepay_id=" + mes.prepay_id,
                signType: "MD5", //微信签名方式：
                paySign: sign, //微信签名
              }, function (res) {
                Toast.hide();
                if (res.errMsg == 'chooseWXPay:fail, the permission value is offline verifying') {
                  Toast.offline(`支付失败：${res.errMsg}`)
                  return false
                }
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  //支付成功后跳转的页面
                  window.sessionStorage.setItem("orderStatus", "1");
                  window.location.href = myUrl + "/payStatus";
                } else if (res.err_msg == "get_brand_wcpay_request:cancel") { alert("您已取消支付") }
                else if (res.err_msg == "get_brand_wcpay_request:fail") { alert("支付失败，请稍后再试"); }
              });
          });
      } else {
        //微信支付 在其他浏览器中
        const toUrl = `${prefixUrl}c/wxPay/weixinPayH5?entityCodes=${id}&type=${landCodeId}&fee=${orderMoney}&elasDiscountUseId=${_elasDiscountUseIdarr}`;
        Toast.loading('Loading...')
        fetch(toUrl, { method: "GET", }).then((res) => res.text())
          .then((data) => {
            Toast.hide();
            window.location.href = data;
          });
      }
    }
  }
  // 11 选择优惠券数组
  fn_onChecked = ItemArr => {
    console.log(ItemArr);
    const { orderCode } = this.state.new;
    if (ItemArr.length) {
      let elasDiscountIdList = [];
      let _ItemArr = [];
      ItemArr.map(item => {
        _ItemArr.push(item)
        elasDiscountIdList.push(item)
      })
      this.setState({ ItemArr: _ItemArr, })
      const payload = { elasDiscountIdList, orderCode, }
      this.getFeeByOrderCodeAndDis(payload)
    } else {
      this.setState({ newFee: undefined, ItemArr: [], })
    }
  }
  getFeeByOrderCodeAndDis = payload => {
    const { prefixUrl, } = this.state.new;
    fetch(`${prefixUrl}c/discount/getFeeByOrderCodeAndDis`, {
      method: "POST",
      headers: { "Content-type": "application/json", },
      body: JSON.stringify(payload), //把提交的内容转字符串
    }).then((res) => {
      if (res.status == 200) { return res.json() }
      else { return { code: 1001, desc: `${res.status} ${res.statusText}`, rst: null, } }
    }).then((result) => {
      Toast.hide()
      if (result.code == 1000) {
        this.setState({
          newFee: result.rst.price,
        })
      } else { Toast.offline(result.desc, 1.5) }
    });
  }
  // 页面关闭清空上个页面储存的数据
  componentWillUnmount() {
    window.sessionStorage.removeItem('_data')
    clearInterval(this.timerID);
    clearInterval(this.timerFee);
  }
  // 12 获取详细信息
  getParkingLotRules = () => {
    // console.log(this.state.new);
    const carNum = { entityId: this.state.new.elasParkingLotId, colorType: this.state.new.colorType };
    // const { state, } = this.props.location;
    // 查询停车场规则
    const { prefixUrl, } = this.state;
    const _url = `${prefixUrl}c/h5Manage/getParkingLotRules`

    fetch(_url, {
      method: "POST",
      headers: { "Content-type": "application/json", },
      body: JSON.stringify(carNum), //把提交的内容转字符串
    })
      .then((res) => {
        if (res.status == 200) { return res.json() }
        else { return { code: 1001, desc: `${res.status} ${res.statusText}`, rst: null, } }
      })
      .then((data) => {
        Toast.hide()
        if (data.code == 1000) {
          // 收费规则 0按时收费 1按次收费
          const classifyList = data.rst;
          if (!classifyList) return Toast.success("免费！");
          const longMes = classifyList.filter((item) => 0 == item.chargeRule);
          const timeMes = classifyList.filter((item) => 1 == item.chargeRule);
          this.setState({
            listLast: longMes,
            listTimes: timeMes,
          });
        } else { Toast.offline(data.desc, 1.5) }
      });
  }
  render() {
    const { stop, phoneLeave, listLast, listTimes, newFee,ItemArr } = this.state;
    const code = stop[0] || {};
    if (this.state.new == null || !this.state.new) {
      return <WingBlank><p>暂无进行中的订单！</p></WingBlank>
    }
    const pathname = window.sessionStorage.getItem('pathname');
    if (pathname !== null) { window.sessionStorage.removeItem('pathname') }
    const NoCar = window.sessionStorage.getItem("NoCar");
    const endTime = this.state.date.getTime();
    const Time =
      this.state.new.startTime &&
      this.state.new.startTime.substring(0, 19).replace(/-/g, "/");
    const startTime = new Date(Time).getTime();
    const resultTime = endTime - startTime;
    const hour = Math.floor(resultTime / (1000 * 60 * 60));
    const minute =
      Math.floor(resultTime / (1000 * 60)) -
      Math.floor(resultTime / (1000 * 60 * 60)) * 60;
    // const second = Math.floor(resultTime / 1000) - Math.floor(resultTime / (1000 * 60)) * 60;
    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <div style={center}>
              <WingBlank>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                <h1 style={{ margin: "10px 0" }}>{this.state.new.carNumber}</h1>
                <div style={{ color: "#CBC9C9", height: "16px" }}>
                  {this.state.new.parkingLotAdress}
                </div>
                <WhiteSpace></WhiteSpace>
                <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
                <WhiteSpace></WhiteSpace>
                <div>订单开始时间：{this.state.new.startTime}</div>
                <WhiteSpace></WhiteSpace>
                <div style={{ backgroundColor: "#eeeeee", height: "110px" }}>
                  <WingBlank>
                    <WhiteSpace></WhiteSpace>
                    <Flex>
                      <Flex.Item style={{ backgroundColor: "#ffffff", width: "100%", textAlign: "center", }}>
                        {hour && hour < 10 ?
                          <h1>{(Array(2).join(0) + hour).slice(-2)}</h1>
                          :
                          <h1>{hour}</h1>
                        }
                      </Flex.Item>
                      <Flex.Item style={{ backgroundColor: "#ffffff", width: "100%", textAlign: "center", }} >
                        <h1>{(Array(2).join(0) + minute).slice(-2)}</h1>
                      </Flex.Item>
                    </Flex>
                    <Flex>
                      <Flex.Item style={{ width: "100%", textAlign: "center", padding: "8px 0", }}>
                        时
                      </Flex.Item>
                      <Flex.Item style={{ width: "100%", textAlign: "center" }}>
                        分
                      </Flex.Item>
                    </Flex>
                  </WingBlank>
                </div>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                <WingBlank>
                  <WingBlank>
                    <WingBlank>
                      <div style={{ border: "8px solid #445cf4", borderBottom: "none", }} >
                        <WhiteSpace></WhiteSpace>
                        <div style={{ fontSize: "17px", textAlign: "center" }}>
                          {this.state.new.carType == 0 ? null : <div>月租车</div>}
                        </div>
                        <WhiteSpace></WhiteSpace>
                        <div
                          style={{
                            color: "#2D96FE",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                          onClick={this.showModal("modal1")}
                        >
                          停车收费标准{" "}
                          <img
                            src={require("../../components/images/what.png")}
                            alt="问号"
                            style={{ width: "10px" }}
                          />
                        </div>
                        <WhiteSpace></WhiteSpace>
                      </div>
                    </WingBlank>
                  </WingBlank>
                </WingBlank>
              </WingBlank>
            </div>
            <WhiteSpace />

            {/* 缴费记录 */}
            <WhiteSpace />
            {this.state.new.payPrice == null || this.state.new.payPrice.length == 0 ? null :
              <div style={{ borderRadius: "7px", padding: "15px", background: "#fff", }}>
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>已缴费</p>
                <WhiteSpace />
                {this.state.new.payPrice.map((item, index) => {
                  let _PayType = ""
                  switch (item.payType) {
                    case 0: _PayType = 'h5-微信'; break;
                    case 1: _PayType = 'h5-支付宝'; break;
                    case 2: _PayType = '现金支付'; break;
                    case 3: _PayType = 'pda支付宝'; break;
                    case 4: _PayType = 'pda微信'; break;
                    case 5: _PayType = '月租车包月'; break;
                    case 6: _PayType = '扫码支付'; break;
                  }
                  return <div key={index}>
                    <p>已支付：
                      <span style={{ fontSize: '18px', color: '#1890FF' }}>
                        {item.orderFee}（ {_PayType}支付）
                      </span>
                    </p>
                    <WhiteSpace />
                    {item.disuse == null || item.disuse.length == 0 ? null :
                      <div>
                        <div style={{ float: "left", }}>优惠券：</div>
                        <div style={{ float: "left", minWidth: "200px", }}>
                          {item.disuse.map((ite, ind) => {
                            return <div key={ind}>
                              <span style={{ float: "left", }}> {ite.discountName} </span>
                              <span style={{ color: "red", float: "right", }}>
                                -{ite.deductFee} {ite.discountType == 1 ? "元" : "小时"}
                              </span>
                              <div style={{ clear: "both" }} />
                              <WhiteSpace />
                            </div>
                          })}
                        </div>
                        <div style={{ clear: "both" }} />
                        <WhiteSpace />
                      </div>
                    }
                    <p>支付时间：{item.payTime}</p>
                    <WhiteSpace />
                  </div>
                })
                }
              </div>
            }
            <WhiteSpace />

            {/* 缴费状态 0 临时车， 1月租车， 2白名单， 1和2 不用缴费 直接出场*/}
            {this.state.new.carType == 0 ?
              <>
                {/* 待缴费订单 */}
                <div style={{ borderRadius: "7px", padding: "15px", background: "#fff", }}>
                  <p style={{ fontSize: "18px", fontWeight: "bold" }}>待缴费</p>
                  <WhiteSpace />
                  <p>待支付：{this.state.new.orderFee} 元</p>
                  <WhiteSpace />
                  {this.state.new.noDiscountUse == null ? null :
                    <div>
                      <div style={{ float: "left", }}>优惠券：</div>
                      <div style={{ float: "left", minWidth: '80%' }}>
                        {this.state.new.noDiscountUse.length ?
                          <Checkbox.Group onChange={this.fn_onChecked} value={ItemArr}>
                            {/* ItemArr */}
                            {this.state.new.noDiscountUse.map((item, index) => (
                              <div key={index}>
                                <Checkbox key={item.elasDiscountUseId} value={item.elasDiscountUseId}>
                                  <div style={{ float: "left", width: "90%", }}>
                                    <span style={{ float: "left", }}>{item.discountName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span style={{ float: "right", color: "red" }}>-{item.deductFee} {item.discountType == 1 ? "元" : "小时"} &nbsp;</span>
                                  </div>
                                </Checkbox>
                                <WhiteSpace />
                              </div>))
                            }
                          </Checkbox.Group>
                          : "暂无可用优惠券！"
                        }
                      </div>
                      <div style={{ clear: "both" }} />
                      <WhiteSpace />
                      <div>
                        小计：<span style={{ fontSize: '18px', color: "red", }}>{newFee ? newFee : this.state.new.orderFee} 元</span>
                      </div>
                    </div>
                  }

                  <WhiteSpace />
                </div>
              </>
              : null}
            <WhiteSpace></WhiteSpace>
            <Radio.Group
              style={{ display: "inline" }}
              onChange={this.onChange}
              value={this.state.value}
            >
              {this.state.browser == 3 ? (
                <Flex>
                  <Flex.Item>
                    <div style={radioStyle}>
                      <img
                        src={require("../../components/images/weixin.png")}
                        style={img}
                      />
                      <div style={{ paddingLeft: "39.5px" }}>
                        <span style={{ wordSpacing: "9.85px", marginRight: "6px" }}>
                          微信支付
                        </span>
                        <Radio value={2}></Radio>
                      </div>
                    </div>
                  </Flex.Item>
                  <Flex.Item>
                    <div style={radioStyle}>
                      <img
                        src={require("../../components/images/umidd17.png")}
                        style={img}
                      />
                      <div style={{ paddingLeft: "39.5px" }}>
                        <span style={{ marginRight: "6px" }}>支付宝支付</span>
                        <Radio value={1}></Radio>
                      </div>
                    </div>
                  </Flex.Item>
                </Flex>
              ) : null}
            </Radio.Group>
          </WingBlank>
        </div>
        <WhiteSpace />
        <div style={{ color: "#CBCACA", width: "100%", textAlign: "center", }}>
          * 仅供参考，实际金额以账单为准
        </div>
        <WhiteSpace />
        <span style={{ width: "100%", padding: "8px 0", }}>
          <WingBlank>
            {/*carType,1为月租车，0为临时车  、2白名单，、phoneLeave，1为有牌车，0为无牌车*/}
            {
              phoneLeave == "0" && this.state.new.orderFee == "0" ?
                <Button
                  type="primary"
                  style={{ borderRadius: "25px", fontSize: "17px" }}
                  onClick={() => this.leaveOperation()}
                >
                  确认出场
                </Button>
                :
                this.state.new.carType == 1 ||
                  this.state.new.carType == 2 ||
                  this.state.new.orderFee == "0.00" ||
                  this.state.new.orderFee == "0" || NoCar == "1" ? (
                  <Button
                    type="primary"
                    style={{ borderRadius: "25px", fontSize: "17px" }}
                    disabled
                  >
                    支付
                  </Button>
                ) : (
                  <div
                    type="primary"
                    style={{
                      borderRadius: "25px",
                      fontSize: "17px",
                      height: "47px",
                      lineHeight: "47px",
                      textAlign: "center",
                      color: "#fff",
                      backgroundColor: "#108ee9",
                    }}
                    onClick={() => this.pay()}
                  >
                    支付{newFee ? newFee : this.state.new.orderFee}元
                  </div>
                )
            }
          </WingBlank>
        </span>
        <WhiteSpace />


        <Modal
          visible={this.state.modal1}
          transparent
          maskClosable={false}
          onClose={this.onClose("modal1")}
          // title="未查询到进行中订单"
          footer={[
            {
              text: "知道了",
              onPress: () => {
                this.onClose("modal1")();
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              height: 460,
              backgroundImage: `url(${require("../../components/images/fee.png")})`,
            }}
          >
            <h3 style={{ color: "#000000" }}>
              停车收费标准（{code.parkingLotName}）
            </h3>
            <table class="ruletable">
              <tr>
                <td style={{ borderLeft: "none", textAlign: "center" }}>
                  免费停车时长
                </td>
                <td></td>
                <td>{(listLast && listLast.length > 0) ? listLast[0].freeParkingMinute : 1}分钟</td>
              </tr>
              <tr>
                <td style={{ borderLeft: "none", textAlign: "center" }}>
                  付费后免费停车时长
                </td>
                <td></td>
                <td>{listLast && listLast.length > 0 ? listLast[0].freePayMinute : 0}分钟</td>
              </tr>
              <tr>
                <td
                  style={{
                    borderLeft: "none",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  每日最大停车费
                </td>
                <td ></td>
                <td >{listLast && listLast.length > 0 ? listLast[0].maxCharge : 0}元</td>
              </tr>
              {listLast && listLast.length > 0
                ? listLast.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td style={{ borderLeft: "none", textAlign: "center" }}>
                        收费时段{index + 1}
                      </td>
                      <td>
                        {item.startTime && item.startTime.replace(".", ":")}-
                        {item.endTime && item.endTime.replace(".", ":")}
                      </td>
                      <td>
                        {item.maxFrame == '0.00' ? '免费' : <>最大停车费<br />{item.maxFrame}元</>}
                      </td>
                    </tr>
                    {item.maxFrame !== '0.00' && item.ruleList.map((x, index) => (
                      <tr>
                        <td style={{ borderLeft: "none", textAlign: "center" }} />
                        <td>{index == 0 ? '初始时段' : `第${index + 1}时段`}<br />{x.initialChargeNum}小时</td>
                        <td>
                          {`${x.initialUtilCharge}元/${x.initialUtilChargeNum}小时`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ))
                : ""}
              <tbody>
                {listTimes && listTimes.length > 0
                  ? listTimes.map((item, index) => (
                    <tr key={index}>
                      <td style={{ borderLeft: "none", textAlign: "center" }}>
                        收费时段{index + listLast.length + 1}
                      </td>
                      <td>
                        {item.startTime && item.startTime.replace(".", ":")}-
                        {item.endTime && item.endTime.replace(".", ":")}
                      </td>
                      <td>
                        单次停车费
                        <br />
                        {item.onceCharge}元/次
                      </td>
                    </tr>
                  ))
                  : ""}

              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}
