/*停车包月协议路由组件*/
import  React,{Component} from 'react'
import {WingBlank, WhiteSpace} from 'antd-mobile'
const sectionStyle = {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${require("../../components/images/background.png")})`
};
const originHeight=document.documentElement.clientHeight;
const clickHeight=originHeight*604/667-48;
const center={
    backgroundColor:'#ffffff',
    borderRadius:'13px',
    height:clickHeight,
    color:'#2B2B2B',
    overflow:'scroll',
    lineHeight:'initial'
};

export default  class MonthlyDeal extends Component {
    render() {
        return(
            <div style={sectionStyle}>
                <div style={{paddingTop:'30px'}}>
                    <WhiteSpace></WhiteSpace>
                    <WhiteSpace></WhiteSpace>
                    <WingBlank>
                        <div style={center}>
                            <WhiteSpace></WhiteSpace>
                            <WhiteSpace></WhiteSpace>
                            <div style={{fontSize:'18px',textAlign:'center',fontWeight:'600'}}>停车场包月停车服务协议</div>
                            <WhiteSpace></WhiteSpace>
                            <WingBlank>
                                <div>
                                    <p><b>【致谢和说明】</b>欢迎使用宁波停车APP！</p>
                                    <WhiteSpace></WhiteSpace>
                                    <p>本协议是就您参与宁波停车服务和市场活动服务等相关事宜所订立的协议，本协议将对宁波停车和宁波停车用户双方构成有约束力的法律文件，请您仔细阅读并明确本协议内容。</p>
                                    <WhiteSpace></WhiteSpace>
                                    <p>以下是适用于所有宁波停车用户的一般性条款，连同宁波停车隐私保护政策以及宁波停车与宁波停车用户协议将构成您与宁波停车之间的协议。</p>
                                    <p>宁波停车可能会更新协议条款。</p>
                                    <WhiteSpace></WhiteSpace>
                                    <p>当您在宁波停车手机APP上按照页面提示点击相应按钮进行注册或参与其活动后，即表示您已充分阅读、理解并接受本协议的全部内容，并与宁波停车达成协议。</p>
                                    <WhiteSpace></WhiteSpace>
                                    <p>如果您对该条款或宁波停车有任何疑问，欢迎您在service@union-park.cn与我们联系。</p>
                                    <WhiteSpace></WhiteSpace>
                                    <p><b>【服务内容】</b></p>
                                     <p>1. 为停车用户提供封闭停车场和道路泊位的车辆停放服务。</p>
                                     <p>2. 为您提供支付宝，微信等多种支付方式，快捷方便，免去您发生现金不足或排队缴费的痛苦。</p>
                                     <p>【使用包月停车用户请特别关注下述事项】</p>
                                    <WhiteSpace></WhiteSpace>
                                    <div style={{color:'#898989'}}>
                                    <p>包月用户指：按月缴纳定额停车费后，可在限定的停车场不限时、不限次进行停车的用户车辆。</p>
                                    <p> 1. 停车场采用车牌号码识别方式甄别包月车辆,注册时请确认车牌信息的准确性。</p>
                                    <p>2. 车辆须停放在划线范围内泊位上，提倡后退式停车。</p>
                                    <p> 3. 在停放车辆时不得占用、堵塞公共通道和消防通道，在告知无效的情况下，停车场管理方有权采取必要措施。当停车场管理方协助执法部门对车辆进行安全检查时，应予以配合。</p>
                                    <p> 4. 在包月协议生效之日起，采用一车一牌号收费，因包月用户驾驶其它未进行登记或包月的车辆出入停车场所产生的费用由用户自行承担，且不能用包月费替代减免。</p>
                                    <p> 5. 停车场属于公共停场所，仅提供停车位，先到先停，停满为止。不确保包月的车辆均有车位可停。</p>
                                    <p> 6. 包月停车费一经办理，恕不退还，如未到期限可以转让他人，转让方需向车场管理方申请并提供相关信息进行变更，审核通过后受让人的车辆即可以包月车辆进出停车场。</p>
                                    <p> 7. 用户理解、宁波停车需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，宁波停车无需为此承担任何责任，但宁波停车应尽可能事先进行通告。</p>
                                    </div>
                                    <WhiteSpace></WhiteSpace>
                                        <p><b>【免责声明】</b></p>
                                        <p> 请您仔细阅读以下条款，您使用宁波停车的行为即表示您已充分阅读、理解并接受本声明全部内容。</p>
                                        <p> 1. 由停车包月用户的原因造成停车场设施、设备、自己车辆、人身或他人车辆、人身受损，由包月用户承担一切责任。</p>
                                        <p> 2. 请不要在停放的车内存放现金等贵重物品及重要文件资料，如发生被盗、被抢等意外责任自负。</p>
                                        <p> 3. 因水灾、火灾、地震等自然原因或战争、社会动乱等社会原因导致的不可抗力致使包月用户车辆损毁的，停车场方不承担责任。</p>
                                        <p> 4. 本声明涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。宁波停车之声明以及其修改权、更新权及最终解释权均属宁波停车所有。</p>
                                    <WhiteSpace></WhiteSpace>
                                        <p><b>【知识产权】</b></p>
                                        <p>宁波停车在本服务中所使用的“宁波停车”等商业标识，其著作权或商标权归宁波停车所有。</p>
                                        <p>上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经宁波停车书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</p>
                                        <p><b>【隐私保护】</b></p>
                                        <p>保护用户隐私是宁波停车的一项基本原则，宁波停车保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在宁波停车的非公开内容，但下列情况除外：</p>
                                        <p>1. 事先获得用户的明确授权；</p>
                                        <p>2. 根据有关的法律法规要求；</p>
                                        <p>3. 按照相关政府主管部门的要求；</p>
                                        <p>4. 为维护社会公众的利益；</p>
                                        <p>5. 为维护宁波停车的合法权益。</p>
                                </div>
                            </WingBlank>
                            <WhiteSpace></WhiteSpace>
                            <WhiteSpace></WhiteSpace>
                        </div>
                        <WhiteSpace></WhiteSpace>
                        <div style={{textAlign:'center',color:'#ffffff',fontSize:'18px'}} onClick={() =>{
                            window.sessionStorage.setItem("dealCheck",'true');
                            this.props.history.push({pathname:'./MonthlyDetail'})}
                        }>我已阅读该协议</div>
                    </WingBlank>
                </div>
            </div>
        )
    }
}
