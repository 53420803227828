/*缴费详情路由组件*/
import React, { Component, Fragment } from "react";
import { WingBlank, WhiteSpace, Modal } from "antd-mobile";
import { getParkingLotRules, getParkingLots, getArrearsRecord, } from '../../fetch'
const sectionStyle = {
  width: "100%",
  minHeight: "100vh",
  height: '100%',
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  backgroundColor: "#ffffff",
  borderRadius: "8px",
};

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payPrice: [],
      arrearage: [],
      modal1: false,
      listLast: [],
      listTimes: [],
      new: "",
      price: "",
      stop: "",
      chargeType: 0,
      date: new Date(),
    };
  }
  showModal = (key) => (e) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    this.setState({
      [key]: true,
    });
    const carNum = {
      entityId: this.state.arrearage.elasParkingLotId,
      colorType: this.state.arrearage.colorType,
    };
    const { state: { baseCpId }, } = this.props.location;
    // 查询停车场规则
    getParkingLotRules({ payload: carNum, baseCpId, }).then(data => {
      if (data.rst) {
        const classifyList = data.rst;
        const longMes = classifyList.filter((item) => 0 == item.chargeRule);
        const timeMes = classifyList.filter((item) => 1 == item.chargeRule);

        this.setState({
          listLast: longMes,
          listTimes: timeMes,
        });
      }
    })
    // 查询所有的停车场
    const entityCode = { entityCode: this.state.arrearage.parkingLotCode, };
    getParkingLots(entityCode).then(data => this.setState({ stop: data.rst, }))
  };
  onClose = (key) => () => {
    this.setState({
      [key]: false,
    });
  };

  componentDidMount() {
    // 这里判断通过悬窗打开后 userid 消失的问题，判断一下，没有的话让他跳转到首页重新获取userid。
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger") > 0) && (id == null)) {//微信
      this.props.history.push('/');
      return false;
    }
    //根据车牌号查询欠费,缴费记录(需要展示免费记录)
    this.handlesclick();
  }

  handlesclick = () => {
    //根据车牌号查询欠费,缴费记录(需要展示免费记录)
    const orderCode = window.sessionStorage.getItem("orderCode");
    const carNum = { orderCode, };
    getArrearsRecord(carNum).then(data => {
      if (data.code == 1000) {
        window.sessionStorage.setItem('prefixUrl', data.rst.list[0].prefixUrl)
        this.setState({
          arrearage: data.rst.list[0],
        });
        const url = `${data.rst.list[0].prefixUrl}c/h5Manage/getArrearsRecord`
        fetch(url, {
          method: "POST",
          headers: { "Content-type": "application/json", },
          body: JSON.stringify({ orderCode, pageSize: 1, pageNum: 1 }),
        }).then(data => data.json()).then(res => {
          this.setState({
            payPrice: res.rst.list[0].payPrice,
          })
        })
      }
    })
  };
  Div_content = ({ title, text }) =>
    <div style={{ color: '#55617C', fontWeight: '400' }}>
      {title}&nbsp;&nbsp;&nbsp;&nbsp;
      <span style={{ color: '#000000' }}>{text}</span>
    </div>

  render() {
    const { listLast, listTimes } = this.state;
    const { stop, } = this.state;
    const code = stop[0] || {};

    const { arrearage, payPrice, } = this.state;
    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <div style={center}>
              <WingBlank>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>停车服务</p>
                <WhiteSpace />
                <div style={{ color: "#CBC9C9" }}>
                  订单编号: {arrearage.orderCode}
                </div>
                <WhiteSpace></WhiteSpace>
                <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
                <WhiteSpace></WhiteSpace>
                {this.Div_content({ title: "车牌号码", text: arrearage.carNumber })}
                <WhiteSpace></WhiteSpace>
                {this.Div_content({ title: "停车地点", text: arrearage.parkingLotAdress })}
                {arrearage.orderType == 0 ?
                  <Fragment>
                    <WhiteSpace></WhiteSpace>
                    {this.Div_content({ title: "泊 位 号", text: arrearage.berthNum })}
                  </Fragment>
                  : null
                }
                <WhiteSpace></WhiteSpace>
                {this.Div_content({ title: "驶入时间", text: arrearage.startTime })}
                <WhiteSpace></WhiteSpace>
                {this.Div_content({ title: "驶离时间", text: arrearage.endTime })}
                <WhiteSpace></WhiteSpace>
                <div>
                  {this.Div_content({
                    title: <span>停车时长</span>,
                    text: <Fragment>
                      <span style={{ color: "red" }}>{arrearage.hour} </span>时
                    <span style={{ color: "red" }}> {arrearage.minute} </span>分
                    <span style={{ color: "red" }}>  {arrearage.second} </span>秒
                  </Fragment>
                  })
                  }
                </div>
                <WhiteSpace></WhiteSpace>
                <div>
                  {this.Div_content({ title: "停车费用", text: <span style={{ color: "red" }}>{arrearage.orderFee} 元</span> })}
                </div>
                <WhiteSpace></WhiteSpace>
                <div
                  style={{
                    color: "#2D96FE",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  onClick={this.showModal("modal1")}
                >
                  停车收费标准{" "}
                  <img
                    src={require("../../components/images/what.png")}
                    alt="问号"
                    style={{ width: "10px" }}
                  />
                </div>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
              </WingBlank>
            </div>
          </WingBlank>
          <WhiteSpace></WhiteSpace>
        </div>
        {
          payPrice == null || payPrice.length == 0 ? null :
            <WingBlank>
              <div style={center}>
                <div style={{ borderRadius: "7px", padding: "15px", background: "#fff", }}>
                  <p style={{ fontSize: "18px", fontWeight: "bold" }}>已缴费</p>
                  <WhiteSpace />
                  {payPrice.map((item, index) => {
                    let _PayType = ""
                    switch (item.payType) {
                      case 0: _PayType = 'h5-微信'; break;
                      case 1: _PayType = 'h5-支付宝'; break;
                      case 2: _PayType = '现金支付'; break;
                      case 3: _PayType = 'pda支付宝'; break;
                      case 4: _PayType = 'pda微信'; break;
                      case 5: _PayType = '月租车包月'; break;
                      case 6: _PayType = '扫码支付'; break;
                    }
                    return <div key={index}>
                      <p>已支付：
                  <span style={{ fontSize: '18px', color: '#1890FF' }}>
                          {item.orderFee}（ {_PayType}支付）
                  </span>
                      </p>
                      <WhiteSpace />
                      {item.disuse == null || item.disuse.length == 0 ? null :
                        <div>
                          <div style={{ float: "left", }}>优惠券：</div>
                          <div style={{ float: "left", minWidth: "200px", }}>
                            {item.disuse.map((ite, ind) => {
                              return <div key={ind}>
                                <span style={{ float: "left", }}> {ite.discountName} </span>
                                <span style={{ color: "red", float: "right", }}>
                                  -{ite.deductFee} {ite.discountType == 1 ? "元" : "小时"}
                                </span>
                                <div style={{ clear: "both" }} />
                                <WhiteSpace />
                              </div>
                            })}
                          </div>
                          <div style={{ clear: "both" }} />
                          <WhiteSpace />
                        </div>
                      }
                      <p>支付时间：{item.payTime}</p>
                      <WhiteSpace />
                      {index + 1 == payPrice.length ? null : <div style={{ background: "#888", width: "100%", height: "1px", }} />}
                      <WhiteSpace />
                    </div>
                  })
                  }
                </div>
              </div>
            </WingBlank>
        }
        <Modal
          visible={this.state.modal1}
          transparent
          maskClosable={false}
          onClose={this.onClose("modal1")}
          // title="未查询到进行中订单"
          footer={[
            {
              text: "知道了",
              onPress: () => {
                this.onClose("modal1")();
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              height: 460,
              backgroundImage: `url(${require("../../components/images/fee.png")})`,
            }}
          >
            <h3 style={{ color: "#000000" }}>
              停车收费标准（{code.parkingLotName}）
            </h3>
            {/*<span style={{fontSize: '12px',color: '#2D96FE',float: 'left'}}>注:以一小时为计费单位(不足一小时按一小时计费)</span>*/}
            <table style={{ borderBottom: "none" }}>
            <tr>
                  <td style={{ borderLeft: "none", textAlign: "center" }}>
                    免费停车时长
                  </td>
                  <td></td>
                  <td>{listLast && listLast.length > 0?listLast[0].freeParkingMinute:0}分钟</td>
                </tr>
                <tr>
            <td style={{ borderLeft: "none", textAlign: "center" }}>
              付费后免费停车时长
            </td>
            <td></td>
            <td>{listLast && listLast.length > 0 ? listLast[0].freePayMinute : 0}分钟</td>
          </tr>
                <tr>
                  <td
                    style={{
                      borderLeft: "none",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    每日最大停车费
                  </td>
                  <td ></td>
                  <td >{listLast && listLast.length > 0?listLast[0].maxCharge:0}元</td>
                </tr>
              {listLast && listLast.length > 0
                ? listLast.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td style={{ borderLeft: "none", textAlign: "center" }}>
                        收费时段{index + 1}
                      </td>
                      <td>
                        {item.startTime && item.startTime.replace(".", ":")}-
                          {item.endTime && item.endTime.replace(".", ":")}
                      </td>
                      <td>
                        {item.maxFrame == '0.00' ? '免费' : <>最大停车费<br />{item.maxFrame}元</>}
                        {/* 最大停车费
                          <br />
                          {item.maxFrame}元 */}
                      </td>
                    </tr>
                    {item.maxFrame !== '0.00' && item.ruleList.map((x, index) => (
                      <tr>
                        <td style={{ borderLeft: "none", textAlign: "center" }} />
                        <td>{index == 0 ? '初始时段' : `第${index + 1}时段`}<br />{x.initialChargeNum}小时</td>
                        <td>
                          {`${x.initialUtilCharge}元/${x.initialUtilChargeNum}小时`}
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                        <td
                          style={{ borderLeft: "none", textAlign: "center" }}
                        ></td>
                        <td>
                          初始时段时长
                          <br />
                          {item.initialChargeNum}小时
                        </td>
                        <td>
                          单位收费时长
                          <br />
                          {item.initialUtilChargeNum}小时
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ borderLeft: "none", textAlign: "center" }}
                        ></td>
                        <td></td>
                        <td>
                          单位收费时长
                          <br />
                          {item.initialUtilCharge}元
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ borderLeft: "none", textAlign: "center" }}
                        ></td>
                        <td>其余时段</td>
                        <td>
                          单位收费时长
                          <br />
                          {item.otherUtilChargeNum}小时
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ borderLeft: "none", textAlign: "center" }}
                        ></td>
                        <td></td>
                        <td>
                          单位停车费
                          <br />
                          {item.otherUtilCharge}元
                        </td>
                      </tr> */}
                  </tbody>
                ))
                : ""}
              <tbody>
                {listTimes && listTimes.length > 0
                  ? listTimes.map((item, index) => (
                    <tr key={index}>
                      <td style={{ borderLeft: "none", textAlign: "center" }}>
                        收费时段{index + listLast.length + 1}
                      </td>
                      <td>
                        {item.startTime && item.startTime.replace(".", ":")}-
                          {item.endTime && item.endTime.replace(".", ":")}
                      </td>
                      <td>
                        单次停车费
                          <br />
                        {item.onceCharge}元/次
                        </td>
                    </tr>
                  ))
                  : ""}
               
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}
