/*订单类型路由组件*/
import  React,{Component} from 'react'
import  {WingBlank, WhiteSpace,Icon} from 'antd-mobile'
import { getAllBaseCp, } from '../../fetch'
const sectionStyle = {
    width: "100%",
    height: "100vh",
    backgroundSize: 'cover',
    backgroundImage: `url(${require("../../components/images/background.png")})`
};
const center={
    backgroundColor:'#ffffff',
    borderRadius:'13px'
};
const flexMain={
    height: '63px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    color:'#A7895F',
    fontSize:'13px'
};
const fontStyle={
    color:'#0A0A0A',
    fontSize:'23px',
    fontWeight:'bold'
}
export default  class OrderType extends Component {
    state={
        AllBaseCp:[]
    }
    componentDidMount(){
        const browser = window.navigator.userAgent.toLowerCase();
        const id = window.sessionStorage.getItem("userId");
        if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
            this.props.history.push('/'); 
            return false;
        }
        // 获取所有公司 参数url 
        getAllBaseCp({}).then(res=>this.setState({ AllBaseCp:res.rst, }))
    }

    render() {
        const { AllBaseCp, } = this.state;
        return(
            <div style={sectionStyle}>
                <div>
                    <WhiteSpace></WhiteSpace>
                    <WhiteSpace></WhiteSpace>
                    <WingBlank>
                        <div style={center}>
                            <WingBlank>
                                <div style={flexMain} onClick={() => { this.props.history.push({pathname:'./stopSever',state:{ AllBaseCp, } }) }}>
                                    <div style={fontStyle}>停车服务</div>
                                    <div> <Icon type="right" size="sm"/></div>
                                </div>
                            </WingBlank>
                        </div>
                        <WhiteSpace></WhiteSpace>
                        <div style={center}>
                            <WingBlank>
                                <div style={flexMain} onClick={() => {this.props.history.push({pathname:'./monthlyList',state:{ AllBaseCp, } })}}>
                                    <div style={fontStyle}>车场包月</div>
                                    <div> <Icon type="right" size="sm"/></div>
                                </div>
                            </WingBlank>
                        </div>
                        <WhiteSpace></WhiteSpace>
                        <div style={center}>
                            <WingBlank>
                                <div style={flexMain} onClick={() => {this.props.history.push({pathname:'./invoiceHistory'})}}>
                                    <div style={fontStyle}>开票历史</div>
                                    <div> <Icon type="right" size="sm"/></div>
                                </div>
                            </WingBlank>
                        </div>
                    </WingBlank>
                </div>
            </div>
        )
    }
}
