/*选择泊位号界面路由组件*/
import React, { Component } from "react";
import { getRunOrderByCarNum, getUrlBerthsByCode, } from '../../fetch'
import { Flex, WingBlank, WhiteSpace, Modal, Toast } from "antd-mobile";
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const car = {
  marginLeft: "80.5px",
  marginTop: "36px",
};
const center = {
  height: "264px",
  backgroundColor: "#ffffff",
  borderRadius: "5px",
  marginTop: "-14px",
};
const numberStyle = {
  textAlign: "center",
  fontSize: "16px",
};
const numberQuery = {
  textAlign: "center",
  fontSize: "12px",
  color: "#CBC9C9",
};
const carNumber = {
  border: "1px dashed #2D96FE",
  width: "30px",
  height: "30px",
  borderRadius: "3px",
  textAlign: "center",
  lineHeight: "30px",
};
const carNumbers = {
  backgroundColor: "#2580F3",
  width: "30px",
  height: "30px",
  borderRadius: "3px",
  textAlign: "center",
  lineHeight: "30px",
};
const StyleContent = { 
  float:"left",minWidth:"40%",width:"40%",margin:"10px 5%",textAlign:"center",lineHeight:"60px",
  boxSizing:"border-box", height:"64px",borderRadius:"4px",fontSize:"18px",fontWeight:"400",
}
const carNumberColorArr = [
  {color: '#FFF',text: '未知',},{color: '#002cA1',text: '蓝',},
  {color: '#b5a039',text: '黄',},{color: '#A4aaba',text: '白',},
  {color: '#000',text: '黑',},{color: '#2eb36c',text: '绿',},
];

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

export default class number extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1: false,
      berth: "",
      choiceNum: "",
      underway: "",
      leftBerth: true,
      rightBerth: false,
      modal2: true,
      result:[],
      OrderList:[],
      modal3:false,
      Car:{ orderCode:undefined, carNumber:undefined, },
    };
    this.leftOption = this.leftOption.bind(this);
  }

  leftOption = (item) => {
    this.setState({
      _berthNum:item.berthNum,
      leftBerth: false,
      rightBerth: true,
      prefixUrl:item.prefixUrl,
    });
  }
  rightOption = item => {
    this.setState({
      _berthNum:item.berthNum,
      leftBerth: true,
      rightBerth: false,
      prefixUrl:item.prefixUrl,
    });
  }
  // 高桩点击查询
  HeightOption = item =>{
    this.setState({ 
      _berthNum:item.berthNum,
      prefixUrl:item.prefixUrl, 
    })
  }

  componentDidMount() {
    this.berthNums();
  }
  // 1根据url后面的桩位号 获取对应有几个泊位号
  berthNums() {
    // http://m.elascloud.com/number?wisdomPileCode=YZH9
    // const query = this.props.location.search;
    // const wisdomPileCode = query.split("=")[1]

    // http://m.elascloud.com/n/YZH13
    const path = this.props.location.pathname; //为/n/YZH13
    const wisdomPileCode = path.split("/")[2];
    window.sessionStorage.setItem("wisdomPileCode", wisdomPileCode);
    getUrlBerthsByCode({ BerthsCode:wisdomPileCode }).then(res=>{
      this.setState({ result:res.rst })
    })
  }

  // 2 低桩确定左目还是右目
  NumClick = (e) => {
    this.setState({ choiceNum: e.target.innerText, });
  }

  // 3 根据泊位号查询进行中的订单列表
  handlesclick = () => {
    const { _berthNum, prefixUrl, } = this.state;
    const url = `${prefixUrl}c/jobHandle/getUnderwayOrderList`
    fetch(url,{
      method: "POST",
      headers: { "Content-type": "application/json", },
      body: JSON.stringify({ berthNum:_berthNum }), 
    }).then(res=>res.json()).then(data=>{
      if(data.code==1000){
        if(data.rst.length){
          this.setState({ OrderList:data.rst, modal3:true, })
        }else{ this.setState({ modal1: true, }) }
      }else{ Toast.offline(data.desc,1.5,()=>this.setState({ modal1: true, })) }
    })
    // getRunOrderByCarNum({ berthNum:_berthNum }).then(data=>{
    //   this.setState({ underway: data.rst, },()=>{
    //     if (data.rst) {
    //       window.sessionStorage.setItem( "_data", JSON.stringify(data.rst) );
    //       this.props.history.push({ pathname: "../more" });
    //     } else {
    //       this.setState({ modal1: true, });
    //     }
    //   });
    // })
  }

  // 根据车牌号,泊位号查订单
  getUnderwayOrderList = () =>{
    const { _berthNum, prefixUrl, Car:{ orderCode, carNumber, } } = this.state;
    if(orderCode&&carNumber){
      const url = `${prefixUrl}c/h5Manage/getRunOrderByCarNum`
      fetch(url,{
        method: "POST",
        headers: { "Content-type": "application/json", },
        body: JSON.stringify({ orderCode, carNum:carNumber, berthNum:_berthNum }), 
      }).then(res=>res.json()).then(data=>{
        if(data.code==1000){
          // hasCarNumber，1为有牌车，0为无牌车
          const NoCar = carNumber=='未知车牌'?1:0
          window.sessionStorage.setItem("NoCar",NoCar);
          window.sessionStorage.setItem( "_data", JSON.stringify(data.rst) );
          window.sessionStorage.setItem( 'pathname', './more' );
          this.props.history.push({ pathname: "../" });
        }else{ Toast.offline(data.desc,1.5,()=>this.setState({ modal1: true, })) }
      })
    }else{ alert("请选择车牌号订单！") }
  }

  onWrapTouchStart = (e) => {
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };

  render() {
    const { result, _berthNum, modal3, OrderList, } = this.state;
    return (
      <div style={sectionStyle}>
        <img
          src={require("../../components/images/car.png")}
          style={car}
          alt="标签"
        />
        <WingBlank>
          <div style={center}>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <div style={numberStyle}>
              <b>输入车牌号码</b>
            </div>
            <WhiteSpace></WhiteSpace>
            <div style={numberQuery}>请输入车牌号进行缴费查询</div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <Flex>
                <Flex.Item style={carNumber}></Flex.Item>
                <Flex.Item style={carNumber}></Flex.Item>
                <Flex.Item style={carNumber}></Flex.Item>
                <Flex.Item style={carNumber}></Flex.Item>
                <Flex.Item style={carNumber}></Flex.Item>
                <Flex.Item style={carNumber}></Flex.Item>
                <Flex.Item style={carNumber}></Flex.Item>
                <Flex.Item style={carNumbers}></Flex.Item>
              </Flex>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              <WhiteSpace></WhiteSpace>
            </WingBlank>
          </div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
        </WingBlank>

        <Modal
          visible={this.state.modal2}
          transparent
          maskClosable={false}
          footer={[
            {
              text: "取消",
              onPress: () => {
                this.props.history.push({ pathname: "../main" });
              },
              style: "default",
            },
            { text: "确定", onPress: () => this.handlesclick() },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              height: 190,
              width: "280px",
              padding: "0 16px",
              background: result.length>2?null:`url(${require("../../components/images/stopNumber.png")}) center`,
            }}
          >
            <h3 style={{ color: "#000000" }}>选择泊位号</h3>
            {result.length>2?
              // 高桩
              <div>
                {result.map((item,index)=>
                  <div 
                    key={index} 
                    style={StyleContent} 
                    className={item.berthNum==_berthNum? "border" : "borderNone"} 
                    onClick={()=>this.HeightOption(item)}
                  >
                    {item.berthNum}
                  </div>
                )}
                <div style={{ clear:"both", }}/>
              </div>:
              // 低桩
              <Flex style={{ textAlign: "center" }} justify="around">
                {result.map((item,index)=>{
                  return <Flex.Item key={item.berthNum}>
                  <div
                    style={{ margin:"auto",marginTop:"20px", }}
                    onClick={()=>index?this.leftOption(item):this.rightOption(item)}
                    className={item.berthNum==_berthNum? "active" : "active1"}
                  >
                    <p>{index?'右目':'左目'}</p>
                    <h4 onClick={this.NumClick}>
                      {item.berthNum}
                    </h4>
                  </div>
                </Flex.Item>
                })}
              </Flex>
            }
          </div>
        </Modal>

        {/*未查询到进行中订单*/}
        <Modal
          visible={this.state.modal1}
          transparent
          maskClosable={false}
          footer={[
            {
              text: "知道了",
              onPress: () => {
                this.props.history.push({ pathname: "../main" });
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              height: 190,
              backgroundImage: `url(${require("../../components/images/no.png")})`,
            }}
          >
            <h3 style={{ color: "#000000" }}>未查询到进行中订单</h3>
            <span style={{ color: "#CBC9C9" }}>关注公众号"云之合"</span>
          </div>
        </Modal>
            
        {/* 选择车牌弹框 */}
        <Modal
          visible={modal3}
          transparent
          maskClosable={false}
          footer={[
            { text: "取消", style: "default", onPress: () => this.setState({ modal3:false, }),},
            { text: "确定", onPress: () => this.getUnderwayOrderList() },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <WhiteSpace size="lg"/>
          <WhiteSpace size="lg"/>
          {OrderList.length?OrderList.map(item=>{
            const { colorType, orderCode, carNumber } = item;
            const _col = carNumberColorArr[colorType]
            return <WingBlank size="lg">
                <div 
                  key={orderCode} 
                  style={{ 
                    background:_col.color, fontSize:"22px",
                    color:_col.color=='#FFF'?"#000":'#FFF',
                    border:_col.color=='#FFF'?"1px solid #888":null,
                    borderRadius:"4px",
                    boxShadow:this.state.Car.orderCode==orderCode?"0px 0px 10px #108ee9":null,
                  }}
                  onClick={e=>this.setState({ Car:item })}
                >
                {carNumber}
                </div>
              <WhiteSpace />
              <WhiteSpace />
              </WingBlank>
              })
          :null}
        </Modal>
      </div>
    );
  }
}
