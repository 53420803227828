/*车辆包月路由组件*/
import  React,{Component} from 'react'
import  {WingBlank, WhiteSpace,Icon, Toast} from 'antd-mobile'
import { getUserProve, } from '../../fetch'
const sectionStyle = {
    width: "100%",
    height: "100vh",
    backgroundSize: 'cover',
    backgroundImage: `url(${require("../../components/images/background.png")})`
};
const center={
    backgroundColor:'#ffffff',
    borderRadius:'13px'
};
const flexMain={
    height: '93px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    color:'#A7895F',
    fontSize:'13px'
};
const fontStyle={
    color:'#0A0A0A',
    fontSize:'23px',
    fontWeight:'bold'
}
export default  class MyParking extends Component {
    state={
        GoDetail:false,
    }

    componentDidMount(){
        this.start()
    }

    start = () =>{
        const id = {
            userId: window.sessionStorage.getItem("userId"),
        };
        getUserProve(id).then(data=>{
            const { rst:{ phone, userName }, code, desc } = data;
            if(code==1000){
                if(phone!==null&&userName!==null){
                    this.setState({ GoDetail:true, })
                }else{
                    this.setState({ GoDetail:false, })
                }
            }else{
                Toast.info(desc)
            }
        })
    }
// 剩余  产品 同时将用户的姓名、手机号、身份证号记录到平台的月租充值列表和发票列表（身份证号不需要记录到发票列表）
    // 车场包月
    Go1 = () =>{
        const { GoDetail, } = this.state;
        if(GoDetail){
            this.props.history.push({pathname:'./monthlyCar'})
        }else{
            window.sessionStorage.setItem("_GoDetail",'./monthlyCar')
            this.props.history.push({pathname:'./myIdentification'})
        }
    }
    // 电子发票
    Go2 = () =>{
        const { GoDetail, } = this.state;
        if(GoDetail){
            this.props.history.push({pathname:'./orderType'})
        }else{
            window.sessionStorage.setItem("_GoDetail",'./orderType')
            this.props.history.push({pathname:'./myIdentification'})
        }
    }

    render() {
        const userId = window.sessionStorage.getItem("userId");
        return(
            <div style={sectionStyle}>
                <div>
                    <WhiteSpace></WhiteSpace>
                    <WhiteSpace></WhiteSpace>
                    <WingBlank>
                        <div style={center}>
                            <WingBlank>
                                <div style={flexMain} onClick={this.Go1}>
                                    <div>
                                        <div style={fontStyle}>车场包月</div>
                                        <WhiteSpace></WhiteSpace>
                                        <div>车辆停车场包月续费管理</div>
                                    </div>
                                    <div> <Icon type="right" size="sm"/></div>
                                </div>
                            </WingBlank>
                        </div>
                        <WhiteSpace></WhiteSpace>
                        <div style={center}>
                            <WingBlank>
                                <div style={flexMain} onClick={this.Go2}>
                                    <div>
                                        <div style={fontStyle}>电子发票</div>
                                        <WhiteSpace></WhiteSpace>
                                        <div>开具停车缴费订单电子发票</div>
                                    </div>
                                    <div> <Icon type="right" size="sm"/></div>
                                </div>
                            </WingBlank>
                        </div>
                        <WhiteSpace></WhiteSpace>
                        {userId !==null?
                        <div style={center}>
                            <WingBlank>
                                <div style={flexMain} onClick={() => this.props.history.push({pathname:'./myIdentification'})}>
                                    <div>
                                        <div style={fontStyle}>用户认证</div>
                                        <WhiteSpace></WhiteSpace>
                                        <div>用户认证通过后可以开具发票</div>
                                    </div>
                                    <div> <Icon type="right" size="sm"/></div>
                                </div>
                            </WingBlank>
                        </div>:''}
                    </WingBlank>
                </div>
            </div>
        )
    }
}
