/*支付状态路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace, Button } from "antd-mobile";
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  height: "215px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  fontSize: "17px",
};
const StyleDian = { border:"1px solid rgb(51,65,97)",width:"8px",height:"8px",display:"inline-block",borderRadius:"8px",background:"rgb(51,65,97)",margin:"0 5px" }
const img_style = { marginTop: "45px", width: "71px", height: "71px",}

function GetRequest() {
  var url = window.location.search; //获取url中"?"符后的字串
  var theRequest = {};
  if (url.indexOf("?") !== -1) {
    var str = url.substr(1);
    const strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

const type = GetRequest().type;


export default class PayStatus extends Component {
  render() {
    const price = window.sessionStorage.getItem("money");
    const payStatus = window.sessionStorage.getItem("orderStatus");
    const payType = window.sessionStorage.getItem("payType");
    const freeParkingMinute = window.sessionStorage.getItem("freeParkingMinute");
    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <div style={center}>
              <WingBlank>
                <div style={{ textAlign: "center",color:"rgb(51,65,97)", }}>
                  <img 
                    src={
                      payStatus == 1 ?
                      require("../../components/images/success.png"):
                      require("../../components/images/fail.png")
                    } 
                    style={img_style} 
                    alt="标签" 
                  />
                  <WhiteSpace></WhiteSpace>
                  {payStatus == 1 ? 
                    <div style={{ fontWeight:"400",fontSize:"20px", }}>
                      {Number(payType) == 1 ? "停车" : "包月"}成功支付
                      <span style={{ color: "#FD3434" }}> {price}</span>元
                    </div>
                    : 
                    <div>{Number(payType) == 1 ? "停车" : "包月"}支付失败</div>
                  }
                  <WhiteSpace />

                  {Number(freeParkingMinute)!==0 && payStatus==1?
                  <div style={{ fontSize:"12px", }}>
                    <span style={StyleDian}/>
                    请与付款后 <span style={{ color:"red" }}>{Number(freeParkingMinute)}</span>分钟内离场，以免再次产生费用
                  </div>
                  :null}

                </div>
              </WingBlank>
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            {payStatus == 1 ? 
              <Button
                type="default"
                style={{ borderRadius: "25px", fontSize: "17px" }}
                onClick={() => this.props.history.push({ pathname: "./costRecord" }) }
              >
                查看缴费记录
              </Button>
             : null}
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>

            {Number(type) == 1 ? 
              <Button
                type="default"
                style={{ borderRadius: "25px", fontSize: "17px" }}
                onClick={() => this.props.history.push({ pathname: "./costRecord" })}
              >
                查看订单记录
              </Button>
             : 
              <Button
                type="default"
                style={{ borderRadius: "25px", fontSize: "17px" }}
                onClick={() => this.props.history.push({ pathname: "./monthlyOrder" })}
              >
                查看包月记录
              </Button>
            }

            <Button
              type="default"
              style={{ borderRadius: "25px", fontSize: "17px", marginTop: 20 }}
              onClick={() => {
                window.sessionStorage.setItem("fee",price)
                this.props.history.push({ pathname: "./invoice" });
              }}
            >
              开具电子发票
            </Button>
          </WingBlank>
        </div>
      </div>
    );
  }
}
