/*包月详情路由组件*/
import React, { Component, Fragment } from "react";
import { WingBlank, WhiteSpace, Modal } from "antd-mobile";
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  backgroundColor: "#ffffff",
  borderRadius: "8px",
};
const detail = {
  backgroundColor: "#ffffff",
  borderRadius: "8px",
};
export default class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrearage: "",
      mesDetail: "",
      modal1: false,
      listLast: [],
      listTimes: [],
      new: "",
      price: "",
      stop: "",
      chargeType: 0,
      date: new Date(),
    };
  }
  showModal = (key) => (e) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    this.setState({
      [key]: true,
    });
    const carNum = {
      entityId: this.state.arrearage.elasParkingLotId,
      colorType: this.state.arrearage.colorType,
    };
    const entityCode = {
      entityCode: this.state.arrearage.parkingLotCode,
    };
    const url = "c/h5Manage/getParkingLotRules";
    const param = {
      requestUrl: url,
      param: JSON.stringify(carNum),
      baseCpId: null,
    };
    fetch("a/request/all", {
      // post提交
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param), //把提交的内容转字符串
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.rst.length > 0) {
          const classifyList = data.rst;
          const longMes = classifyList.filter((item) => 0 == item.chargeRule);
          const timeMes = classifyList.filter((item) => 1 == item.chargeRule);
          this.setState({
            listLast: longMes,
            listTimes: timeMes,
          });
        }
      });
    const urls = "c/h5Manage/getParkingLots";
    fetch(urls, {
      // post提交
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(entityCode), //把提交的内容转字符串
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          stop: data.rst,
        });
      });
  };
  onClose = (key) => () => {
    this.setState({
      [key]: false,
    });
  };

  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
      this.props.history.push('/'); 
      return false;
    }
    this.handlesclick();
  }

  handlesclick = () => {
    const prefixUrl = window.sessionStorage.getItem("prefixUrl");
    const orderCode = window.sessionStorage.getItem("orderCode");
    const carNum = { orderCode: orderCode, };
    const url = prefixUrl + "c/h5Manage/getCardMsg";
    fetch(url, {
      // post提交
      method: "POST",
      headers: { "Content-type": "application/json", },
      body: JSON.stringify(carNum), //把提交的内容转字符串
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code == 1000) {
          this.setState({
            arrearage: data.rst.elasCardDetail,
            mesDetail: data.rst.clubClrd,
          });
        }
      });
  };
  Div_content = ({title,text}) => 
    <div style={{ color:'#55617C',fontWeight:'400' }}>
      {title}&nbsp;&nbsp;&nbsp;&nbsp;
      <span style={{ color:'#000000' }}>{text}</span>
    </div>

  render() {
    const { listLast, listTimes } = this.state;
    const { stop, } = this.state;
    const code = stop[0] || {};

    const { arrearage, mesDetail } = this.state;

    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <div style={center}>
              <WingBlank>
                <WhiteSpace size="lg"/>
                <div style={{ fontSize: "17px", textAlign: "center" }}>包月订单信息</div>
                <WhiteSpace size="lg"/>
                <div style={{ color: "#CBC9C9" }}>订单编号&nbsp;&nbsp;&nbsp;&nbsp;{arrearage.orderCode}</div>
                <WhiteSpace></WhiteSpace>
                <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
                <WhiteSpace></WhiteSpace>
                <div style={{ color:'#55617C',fontWeight:'400' }}>
                  {this.Div_content({ title:"月租卡号", text:arrearage.clubCardCode })}
                  <WhiteSpace></WhiteSpace>
                  {this.Div_content({ title:"车牌号码", text:arrearage.carNumber })}
                  <WhiteSpace></WhiteSpace>
                  {this.Div_content({ title:"车场名称", text:mesDetail.parkingLotName })}
                  <WhiteSpace></WhiteSpace>
                  {this.Div_content({ title:"停车地点", text:mesDetail.adress })}
                </div>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
              </WingBlank>
            </div>
          </WingBlank>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <div style={detail}>
              <WingBlank>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                <div style={{ fontSize: "17px", textAlign: "center" }}>包月计费详情</div>
                <WhiteSpace></WhiteSpace>
                <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
                <WhiteSpace></WhiteSpace>
                {this.Div_content({ title:"包月套餐", text:`${arrearage.renewDay} 个月` })}
                <WhiteSpace></WhiteSpace>
                <div>
                {this.Div_content({ title:"包月费用", text:<Fragment>
                  <span style={{ color: "red" }}>{arrearage.cardFee}</span> 元 
                  （ {mesDetail.payType == 0? "H5-微信": 
                      mesDetail.payType == 1? "H5-支付宝": 
                      mesDetail.payType == 2? "现金支付": 
                      mesDetail.payType == 3? "pda支付宝": 
                      mesDetail.payType == 4? "pda微信": 
                      mesDetail.payType == 5? "月租车包月": 
                      mesDetail.payType == 6? "扫码支付": ""} ）
                </Fragment> })}
                </div>
                <WhiteSpace></WhiteSpace>
                {this.Div_content({ title:"包月时间", text:`${arrearage.createdTime}` })}
                <WhiteSpace></WhiteSpace>
                {this.Div_content({ title:"开始时间", text:`${arrearage.startTime}` })}
                <WhiteSpace></WhiteSpace>
                {this.Div_content({ title:"结束时间", text:`${arrearage.endTime}` })}
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
              </WingBlank>
            </div>
          </WingBlank>
        </div>

        <Modal
          visible={this.state.modal1}
          transparent
          maskClosable={false}
          onClose={this.onClose("modal1")}
          // title="未查询到进行中订单"
          footer={[
            {
              text: "知道了",
              onPress: () => {
                this.onClose("modal1")();
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              height: 460,
              backgroundImage: `url(${require("../../components/images/fee.png")})`,
            }}
          >
            <h3 style={{ color: "#000000" }}>
              停车收费标准（{code.parkingLotName}）
            </h3>
            {/*<span style={{fontSize: '12px',color: '#2D96FE',float: 'left'}}>注:以一小时为计费单位(不足一小时按一小时计费)</span>*/}
            <table style={{ borderBottom: "none" }}>
            <tr>
                  <td style={{ borderLeft: "none", textAlign: "center" }}>
                    免费停车时长
                  </td>
                  <td></td>
                  <td>{listLast && listLast.length > 0?listLast[0].freeParkingMinute:0}分钟</td>
                </tr>
                <tr>
            <td style={{ borderLeft: "none", textAlign: "center" }}>
              付费后免费停车时长
            </td>
            <td></td>
            <td>{listLast && listLast.length > 0 ? listLast[0].freePayMinute : 0}分钟</td>
          </tr>
                <tr>
                  <td
                    style={{
                      borderLeft: "none",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    每日最大停车费
                  </td>
                  <td ></td>
                  <td >{listLast && listLast.length > 0?listLast[0].maxCharge:0}元</td>
                </tr>
              {listLast && listLast.length > 0
                ? listLast.map((item, index) => (
                    <tbody key={index}>
                      <tr>
                        <td style={{ borderLeft: "none", textAlign: "center" }}>
                          收费时段{index + 1}
                        </td>
                        <td>
                          {item.startTime && item.startTime.replace(".", ":")}-
                          {item.endTime && item.endTime.replace(".", ":")}
                        </td>
                        <td>
                          最大停车费
                          <br />
                          {item.maxFrame}元
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ borderLeft: "none", textAlign: "center" }}
                        ></td>
                        <td>
                          初始时段时长
                          <br />
                          {item.initialChargeNum}小时
                        </td>
                        <td>
                          单位收费时长
                          <br />
                          {item.initialUtilChargeNum}小时
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ borderLeft: "none", textAlign: "center" }}
                        ></td>
                        <td></td>
                        <td>
                          单位收费时长
                          <br />
                          {item.initialUtilCharge}元
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ borderLeft: "none", textAlign: "center" }}
                        ></td>
                        <td>其余时段</td>
                        <td>
                          单位收费时长
                          <br />
                          {item.otherUtilChargeNum}小时
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ borderLeft: "none", textAlign: "center" }}
                        ></td>
                        <td></td>
                        <td>
                          单位停车费
                          <br />
                          {item.otherUtilCharge}元
                        </td>
                      </tr>
                    </tbody>
                  ))
                : ""}
              <tbody>
                {listTimes && listTimes.length > 0
                  ? listTimes.map((item, index) => (
                      <tr key={index}>
                        <td style={{ borderLeft: "none", textAlign: "center" }}>
                          收费时段{index + listLast.length + 1}
                        </td>
                        <td>
                          {item.startTime && item.startTime.replace(".", ":")}-
                          {item.endTime && item.endTime.replace(".", ":")}
                        </td>
                        <td>
                          单次停车费
                          <br />
                          {item.onceCharge}元/次
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}
