/*欠费订单列表路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace, Button, Checkbox, ListView } from "antd-mobile";
import ReactDOM from "react-dom";

const CheckboxItem = Checkbox.CheckboxItem;
const center = {
  height: "193px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
};
const nullList = {
  width: "100%",
  height: "100vh",
  background: `url(${require("../../components/images/noList.png")}) no-repeat center`,
};
export default class Order extends Component {
  constructor(props) {
    super(props);
    this.pageNum = 1; //定义分页信息
    this.pageSize = 10;

    const arrearageDataSource = new ListView.DataSource({
      //这个dataSource有cloneWithRows方法
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      arrearage: "",
      isAllChecked: false,
      money: 0,
      orderCode: "",
      arrearageDataSource,
      refreshing: true,
      isLoading: true,
      height: document.documentElement.clientHeight,
      useBodyScroll: true,
      hasMore: true,
    };
  }

  way = () => {
    this.state.orderCode == ""
      ? alert("请选择要支付的订单")
      : this.props.history.push({ pathname: "./way" });
    window.sessionStorage.setItem("entityCodes", this.state.orderCode);
    window.sessionStorage.setItem("money", this.state.money);
  };

  total = () => {
    let arr = this.state.arrearage;
    let price = 0;
    const a = [];
    // eslint-disable-next-line
    arr && arr.map((item) => {
        if (item.checked == true) {
          const fee = parseFloat(item.orderFee);
          price += fee;

          a.push(item.orderCode);
        }
      });
    this.setState({
      money: price,
      orderCode: a,
    });
  }

  // 全选
  isAllChecked(e) {
    let bool = e.target.checked;
    if (bool) {
      this.setState({ isAllChecked: true, });
      this.state.arrearage.filter((item) => item.checked = true);
    } else {
      this.setState({
        isAllChecked: false,
      });
      this.state.arrearage.filter((item) => item.checked = false);
    }
    this.setState({
      arrearage: this.state.arrearage,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        JSON.parse(JSON.stringify(this.state.arrearage)) // 更改state数据时也要把dataSource重新赋值
      ),
    });
    this.total()
  }

  // 勾选
  singleChecked(e, i) {
    // eslint-disable-next-line
    this.state.arrearage[i].checked = e.target.checked;
    this.setState({
      arrearage: this.state.arrearage,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        JSON.parse(JSON.stringify(this.state.arrearage)) // 更改state数据时也要把dataSource重新赋值
      ),
    });
    var bool = this.state.arrearage.filter((item) => {
      return !item.checked;
    });
    if (bool.length == 0) {
      this.setState({
        isAllChecked: true,
      });
    } else {
      this.setState({
        isAllChecked: false,
      });
    }
    this.total();
  }

  async componentDidMount() {
    this.rData = await this.genData({
      pageNum: this.pageNum,
      pageSize: this.pageSize,
    });
    if (this.lv) {
      const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
      this.setState({
        height: hei,
      });
    }

    this.setState({
      arrearage: this.rData,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        this.rData
      ),
      // heisght: hei,
      refreshing: false,
      isLoading: false,
    });
  }

  genData = (params) => {
    this.pageNum++;
    const carNums = window.sessionStorage.getItem("carNum");
    // const carNum=this.props.location.carNum;
    const carNum = {
      // carNum:'浙A9910Y',
      carNum: carNums,
      orderStatus: "1",
      ...params,
    };
    const url = "c/h5Manage/getArrearsRecord";
    const param = {
      requestUrl: url,
      param: JSON.stringify(carNum),
      baseCpId: null,
    };
    return (
      fetch("a/request/all", {
        // post提交
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-Token":
            "eyJiYXNlQ3BJZCI6MjMyLCJiYXNlRGF0YVVybCI6ImpkYmM6bXlzcWw6Ly9ybS1icDE1azJ5ZDc1bTRxZDVpeTJvLm15c3FsLnJkcy5hbGl5dW5jcy5jb206MzMwNi9lbGFzY2xvdWRfdGVzdD9zZXJ2ZXJUaW1lem9uZT1Bc2lhL1NoYW5naGFpJnVzZU9sZEFsaWFzTWV0YWRhdGFCZWhhdmlvcj10cnVlJnplcm9EYXRlVGltZUJlaGF2aW9yPWNvbnZlcnRUb051bGwiLCJiYXNlRGF0YU5hbWUiOiJlbGFzY2xvdWRfdGVzdCIsImJhc2VEYXRhVXNlck5hbWUiOiJ5emhfdGVzdCIsImJhc2VEYXRhUGFzc3dvcmQiOiJmZXNhZmVhZCIsImZsYWciOjEsImFsZyI6IkhTNTEyIn0.eyJleHAiOjE1NzQzMTkyMjUsImp0aSI6IjEifQ.QwLTUex2VNiRB79Wftt1FI_UEYPoZPCuNkYUopGg94Cjwn836W2DkWTZxyZ7Wf7ME8m1VEJDWc55w7CnG2_kRg",
        },
        body: JSON.stringify(param), //把提交的内容转字符串
      })

        .then((res) => res.json())
        .then((result) => {
          if (result) {
            if (result.code == 1000) {
              return result.rst.list;
            } else {
              alert(result.desc);
              return;
            }
          } else {
            this.setState({
              hasMore: false,
            });
          }
        })
    );
  };

  onEndReached = async (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    } //如果this.state.hasMore为false，说明没数据了，直接返回
    this.setState({ isLoading: true });
    this.rData = [
      ...this.rData,
      ...(await this.genData({
        // invoiceFlag: this.state.invoice ? 0 : 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // startTime: this.state.startTime ? this.state.startTime : undefined,
        // endTime: this.state.endTime ? this.state.endTime : undefined,
        // startTime2: this.state.startTime2 ? this.state.startTime2 : undefined,
        // endTime2: this.state.endTime2 ? this.state.endTime2 : undefined,
      })),
    ]; //每次下拉之后将新数据装填过来
    setTimeout(() => {
      this.setState({
        arrearage: this.rData,
        arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
          this.rData
        ),
        isLoading: false,
      });
    }, 1000);
  };

  render() {
    const { arrearage, arrearageDataSource } = this.state;

    const row = (rowData, sectionID, rowID) => {
      return (
        <div key={rowID}>
          <div style={center}>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={{ color: "#CBC9C9" }}>
                订单编号:{rowData.orderCode}
              </div>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              <WhiteSpace></WhiteSpace>
              <div
                onClick={() => {
                  sessionStorage.setItem("orderCode", rowData.orderCode);
                  this.props.history.push({
                    pathname: "./detail",
                    order: {
                      orderStatus: "1",
                      orderCode: rowData.orderCode,
                    },
                  });
                }}
              >
                <div>车牌号码: {rowData.carNumber}</div>
                <WhiteSpace></WhiteSpace>
                <div>停车地点: {rowData.parkingLotAdress}</div>
                <WhiteSpace></WhiteSpace>
                <div>
                  停车时长: <span style={{ color: "red" }}>{rowData.hour}</span>
                  小时
                  <span style={{ color: "red" }}>{rowData.minute}</span>
                  分钟
                </div>
                <WhiteSpace></WhiteSpace>
                <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              </div>
            </WingBlank>
            <WhiteSpace></WhiteSpace>
            <CheckboxItem
              onChange={(e) => this.singleChecked(e, rowID)}
              checked={rowData.checked ? true : false}
            >
              <div>
                金额:
                <span style={{ color: "red" }}>{rowData.orderFee}</span>元
              </div>
              <Button
                type="primary"
                inline
                size="small"
                // onClick={this.way}
                onClick={() => {
                  sessionStorage.setItem("orderCode", rowData.orderCode);
                  this.props.history.push({ pathname: "./detail" });
                }}
                style={{
                  marginRight: "4px",
                  borderRadius: "20px",
                  position: "absolute",
                  right: "10px",
                  top: "7px",
                }}
              >
                &nbsp;&nbsp;支付&nbsp;&nbsp;
              </Button>
            </CheckboxItem>
          </div>
          <WhiteSpace></WhiteSpace>
        </div>
      );
    };
    return (
      <div style={{ marginTop: 43.5, width: "100%" }}>
        {arrearage && arrearage.length > 0 ? (
          <div style={{ marginBottom: 60, height: "100%" }}>
            <ListView
              key={this.state.useBodyScroll ? "0" : "1"}
              ref={(el) => (this.lv = el)}
              dataSource={arrearageDataSource}
              renderFooter={
                () => (
                  <div style={{ padding: 30, textAlign: "center" }}>
                    {this.state.isLoading ? "加载中..." : "加载完成"}
                  </div>
                )
              }
              renderRow={row} //渲染你上边写好的那个row
              useBodyScroll={this.state.useBodyScroll}
              style={
                this.state.useBodyScroll
                  ? {}
                  : {
                      height: this.state.height,
                      border: "1px solid #ddd",
                      margin: "5px 0",
                    }
              }
              onEndReached={this.onEndReached}
              invoicePageSize={this.pageSize} //每次下拉之后显示的数据条数
            />
            <span
              style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                width: "100%",
                bottom: "0",
                padding: "8px 0",
              }}
            >
              <CheckboxItem
                checked={this.state.isAllChecked ? true : false}
                onChange={(e) => {
                  this.isAllChecked(e);
                }}
              >
                <div>
                  金额：
                  <span style={{ color: "red" }}>{this.state.money}</span>元
                </div>
                <Button
                  type="primary"
                  inline
                  size="small"
                  style={{
                    marginRight: "4px",
                    borderRadius: "20px",
                    position: "absolute",
                    right: "10px",
                    top: "7px",
                  }}
                  onClick={this.way}
                >
                  &nbsp;&nbsp;支付&nbsp;&nbsp;
                </Button>
              </CheckboxItem>
            </span>
          </div>
        ) : (
          <div style={nullList}></div>
        )}
      </div>
    );
  }
}
