/*车辆详情路由组件*/
import React, { Component } from "react";
import { WingBlank, WhiteSpace, Button, Toast } from "antd-mobile";
import { Link } from "react-router-dom";
import { getMsgByCar, } from '../../fetch'
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  backgroundColor: "#ffffff",
  borderRadius: "13px",
};
const main = {
  height: "93px",
  textAlign: "center",
  color: "#0A0A0A",
  fontSize: "35px",
  //   lineHeight: "93px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#ffffff",
  borderRadius: "13px",
};
const flexMain = {
  height: "93px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#A7895F",
  fontSize: "13px",
};
const fontStyle = {
  color: "#0A0A0A",
  fontSize: "23px",
  fontWeight: "bold",
};

export default class MyCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      carNum: "",
    };
  }
  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
      this.props.history.push('/'); 
      return false;
    }
    const carNum = window.sessionStorage.getItem("carNum");
    this.handleClick(carNum);
  }

  handleClick(carNum) {
    const id = { carNum: carNum, };
    getMsgByCar(id).then(data=> this.setState({ data: data.rst, }))
  }

  deleteCar(number) {
    const id = window.sessionStorage.getItem("userId");
    const params = {
      carNum: number,
      userId: id,
    };
    const url = "c/h5Manage/delCarByNum";
    const param = {
      requestUrl: url,
      param: JSON.stringify(params),
      baseCpId: null,
    };
    fetch("a/request/all", {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.code == 1000) {
          Toast.success("删除成功", 2, () =>
            this.props.history.push({ pathname: "./main" })
          );
        } else {
          Toast.fail("删除失败", 1);
        }
      });
  }

  render() {
    const carNum = window.sessionStorage.getItem("carNum");
    const { data } = this.state;
    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            {/* <div style={main}>{carNum}</div> */}
            <Link to={{ pathname:"./more", state:data }}>
              <div style={main}>
                <p>{carNum}</p>
                <p style={{ fontSize: 12, color: "#ccc", lineHeight: 1.5 }}>
                  *点击查看进行中订单
                </p>
              </div>
            </Link>

            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            {data && data.length > 0
              ? data.map((item, index) => (
                  <div key={index}>
                    <div style={center}>
                      <WingBlank>
                        <div style={flexMain}>
                          <div>
                            <div style={fontStyle}>{item.parkingLotName}</div>
                            <WhiteSpace></WhiteSpace>
                            <div>
                              {item.startTime &&
                                item.startTime
                                  .slice(0, 10)
                                  .replace("-", "/")}{" "}
                              -{" "}
                              {item.endTime &&
                                item.endTime.slice(0, 10).replace("-", "/")}
                            </div>
                          </div>
                          <Button
                            style={{
                              backgroundColor: "#A7895F",
                              color: "#FFFFFF",
                            }}
                            size="small"
                            onClick={() => {
                              // sessionStorage.setItem("cardTime", item.endTime);
                              // sessionStorage.setItem("cardTime",'2020-12-20 10:02:03');
                              sessionStorage.setItem(
                                "parkingLotCode",
                                item.parkingLotCode
                              );
                              this.props.history.push({
                                pathname: "./monthlyDetail", state: { carChoice: 1,cardTime: item.endTime }
                              });
                            }}
                          >
                            续费
                          </Button>
                        </div>
                      </WingBlank>
                    </div>
                    <WhiteSpace></WhiteSpace>
                  </div>
                ))
              : ""}
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            {/* <Button
              onClick={() => {
                this.props.history.push({ pathname: "./fulfill" });
              }}
            >
              我的订单
            </Button> */}

            <Button
              onClick={() => {
                this.props.history.push({ pathname: "./costRecord" });
              }}
            >
              我的订单
            </Button>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <Button
              onClick={() => {
                this.props.history.push({ pathname: "./monthlyOrder" });
              }}
            >
              包月订单
            </Button>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <Button type="warning" onClick={() => this.deleteCar(carNum)}>
              删除车辆
            </Button>
          </WingBlank>
        </div>
      </div>
    );
  }
}
