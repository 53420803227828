/*发票列表-停车路由组件*/
import React, { Component, Fragment } from "react";
import {
  WingBlank, Radio,
  WhiteSpace,
  Button,
  Checkbox,
  DatePicker,
  List,
  ListView,
  Toast,
} from "antd-mobile";
import moment from 'moment'
import { createForm } from "rc-form";
import ReactDOM from "react-dom";
import { getNoInvoiceList } from "../../fetch";
const CheckboxItem = Checkbox.CheckboxItem;
const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
const RadioItem = Radio.RadioItem;
const waitStyle = {
  backgroundColor: "#ffffff",
  borderRadius: "12px",
  boxShadow:'0px 6px 12px 0px rgba(228,230,233,0.5)',
  marginBottom:"20px",
};
const topBack = {
  backgroundColor: "#ffffff",
  position: "fixed",
  width: "100%",
  top: "0",
  zIndex: 999,
};
const topSort = {
  height: "40px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const style_title = { 
  textAlign:"right",
  color: "#CBC9C9",
  fontSize:"13px",
  width:"150px",
  float:"right",
  whiteSpace:"nowrap",
  overflow:"hidden",
  textOverflow:"ellipsis" 
}
const isIPhone = new RegExp("\\biPhone\\b|\\biPod\\b", "i").test(
  window.navigator.userAgent
);
// eslint-disable-next-line
let wrapProps;
if (isIPhone) {
  wrapProps = {
    onTouchStart: (e) => e.preventDefault(),
  };
}
class StopSever extends Component {
  constructor(props) {
    super(props);
    const arrearageDataSource = new ListView.DataSource({
      //这个dataSource有cloneWithRows方法
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.invoicePageNum = 1; // 可开票订单定义分页信息
    this.invoicePageSize = 10;
    this.elsePageNum = 1; // 其他订单分页信息
    this.elsePageSize = 10;
    this.state = {
      date: undefined,
      baseCpId:undefined,// 表单筛选项 
      isAllChecked: false,
      money: 0,
      orderCode: [],
      tabIndex: 0,
      show: false,
      down: false,
      invoice: true, // // 默认展示可开票订单

      arrearage: [],
      arrearageDataSource,
      refreshing: true,
      isLoading: true,
      height: document.documentElement.clientHeight,
      useBodyScroll: true,
      hasMore: true,
      startTime: undefined,
      endTime: "",
      startTime2: undefined,
      endTime2: "",
      _prefixUrl:undefined,
      _baseCpId:undefined,
    };
  }

  way = () => {
    const { orderCode, money, arrearage, } = this.state;
    if(!orderCode.length||orderCode == ""){
      alert("请选择要开票的订单")
    }else{
      let _urlArr = arrearage.filter(item=>item.orderCode == orderCode[0])
      let _prefixUrl = _urlArr[0].prefixUrl;
      this.setState({ _prefixUrl, },()=>{
        window.sessionStorage.setItem("prefixUrl", _prefixUrl);
        window.sessionStorage.setItem("entityCodes", orderCode);
        window.sessionStorage.setItem("orderCode", this.state.orderCode);
        window.sessionStorage.setItem("money", money);
        window.sessionStorage.setItem("fee", this.state.money);
        this.props.history.push({ pathname: "./invoice" })
      })
    }
  };

  total() {
    const { arrearage, } = this.state;
    let price = 0;
    let a = [];
    // eslint-disable-next-line
    arrearage && arrearage.map((item) => {
        if (item.checked) {
          const fee = Number(item.orderFee);
          price += fee;
          a.push(item.orderCode);
        }
      });
    this.setState({
      money: price.toFixed(2),
      orderCode: a,
    });
  }

  // 全选
  isAllChecked(e) {
    // 判断是否选择了相同的公司
    let _Arr = []
    this.state.arrearage.map(item=>_Arr.push(item.baseCpName))
    let _SetArr = [...new Set(_Arr)]
    if(_Arr.length>0&&_SetArr.length!==1){
      alert('请选择相同公司的订单进行开票！')
      return false;
    }
    // 判断是否选择了相同的公司
    let bool = e.target.checked;
    if (bool) {
      this.setState({ isAllChecked: true, });
      this.state.arrearage.filter((item) => item.checked = true);
    } else {
      this.setState({ isAllChecked: false, });
      this.state.arrearage.filter((item) => item.checked = false);
    }
    this.setState({
      arrearage: this.state.arrearage,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        JSON.parse(JSON.stringify(this.state.arrearage)) // 更改state数据时也要把dataSource重新赋值
      ),
    });
    this.total();
  }

  // 勾选
  singleChecked(e, i, rowData,) {
    const { orderCode, _baseCpId, } = this.state;
    const { prefixUrl, baseCpId, } = rowData;
    const isChecked = e.target.checked;
    // 空数组填入
    if(isChecked&&!orderCode.length){
      this.setState({ _prefixUrl:prefixUrl, _baseCpId:baseCpId, })
    }
    // 填入多数组
    if(isChecked&&orderCode.length){
      if(baseCpId!==_baseCpId){
        Toast.offline("请选择相同公司的订单进行开票！",1)
        return false;
      }
    }
    this.state.arrearage[i].checked = e.target.checked;
    this.setState({
      arrearage: this.state.arrearage,
      arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(
        JSON.parse(JSON.stringify(this.state.arrearage)) // 更改state数据时也要把dataSource重新赋值
      ),
    });
    let bool = this.state.arrearage.filter(item => !item.checked);
    if (bool.length == 0) { this.setState({ isAllChecked: true, }); } 
    else { this.setState({ isAllChecked: false, }); }
    this.total();
  }

  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
    this.props.history.push('/'); 
    return false;
    }
    this.start()
  }

  start = () =>{
		window.sessionStorage.setItem("addInvoiceType", "1");
		const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
		const params = {
			invoiceFlag: this.state.invoice ? 0 : 1,
			pageNum: this.state.invoice ? this.invoicePageNum : this.elsePageNum,
			pageSize: this.state.invoice ? this.invoicePageSize : this.elsePageSize,
		}
		this._getInvoiceList(params).then(list=>{
			this.rData = list;
			this.setState({
				arrearage: this.rData,
				arrearageDataSource: this.state.arrearageDataSource.cloneWithRows( this.rData ),
				height: hei,
				refreshing: false,
				isLoading: false,
			});
		})
  }

  _getInvoiceList = param =>{
		return new Promise(resolve=>{
			const { invoice } = this.state;
			if (invoice) { this.invoicePageNum++; } else { this.elsePageNum++; }
			let payload = {
				...param,
				userId: window.sessionStorage.getItem("userId"),
			}
			const result = getNoInvoiceList(payload)
			result.then(res=>{
				if (res) {
					if (res.code == 1000) { 
            if(res.rst.list.length){ 
              const prefixUrl = res.rst.list[0].prefixUrl 
              window.sessionStorage.setItem('prefixUrl',prefixUrl)
            }
            resolve(res.rst.list) 
          } else { alert(res.desc); return; }
				} else {
					this.setState({ hasMore: false, });
				}
			})
		})
  }
  getInvoiceList = (params) => {
    const { invoice } = this.state;
    if (invoice) {
      this.invoicePageNum++;
    } else {
      this.elsePageNum++;
    }
    const userId = window.sessionStorage.getItem("userId");
    const id = {
      ...params,
      userId: userId,
    };
    const url = "c/h5Manage/getNoInvoiceList";
    const param = {
      requestUrl: url,
      param: JSON.stringify(id),
      baseCpId: null,
    };
    fetch("a/request/all", {
      // post提交
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param), //把提交的内容转字符串
    }).then((res) => res.json())
      .then((result) => {
        if (result) {
          if (result.code == 1000) {
            return result.rst.list;
          } else {
            alert(result.desc);
            return;
          }
        } else {
          this.setState({
            hasMore: false,
          });
        }
      });
  };

	showSelect = () => this.setState({ down: !this.state.down, });
	
  changeType = async () => {
    this.setState({ invoice: !this.state.invoice, down: false, });
    const invoiceStatus = !this.state.invoice;
    const params = {
      invoiceFlag: invoiceStatus ? 0 : 1,
      pageNum: invoiceStatus
        ? (this.invoicePageNum = 1)
        : (this.elsePageNum = 1),
      pageSize: invoiceStatus ? this.invoicePageSize : this.elsePageSize,
    };

		this._getInvoiceList(params).then(list=>{
			this.rData=list;
			this.setState({
				arrearage: this.rData,
				arrearageDataSource: this.state.arrearageDataSource.cloneWithRows( this.rData ),
				refreshing: false,
				isLoading: false,
			});
		})
  };

  showPop = () => {
    this.setState({
      show: true,
    });
  };
  closePop = () => {
    this.setState({ show: false, });
    this.props.form.resetFields();
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields(async (err, fieldsValue) => {
      if(!err){
        const { startTime, endTime, startTime2, endTime2, } = fieldsValue;
        const { invoice, baseCpId, } = this.state;
        if(startTime&&endTime){
          const d = new Date(fieldsValue.startTime);
          const n = new Date(fieldsValue.endTime);
          if (d.getTime() > n.getTime()) {  alert("开始时间不能大于结束时间"); }
        }
        if(startTime2&&endTime2){
          const m = new Date(fieldsValue.startTime2);
          const p = new Date(fieldsValue.endTime2);
          if (m.getTime() > p.getTime()) { alert("开始时间不能大于结束时间");}
        }
        const params = {
          invoiceFlag: invoice ? 0 : 1,
          startTime: startTime?moment(startTime).format("YYYY-M-D 00:00:00"):undefined,
          endTime: endTime?moment(endTime).format("YYYY-M-D 23:59:59"):undefined,
          startTime2: startTime2?moment(startTime2).format("YYYY-M-D 00:00:00"):undefined,
          endTime2: endTime2?moment(endTime2).format("YYYY-M-D 23:59:59"):undefined,
          pageNum: invoice?(this.invoicePageNum = 1) : (this.elsePageNum = 1),
          pageSize: invoice?this.invoicePageSize:this.elsePageSize,
          baseCpId:baseCpId?baseCpId:undefined,
        };

        // 查询完成之后 将查询条件储存下来
				this._getInvoiceList(params).then(list=>{
					this.rData=list;
					this.setState({
            money: 0, // 总金额设置为0
            orderCode: [], // 选中的列表设置为空数组
            isAllChecked:false, // 全选按钮设置为取消状态
						arrearage: this.rData,
						arrearageDataSource: this.state.arrearageDataSource.cloneWithRows(this.rData),
						refreshing: false,
						isLoading: false,
						show: false,
            startTime: startTime?moment(startTime).format("YYYY-M-D 00:00:00"):undefined,
            endTime: endTime?moment(endTime).format("YYYY-M-D 23:59:59"):undefined,
            startTime2: startTime2?moment(startTime2).format("YYYY-M-D 00:00:00"):undefined,
            endTime2: endTime2?moment(endTime2).format("YYYY-M-D 23:59:59"):undefined,
					});
				})
      }
    });
  };

  onEndReached = async (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
		} //如果this.state.hasMore为false，说明没数据了，直接返回
		const { invoice, startTime, endTime, startTime2, endTime2, } = this.state;
		this.setState({ isLoading: true });
		let params = {
			invoiceFlag: invoice ? 0 : 1,
			pageNum: invoice ? this.invoicePageNum : this.elsePageNum,
			pageSize: invoice ? this.invoicePageSize : this.elsePageSize,
			startTime: startTime ? startTime : undefined,
			endTime: endTime ? endTime : undefined,
			startTime2: startTime2 ? startTime2 : undefined,
			endTime2: endTime2 ? endTime2 : undefined,
		}
		this._getInvoiceList(params).then(list=>{
			this.rData = [...this.rData,...list,]
		})
    setTimeout(() => {
      this.setState({
        arrearage: this.rData,
        arrearageDataSource: this.state.arrearageDataSource.cloneWithRows( this.rData ),
        isLoading: false,
      });
    }, 1000);
  };

  // 运营商筛选发票列表
  RadioItemChange = item => this.setState({ baseCpId:item.baseCpId, })

  render() {
    const { state, } = this.props.location; // AllBaseCp
    const { getFieldProps } = this.props.form;
    const { invoice, arrearageDataSource, baseCpId, } = this.state;
    const show = this.state.show ? "block" : "none";
    const popStyle = { display: show, };
    const downstyle = this.state.down ? "rotate(180deg)" : "rotate(0deg)";
    const down = { width: "16px",height: "16px",transform: downstyle,};
    let popShow = this.state.down ? "block" : "none";
    const menuPop = {display: popShow,borderTop: "1px solid #F2F2F2",backgroundColor: "#ffffff",position: "absolute",width: "150px",top: "40px",boxShadow: "2px 2px 5px #888888",};

    const row = (rowData, sectionID, rowID) => {
      return (
          <div style={waitStyle} key={rowID}>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={{ color: "#CBC9C9",fontWeight:'400' }}>
                <div>
                  <span style={{ color:"#55555",fontSize:"14px",float:"left", }}>{rowData.orderCode}</span>
                  <span style={style_title}>{rowData.baseCpName}</span>
                  <div style={{ clear:"both", }}/>
                </div>
              </div>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              <WhiteSpace></WhiteSpace>
              <div style={{ color:'#55617C',fontWeight:'400' }}>
                <div>车牌号码&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.carNumber}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>停车地点&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.parkingLotAdress}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>停车时间&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>{rowData.createdTime}</span></div>
                <WhiteSpace></WhiteSpace>
                <div>
                  停车时长&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color:'#000000' }}>
                    <span style={{ color: "red" }}>{rowData.hour} </span>小时
                    <span style={{ color: "red" }}> {rowData.minute} </span>分钟
                  </span>
                </div>
                <WhiteSpace></WhiteSpace>
                <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              </div>
            </WingBlank>
            <WhiteSpace></WhiteSpace>
            <CheckboxItem
              onChange={(e) => this.singleChecked(e, rowID, rowData)}
              checked={rowData.checked ? true : false}
            >
              <div>金额：<span style={{ color: "#398CFC",fontSize:'18px', }}>{rowData.orderFee}</span>元 </div>
              <Button
                type="primary"
                inline
                size="small"
                onClick={() => {
                  sessionStorage.setItem("fee", rowData.orderFee);
                  sessionStorage.setItem("orderCode", rowData.orderCode);
                  sessionStorage.setItem("prefixUrl", rowData.prefixUrl);
                  this.props.history.push({ pathname: "./invoice", });
                }}
                style={{
                  width:'100px',
                  marginRight: "4px",
                  borderRadius: "6px",
                  position: "absolute",
                  right: "10px",
                  top: "7px",
                  fontSize:"14px",
                  background:'linear-gradient(-10deg,rgba(123,133,252,1) 0%,rgba(75,111,252,1) 100%)',
                }}
              >
                开票
              </Button>
            </CheckboxItem>
          </div>
      )
    };

    const row2 = (rowData, sectionID, rowID) => {
      return (
        <div key={rowID}>
          <div style={waitStyle}>
            <WingBlank>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
              <div style={{ color: "#CBC9C9" }}>
                订单编号: {rowData.orderCode}
              </div>
              <WhiteSpace></WhiteSpace>
              <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
              <WhiteSpace></WhiteSpace>
              <div>车牌号码: {rowData.carNumber}</div>
              <WhiteSpace></WhiteSpace>
              <div>停车地点: {rowData.parkingLotAdress}</div>
              <WhiteSpace></WhiteSpace>
              <div>
                停车时长: <span style={{ color: "red" }}>{rowData.hour}</span>
                小时
                <span style={{ color: "red" }}>{rowData.minute}</span>
                分钟
              </div>
              <WhiteSpace></WhiteSpace>
              <div>停车费用: {rowData.orderFee}元</div>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
            </WingBlank>
          </div>
          <div style={{ background:"rgb(245,245,249)",height:"14px" }} />
        </div>
      );
    };
    return (
      <div>
        <div className="maskPop" style={popStyle}>
          <div className="secondPop">
            <div className="actionPop">
              <WingBlank>
                <div style={{ height: "100vh",zIndex:9999, }}>
                  <WhiteSpace></WhiteSpace>
                  <WhiteSpace></WhiteSpace>
                  <WhiteSpace></WhiteSpace>
                  <div style={{ fontSize: "18px", marginBottom: "10px" }}>车辆入场日期
                    <div
                      type="ghost"
                      size="small"
                      style={{ float:"right",fontSize:"22px" }}
                      onClick={this.closePop}
                    >
                      ×
                    </div>
                  </div>
                  <div style={{ borderBottom: "1px solid #F2F2F2" }}></div>
                  <DatePicker mode="date" {...getFieldProps("startTime", { initialValue: this.state.date, })}>
                    <List.Item arrow="horizontal">开始日期</List.Item>
                  </DatePicker>
                  <DatePicker mode="date" {...getFieldProps("endTime", { initialValue: this.state.date, })}>
                    <List.Item arrow="horizontal">结束日期</List.Item>
                  </DatePicker>
                  <WhiteSpace></WhiteSpace>
                  <WhiteSpace></WhiteSpace>
                  <WhiteSpace></WhiteSpace>
                  <div style={{ fontSize: "18px", marginBottom: "10px" }}>车辆离场日期
                  </div>
                  <div style={{ borderBottom: "1px solid #F2F2F2" }} />
                  <DatePicker mode="date" {...getFieldProps("startTime2", { initialValue: this.state.date, })} >
                    <List.Item arrow="horizontal">开始日期</List.Item>
                  </DatePicker>
                  <DatePicker mode="date" {...getFieldProps("endTime2", { initialValue: this.state.date,})} >
                    <List.Item arrow="horizontal">结束日期</List.Item>
                  </DatePicker>
                  <WhiteSpace></WhiteSpace>
                  <WhiteSpace></WhiteSpace>

                  {/* 车场筛选 */}
                  {state&&state.AllBaseCp.length?
                  <Fragment>
                    <div style={{ fontSize: "18px", marginBottom: "10px" }}>车场筛选</div>
                    <div style={{ borderBottom: "1px solid #F2F2F2" }} />
                    <WhiteSpace size="lg"/>
                    {state.AllBaseCp.map(item=>
                      <WingBlank size="lg" key={item.baseCpId}>
                        <Button size="small" type={baseCpId==item.baseCpId?"primary":"ghost"} onClick={()=>this.RadioItemChange(item)}> {item.baseCpName} </Button>
                        <WhiteSpace size="md"/>
                      </WingBlank>)
                    }
                  </Fragment>
                  :null
                  }

                  <div style={{ position: "absolute", bottom: "20px" }}>
                    <Button
                      type="ghost"
                      inline
                      size="small"
                      style={{ marginRight: "8px", padding: "0 30px" }}
                      onClick={()=>{
                        this.invoicePageNum = 1; // 可开票订单定义分页信息
                        this.invoicePageSize = 10;
                        this.elsePageNum = 1; // 其他订单分页信息
                        this.elsePageSize = 10;
                        this.props.form.setFieldsValue({
                          startTime:undefined, startTime2:undefined, endTime:undefined, endTime2:undefined,
                        })
                        this.props.form.resetFields();
                        this.setState({ show: false, baseCpId:undefined, },()=>this.start());
                      }}
                    >
                      重置
                    </Button>
                    <Button
                      type="primary"
                      inline
                      size="small"
                      style={{ marginRight: "4px", padding: "0 30px" }}
                      onClick={this.onSubmit}
                    >
                      确定
                    </Button>
                  </div>
                </div>
              </WingBlank>
            </div>
          </div>
        </div>
        <div style={topBack}>
          <WingBlank>
            <WingBlank>
              <div style={topSort}>
                <div style={{ display: "flex" }} onClick={this.showSelect}>
                  <div style={{ color:'#7C90B6', }}>{invoice ? "可开票订单" : "其他订单"}</div>
                  <img
                    src={require("../../Photo/test2.png")}
                    style={down}
                    alt="标签"
                  />
                </div>
                <div style={{ display: "flex",color:'#7C90B6', }} onClick={this.showPop}>
                  <div>订单筛选</div>
                  <img
                    src={require("../../Photo/test3.png")}
                    style={{ width: "14px", height: "14px", marginTop:'2px',marginLeft:"4px", }}
                    alt="标签"
                  />
                </div>
              </div>
            </WingBlank>
          </WingBlank>
          <div style={menuPop}>
            <div>
              <WingBlank>
                <WingBlank>
                  <div
                    style={{ height: "30px", lineHeight: "30px", color:'#7C90B6', }}
                    onClick={this.changeType}
                  >
                    {invoice ? "其他订单" : "可开票订单"}
                  </div>
                </WingBlank>
              </WingBlank>
            </div>
          </div>
        </div>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>
        {invoice ? (
          <div className="listbackground">
            <WingBlank>
              <ListView
                key={this.state.useBodyScroll ? "0" : "1"}
                ref={(el) => (this.lv = el)}
                dataSource={arrearageDataSource}
                renderFooter={
                  // renderFooter就是下拉时候的loading效果
                  () => (
                    <div style={{ padding: 30, textAlign: "center" }}>
                      {this.state.isLoading ? "加载中..." : "加载完成"}
                    </div>
                  )
                }
                renderRow={row} //渲染你上边写好的那个row
                useBodyScroll={this.state.useBodyScroll}
                style={
                  this.state.useBodyScroll
                    ? {}
                    : {
                        height: this.state.height,
                        border: "1px solid #ddd",
                        margin: "5px 0",
                      }
                }
                onEndReached={this.onEndReached}
                invoicePageSize={this.invoicePageSize} //每次下拉之后显示的数据条数
              />
            </WingBlank>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <span
              style={{
                boxSizing:'border-box',
                position: "fixed",
                width: "100%",
                bottom: "0",zIndex:100,
                padding: "8px 0",
              }}
            >
            <WingBlank size="lg">
              <WingBlank size="md">
                <CheckboxItem
                  style={{ 
                    boxSizing:'border-box',
                    borderRadius:'8px',
                    background: "linear-gradient(-10deg,rgba(123,133,252,1) 0%,rgba(75,111,252,1) 100%)", 
                    boxShadow:'0px 10px 18px 0px rgba(64,95,216,0.3)',
                  }}
                  // disabled
                  checked={this.state.isAllChecked ? true : false}
                  onChange={(e) => this.isAllChecked(e)}
                >
                  <div style={{ color:'#fff',fontWeight:'400',fontSize:'14px', }}>
                    全选&nbsp;
                    总金额：<span>{this.state.money} 元</span>
                  </div>
                  <span
                    type="primary"
                    inline
                    size="small"
                    style={{
                      zIndex:'999',
                      position: "absolute",
                      right: "20px",
                      top: "8px",
                      fontSize:"14px",
                      color:'#fff',
                      lineHeight:'27px'
                    }}
                    onClick={this.way}
                  >
                    <span style={{ display:"inline-block",background:"#1324A3",width:"1px",height:"20px",top:"3px",position:'absolute',left:'0' }}/>
                    &nbsp;&nbsp;&nbsp;开 票
                  </span>
                </CheckboxItem>
               </WingBlank>
              </WingBlank>
            </span>
          </div>
        ) : (
          <div>
            <WingBlank>
              <ListView
                key={this.state.useBodyScroll ? "0" : "1"}
                ref={(el) => (this.lv = el)}
                dataSource={arrearageDataSource}
                renderFooter={
                  // renderFooter就是下拉时候的loading效果
                  () => (
                    <div style={{ padding: 30, textAlign: "center" }}>
                      {this.state.isLoading ? "加载中..." : "加载完成"}
                    </div>
                  )
                }
                renderRow={row2} //渲染你上边写好的那个row
                useBodyScroll={this.state.useBodyScroll}
                style={
                  this.state.useBodyScroll
                    ? {}
                    : {
                        height: this.state.height,
                        border: "1px solid #ddd",
                        margin: "5px 0",
                      }
                }
                onEndReached={this.onEndReached}
                invoicePageSize={this.invoicePageSize} //每次下拉之后显示的数据条数
              />
            </WingBlank>
          </div>
        )}
      </div>
    );
  }
}
const WrappedForm = createForm()(StopSever);
export default WrappedForm;
