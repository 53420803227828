/*支付方式选择路由组件*/
import React, { Component } from "react";
import {
  WingBlank,
  WhiteSpace,
  Button,
  Toast,
} from "antd-mobile";
import { Radio } from "antd";
import "antd/lib/radio/style/index.css";
import md5 from "md5";
import { Modal } from 'antd-mobile'
const Modal_alert = Modal.alert;
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundImage: `url(${require("../../components/images/background.png")})`,
};
const center = {
  height: "59px",
  backgroundColor: "#ffffff",
  borderRadius: "13px",
  lineHeight: "59px",
  textAlign: "center",
  fontSize: "17px",
};
const radioStyle = {
  display: "block",
  height: "49px",
  lineHeight: "49px",
  backgroundColor: "#ffffff",
  borderRadius: "13px",
};
const style_img = {
  padding: "7px 10px 7px 22.5px",
  position: "absolute",
};

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrearage: "",
      value: 1, //1为支付宝支付，2为微信支付
      browser: 3, //1为微信app的浏览器，2为支付宝app的浏览器，3为其它浏览器
    };
  }

  componentDidMount() {
    this.IsWechatPayOrAliPay();
  }

  IsWechatPayOrAliPay() {
    var browser = window.navigator.userAgent.toLowerCase();
    if (browser.indexOf("micromessenger") > 0) {
      this.setState({
        browser: 1,
        value: 2,
      });
    } else if (browser.indexOf("alipayclient") > 0) {
      this.setState({
        browser: 2,
      });
    } else {
      this.setState({
        browser: 3,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  pay = () => {
    // Toast.loading('Loading...', 0);
    const entityCodes = window.sessionStorage.getItem("entityCodes");
    const landCodeId = window.sessionStorage.getItem("landCode");
    const prefixUrl = window.sessionStorage.getItem("prefixUrl");
    const money = window.sessionStorage.getItem("money");
    const id = entityCodes;
    //支付宝支付
    if (this.state.value == 1) {
      Toast.hide();
      window.location.href = prefixUrl +
        "c/alipay/ailiH5Pay?entityCodes=" + id +
        "&type=" + landCodeId +
        "&fee=" + money;
    } else if (this.state.value == 2) {
      //微信支付 在微信浏览器中
      if (this.state.browser == 1) {
        const userId = window.sessionStorage.getItem("userId");
        const myUrl = window.sessionStorage.getItem("url");
        const toUrl = prefixUrl +
          "c/wxPay/weixinPayH5Copy?entityCodes=" + id +
          "&userId=" + userId +
          "&type=" + landCodeId+
          "&fee=" + money;
        fetch(toUrl, { method: "GET",})
          .then((res) => res.json())
          .then((data) => {
            if(data.code!==1000){
              Modal_alert('接口返回错误',JSON.stringify(data))
            }
            const mes = data.rst;
            const time = parseInt(new Date().getTime() / 1000);
            const stringA = "appId=" + mes.appid +
                            "&nonceStr=" + mes.nonce_str + "&package=" +
                            "prepay_id=" + mes.prepay_id +
                            "&signType=MD5&timeStamp=" + time;
            const stringSignTemp = stringA + "&key=" + mes.key; //注：key为商户平台设置的密钥key
            const sign = md5(stringSignTemp).toUpperCase(); //注：MD5签名方式
            Toast.hide();
            window.WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              {
                appId: mes.appid, //公众号名称，由商户传入
                timeStamp: time.toString(), //时间戳，自1970年以来的秒数
                nonceStr: mes.nonce_str, //随机串
                package: "prepay_id=" + mes.prepay_id,
                signType: "MD5", //微信签名方式：
                paySign: sign, //微信签名
              },
              (res) => {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  //支付成功后跳转的页面
                  window.sessionStorage.setItem("orderStatus", "1");
                  // window.location.href="http://m.elascloud.com/payStatus"
                  window.location.href = myUrl + "payStatus?type=1";
                  window.sessionStorage.setItem("payType", 1);
                } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                  alert("您已取消支付");
                } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                  alert("支付失败，请稍后再试");
                }
              }
            );
          });
      } else {
        //微信支付 在其他浏览器中
        const toUrl = prefixUrl + 
            "c/wxPay/weixinPayH5?entityCodes=" + id +
            "&type=" + landCodeId +
            "&fee=" + money;
        fetch(toUrl, {
          method: "GET",
        })
          .then((res) => res.text())
          .then((data) => {
            Toast.hide();
            window.location.href = data;
          });
      }
    }
  };

  render() {
    const money = window.sessionStorage.getItem("money");
    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <div style={center}>
              <WingBlank>
                {/*<div>支付金额: <span style={{color:'red'}}>&nbsp; {this.props.location.code.money}</span> 元</div>*/}
                <div>
                  支付金额: <span style={{ color: "red" }}>&nbsp; {money}</span>{" "}
                  元
                </div>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
              </WingBlank>
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <Radio.Group
              style={{ display: "inline" }}
              onChange={this.onChange}
              value={this.state.value}
            >
              {this.state.browser == 1 ? (
                <div style={radioStyle}>
                  {" "}
                  <img
                    alt=""
                    src={require("../../components/images/weixin.png")}
                    style={style_img}
                  />
                  <div style={{ paddingLeft: "69.5px" }}>
                    <span style={{ wordSpacing: "9.85px" }}>微信支付 </span>
                    <Radio value={2} style={{ paddingLeft: "50%" }}></Radio>
                  </div>
                </div>
              ) : this.state.browser == 2 ? (
                <div style={radioStyle}>
                  {" "}
                  <img
                    alt=""
                    src={require("../../components/images/umidd17.png")}
                    style={style_img}
                  />
                  <div style={{ paddingLeft: "69.5px" }}>
                    <span>支付宝支付</span>
                    <Radio value={1} style={{ paddingLeft: "50%" }}></Radio>
                  </div>
                </div>
              ) : (
                <div>
                  <div style={radioStyle}>
                    {" "}
                    <img
                      alt=""
                      src={require("../../components/images/weixin.png")}
                      style={style_img}
                    />
                    <div style={{ paddingLeft: "69.5px" }}>
                      <span style={{ wordSpacing: "9.85px" }}>微信支付 </span>
                      <Radio value={2} style={{ paddingLeft: "50%" }}></Radio>
                    </div>
                  </div>
                  <WhiteSpace></WhiteSpace>
                  <div style={radioStyle}>
                    {" "}
                    <img
                      alt=""
                      src={require("../../components/images/umidd17.png")}
                      style={style_img}
                    />
                    <div style={{ paddingLeft: "69.5px" }}>
                      <span>支付宝支付</span>
                      <Radio value={1} style={{ paddingLeft: "50%" }}></Radio>
                    </div>
                  </div>
                </div>
              )}
            </Radio.Group>
          </WingBlank>
          <WhiteSpace></WhiteSpace>
        </div>
        <span
          style={{
            position: "absolute",
            width: "100%",
            bottom: "0",
            padding: "9px 0",
          }}
        >
          <WingBlank>
            {money == "0.00" || money == "null" ? (
              <Button
                type="primary"
                style={{ borderRadius: "25px", fontSize: "17px" }}
                disabled
              >
                <span>支付</span>
              </Button>
            ) : (
              <Button
                type="primary"
                style={{ borderRadius: "25px", fontSize: "17px" }}
                onClick={this.pay}
              >
                <span>支付</span>
              </Button>
            )}
          </WingBlank>
        </span>
      </div>
    );
  }
}
