/*关联设备成功路由组件*/
import  React,{Component} from 'react'
import  {WingBlank, WhiteSpace} from 'antd-mobile'
const sectionStyle = {
    width: "100%",
    height: "100vh",
    backgroundSize: 'cover',
    backgroundImage: `url(${require("../../components/images/background.png")})`
};
const center={
    height: '215px',
    backgroundColor:'#ffffff',
    borderRadius:'8px',
    fontSize:'17px'
}
export default  class Machine extends Component {
    render() {
        return(
            <div style={sectionStyle}>
                <div>
                    <WhiteSpace></WhiteSpace>
                    <WingBlank>
                        <div style={center}>
                            <WingBlank>
                                <img src={require('../../components/images/success.png')} style={{ marginLeft:"120px", marginTop:"45px"}} alt="标签"/>
                                <WhiteSpace></WhiteSpace>
                                <WhiteSpace></WhiteSpace>
                                <div style={{textAlign:'center'}}>关联设备成功</div>
                            </WingBlank>
                        </div>
                    </WingBlank>
                </div>
            </div>
        )
    }
}
