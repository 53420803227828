/*电子发票路由组件*/
import React, { Component } from "react";
import {
  WingBlank,
  WhiteSpace,
  List,
  InputItem,
  SegmentedControl,
  Button,
  Toast,
} from "antd-mobile";
import "./index.css";
import { createForm } from "rc-form";
const Item = List.Item;
const sectionStyle = {
  width: "100%",
  height: "100vh",
  backgroundColor: "#F2F2F2",
};
const center = {
  borderRadius: "13px",
  backgroundColor: "#FFFFFF",
  boxShadow: "6px 6px 20px #ccc",
};
const flexMain = {
  borderRadius: "13px 13px 0 0",
  height: "78px",
  color: "#ADADF8",
  backgroundColor: "#5150F2",
  fontSize: "13px",
  display: "flex",
  alignItems: "center",
};
const content = {
  borderRadius: "13px",
  backgroundColor: "#FFFFFF",
};
class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceType: 0,
      titleType: 2,
      originHeight: "",
      isOriginHei: true,
      isClick: true, // 防止重复点击开关
    };
  }

  componentWillMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
      this.props.history.push('/'); 
      return false;
    }
    window.removeEventListener("resize", this.resize);
  }

  screenChange() {
    window.addEventListener("resize", this.resize);
  }

  componentDidMount() {
    const originHeight = document.documentElement.clientHeight;
    this.screenChange();
    this.setState({ originHeight, });
  }

  resize = () => {
    const changeHeight = document.documentElement.clientHeight;
    this.setState({ isOriginHei: this.state.originHeight > changeHeight?false:true, });
  }

  controlChange = (e) => {
    const index = e.nativeEvent.selectedSegmentIndex;
    this.setState({ invoiceType: index, titleType: index == 0 ? 2 : 1, });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    const { titleType, isClick } = this.state;
    const fee = window.sessionStorage.getItem("fee");
    const userId = window.sessionStorage.getItem("userId");
    const orderCode = window.sessionStorage.getItem("orderCode");
    const invoiceType = window.sessionStorage.getItem("addInvoiceType");
    const prefixUrl = window.sessionStorage.getItem("prefixUrl");
    form.validateFields((err, fieldsValue) => { if (!err){
      const { email, } = fieldsValue;
      const regEmail = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/; //正则表达式
      if (!regEmail.test(email)) { //正则验证不通过，格式不对
        alert("邮箱格式不正确!");
        return false;
      } else {
        const params = {
          titleType: titleType,
          invoiceFee: fee,
          invoiceMsg: "服务费",
          orderCodeList: orderCode.split(','),
          userId: userId,
          type: invoiceType?invoiceType:1,
          ...fieldsValue,
        };
        const url = prefixUrl + "c/invoiceManage/addInvoiceManage";
        if (isClick) {
          this.setState({ isClick: false, },()=>{
            Toast.success('开票中，请稍后...')
            fetch(url, {
              method: "POST",
              headers: { "Content-type": "application/json", },
              body: JSON.stringify(params),
            }).then((res) => res.json())
              .then((data) => {
                Toast.hide()
                this.setState({ isClick: true })
                if (data.code == 1000) {
                  this.props.history.push({ pathname: "./invoiceHistory" });
                } 
              });
          });
        }
      }
    
      }
    });
  };

  render() {
    const show = this.state.isOriginHei == true ? "block" : "none";
    const isOriginHei = {
      display: show,
      backgroundColor: "#2283F3",
    };
    const { getFieldProps } = this.props.form;
    const { invoiceType, isClick } = this.state;
    const fee = window.sessionStorage.getItem("fee");
    return (
      <div style={sectionStyle}>
        <div>
          <WhiteSpace></WhiteSpace>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <div style={center}>
              <div style={flexMain}>
                <WingBlank>
                  <div>
                    <div>
                      开票金额：
                      <span style={{ color: "#FFFFFF", fontSize: "25px" }}>
                        {fee}
                      </span>
                      <span style={{ color: "#FFFFFF", fontSize: "13px" }}>
                        {" "}
                        元
                      </span>
                    </div>
                  </div>
                </WingBlank>
              </div>
              <List className="my-list">
                <Item extra={"增值税电子普通发票"}>发票类型</Item>
              </List>
              <List className="my-list">
                <Item
                  extra={
                    <SegmentedControl
                      selectedIndex={invoiceType}
                      tintColor={"#A7895F"}
                      values={["个人", "企业"]}
                      onChange={this.controlChange}
                    />
                  }
                >
                  抬头类型
                </Item>
              </List>
            </div>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <div style={content}>
              <List>
                <InputItem
                  {...getFieldProps("buyername")}
                  placeholder="请输入"
                  ref={(el) => (this.autoFocusInst = el)}
                >
                  发票抬头
                </InputItem>
              </List>
              {invoiceType == 1 ? (
                <List>
                  <InputItem
                    {...getFieldProps("identifyNumber")}
                    placeholder="请输入"
                    ref={(el) => (this.autoFocusInst = el)}
                  >
                    纳税人识别号
                  </InputItem>
                </List>
              ) : (
                ""
              )}
              <List>
                <InputItem
                  type="email"
                  {...getFieldProps("email")}
                  placeholder="请输入"
                  ref={(el) => (this.autoFocusInst = el)}
                >
                  收票邮箱
                </InputItem>
              </List>
              <List className="my-list">
                <Item extra={"停车费"}>发票内容</Item>
              </List>
            </div>
          </WingBlank>
          <div style={{ position: "fixed", width: "100%", bottom: "0" }}>
            <WingBlank>
              <Button
                type="primary"
                style={isOriginHei}
                onClick={this.onSubmit}
                disabled={!isClick}
              >
                提交
              </Button>
              <WhiteSpace></WhiteSpace>
              <WhiteSpace></WhiteSpace>
            </WingBlank>
          </div>
        </div>
      </div>
    );
  }
}
const WrappedForm = createForm()(Invoice);
export default WrappedForm;
