/*发票状态路由组件*/
import React, { Component } from "react";
import {
  WingBlank,
  WhiteSpace,
  Tabs,
  Button,
  Checkbox,
  Toast,
} from "antd-mobile";
const CheckboxItem = Checkbox.CheckboxItem;
const waitStyle = {
  height: "193px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
};
const center = {
  height: "210px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
};
const tabs = [
  { title: "待开票", index: "0" },
  { title: "已开票", index: "1" },
];
const flexMain = {
  display: "flex",
  justifyContent: "space-between",
};

export default class InvoiceStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrearage: [],
      invoiceList: [],
      isAllChecked: false,
      money: 0,
      orderCode: "",
      tabIndex: 0,
    };
    this.way = this.way.bind(this);
  }

  way = () => {
    this.state.orderCode == ""
      ? alert("请选择要开票的订单")
      : this.props.history.push({ pathname: "./invoice" });
    window.sessionStorage.setItem("orderCode", this.state.orderCode);
    window.sessionStorage.setItem("fee", this.state.money);
  };

  total() {
    let arr = this.state.arrearage;
    let price = 0;
    const a = [];
    // eslint-disable-next-line
    arr && arr.map((item) => {
        if (item.checked == true) {
          const fee = parseFloat(item.orderFee);
          price += fee;

          a.push(item.orderCode);
        }
      });
    this.setState({
      money: price,
      orderCode: a,
    });
  }

  isAllChecked(e) {
    let bool = e.target.checked;
    if (bool) {
      this.setState({
        isAllChecked: true,
      });
      this.state.arrearage.filter((item) => item.checked = true);
    } else {
      this.setState({
        isAllChecked: false,
      });
      this.state.arrearage.filter((item) => item.checked = false );
    }
    this.setState({
      arrearage: this.state.arrearage,
    });
    this.total();
  }

  singleChecked(e, i) {
    // eslint-disable-next-line
    this.state.arrearage[i.index].checked = e.target.checked;
    this.setState({
      arrearage: this.state.arrearage,
    });
    var bool = this.state.arrearage.filter((item) => {
      return !item.checked;
    });
    if (bool.length == 0) {
      this.setState({
        isAllChecked: true,
      });
    } else {
      this.setState({
        isAllChecked: false,
      });
    }
    this.total();
  }

  componentDidMount() {
    const browser = window.navigator.userAgent.toLowerCase();
    const id = window.sessionStorage.getItem("userId");
    if ((browser.indexOf("micromessenger")>0)&&(id==null)) {//微信
    this.props.history.push('/'); 
    return false;
    }
    const tabIndex = 0;
    this.setState({
      tabIndex: tabIndex,
    });
    if (tabIndex == 0) {
      this.getNoInvoiceList();
    } else {
      this.getInvoiceList();
    }
  }

  getNoInvoiceList = () => {
    const userId = window.sessionStorage.getItem("userId");
    const id = {
      userId: userId,
    };
    const url = "c/h5Manage/getNoInvoiceList";
    const param = {
      requestUrl: url,
      param: JSON.stringify(id),
      baseCpId: null,
    };
    fetch("a/request/all", {
      // post提交
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param), //把提交的内容转字符串
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          arrearage: data.rst.list,
        });
      });
  };

  getInvoiceList = () => {
    const userId = window.sessionStorage.getItem("userId");
    const id = {
      userId: userId,
    };
    const url = "c/h5Manage/getInvoiceList";
    const param = {
      requestUrl: url,
      param: JSON.stringify(id),
      baseCpId: null,
    };
    fetch("a/request/all", {
      // post提交
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param), //把提交的内容转字符串
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          invoiceList: data.rst.list,
        });
      });
  };

  tabChange(e) {
    this.setState({
      tabIndex: e,
    });
    if (e == 0) {
      this.getNoInvoiceList();
    } else {
      this.getInvoiceList();
    }
  }

  getInvoicePic = (mes) => {
    const id = {
      invoiceCode: mes,
    };
    const url = "c/invoiceManage/getInvoiceManage";
    const param = {
      requestUrl: url,
      param: JSON.stringify(id),
      baseCpId: null,
    };
    fetch("a/request/all", {
      // post提交
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(param), //把提交的内容转字符串
    })
      .then((res) => res.json())
      .then((data) => {
        const dataUrl = data.rst;
        if (dataUrl == "") {
          Toast.info("开票中，请稍后重试", 3);
        } else {
          const link = document.createElement("a");
          //设置下载的文件名
          link.download = "发票";
          link.style.display = "none";
          //设置下载路径
          link.href = dataUrl;
          //触发点击
          document.body.appendChild(link);
          link.click();
          //移除节点
          document.body.removeChild(link);
        }
      });
  };

  render() {
    const { arrearage, invoiceList, tabIndex } = this.state;
    const tabPage = Number(tabIndex);
    const originHeight = document.documentElement.clientHeight;
    const height = originHeight - 44;
    return (
      <div>
        <Tabs
          tabs={tabs}
          page={tabPage}
          onChange={(tab, index) => {
            this.tabChange(index);
          }}
        >
          <div style={{ height: `${height}px` }}>
            <WhiteSpace></WhiteSpace>
            <WingBlank>
              {arrearage && arrearage.length > 0
                ? arrearage.map((item, index) => (
                    <div key={index}>
                      <div style={waitStyle}>
                        <WingBlank>
                          <WhiteSpace></WhiteSpace>
                          <WhiteSpace></WhiteSpace>
                          <div style={{ color: "#CBC9C9" }}>
                            订单编号: {item.orderCode}
                          </div>
                          <WhiteSpace></WhiteSpace>
                          <div
                            style={{ borderBottom: "1px solid #F2F2F2" }}
                          ></div>
                          <WhiteSpace></WhiteSpace>
                          <div>
                            <div>车牌号码: {item.carNumber}</div>
                            <WhiteSpace></WhiteSpace>
                            <div>停车地点: {item.parkingLotAdress}</div>
                            <WhiteSpace></WhiteSpace>
                            <div>
                              停车时长:{" "}
                              <span style={{ color: "red" }}>{item.hour}</span>
                              小时
                              <span style={{ color: "red" }}>
                                {item.minute}
                              </span>
                              分钟
                            </div>
                            <WhiteSpace></WhiteSpace>
                            <div
                              style={{ borderBottom: "1px solid #F2F2F2" }}
                            ></div>
                          </div>
                        </WingBlank>
                        <WhiteSpace></WhiteSpace>
                        <CheckboxItem
                          onChange={(e) => {
                            this.singleChecked(e, { index });
                          }}
                          checked={item.checked ? true : false}
                        >
                          <div>
                            金额:
                            <span style={{ color: "red" }}>
                              {item.orderFee}
                            </span>
                            元
                          </div>
                          <Button
                            type="primary"
                            inline
                            size="small"
                            // onClick={this.way}
                            onClick={() => {
                              sessionStorage.setItem("fee", item.orderFee);
                              sessionStorage.setItem(
                                "orderCode",
                                item.orderCode
                              );
                              this.props.history.push({
                                pathname: "./invoice",
                              });
                            }}
                            style={{
                              marginRight: "4px",
                              borderRadius: "20px",
                              position: "absolute",
                              right: "10px",
                              top: "7px",
                            }}
                          >
                            &nbsp;&nbsp;开票&nbsp;&nbsp;{" "}
                          </Button>
                        </CheckboxItem>
                      </div>
                      <WhiteSpace></WhiteSpace>
                    </div>
                  ))
                : ""}
            </WingBlank>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <WhiteSpace></WhiteSpace>
            <span
              style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                width: "100%",
                bottom: "0",
                padding: "8px 0",
              }}
            >
              <WingBlank>
                <CheckboxItem
                  checked={this.state.isAllChecked ? true : false}
                  onChange={(e) => {
                    this.isAllChecked(e);
                  }}
                >
                  <div>
                    金额：
                    <span style={{ color: "red" }}>{this.state.money}</span>元
                  </div>
                  <Button
                    type="primary"
                    inline
                    size="small"
                    style={{
                      marginRight: "4px",
                      borderRadius: "20px",
                      position: "absolute",
                      right: "10px",
                      top: "7px",
                    }}
                    onClick={this.way}
                  >
                    {" "}
                    &nbsp;&nbsp;开票&nbsp;&nbsp;{" "}
                  </Button>
                </CheckboxItem>
              </WingBlank>
            </span>
          </div>
          {/*已开票*/}
          <div style={{ height: `${height}px` }}>
            <WhiteSpace></WhiteSpace>
            <WingBlank>
              {invoiceList && invoiceList.length > 0
                ? invoiceList.map((item, index) => (
                    <div key={index}>
                      <div style={center}>
                        <WingBlank>
                          <WhiteSpace></WhiteSpace>
                          <WhiteSpace></WhiteSpace>
                          <div style={{ color: "#CBC9C9" }}>
                            发票号码: {item.invoiceCode}
                          </div>
                          <WhiteSpace></WhiteSpace>
                          <div
                            style={{ borderBottom: "1px solid #F2F2F2" }}
                          ></div>
                          <WhiteSpace></WhiteSpace>
                          <div>
                            发票类型:
                            {item.invoiceType && item.invoiceType == 1
                              ? "增值税电子普通发票"
                              : ""}
                          </div>
                          <WhiteSpace></WhiteSpace>
                          <div>
                            发票抬头类型:{" "}
                            {item.titleType && item.titleType == 1
                              ? "企业"
                              : "个人"}
                          </div>
                          <WhiteSpace></WhiteSpace>
                          <div>发票抬头: {item.invoiceName}</div>
                          <WhiteSpace></WhiteSpace>
                          <div>
                            发票金额:{" "}
                            <span style={{ color: "red" }}>
                              {item.invoiceFee}
                            </span>
                            元
                          </div>
                          <WhiteSpace></WhiteSpace>
                          <div>发票内容: {item.invoiceMsg}</div>
                          <WhiteSpace></WhiteSpace>
                          <div style={flexMain}>
                            <div>开票时间: {item.createdTime}</div>
                            <div
                              onClick={() =>
                                this.getInvoicePic(item.invoiceCode)
                              }
                              style={{ color: "#108ee9" }}
                            >
                              下载
                              {/*<a href={this.state.data} download="停车发票" style={{float:'right'}}>下载 &nbsp;</a>*/}
                            </div>
                          </div>
                        </WingBlank>
                      </div>
                      <WhiteSpace></WhiteSpace>
                    </div>
                  ))
                : ""}
            </WingBlank>
          </div>
        </Tabs>
      </div>
    );
  }
}
